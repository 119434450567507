import React from "react";
import MainLayout from "../../MainLayout.tsx";
import LinkGrid from "../../../components/grids/linkGrid.component.tsx";
import { ColorsType } from "../../../types/colors.types.ts";
import colors from "../../../constants/colors.ts";
import { healthyBackGridItems } from "../../../constants/grids.contants.ts";

const Section5: React.FC = () => {
  return (
    <MainLayout>
      <LinkGrid
        columnsNumber={4}
        items={healthyBackGridItems}
        iconColour={ColorsType.CYAN}
        itemBackgroundColour={colors.CYAN_E6FFFF}
      />
    </MainLayout>
  );
};

export default Section5;
