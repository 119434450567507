import React, { useState } from "react";
import {
  TestimonialProps,
  TestimonialType,
} from "../../interfaces/testimonials.interface.ts";
import Box from "@mui/material/Box";
import colors from "../../constants/colors.ts";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ClientInfo from "../media/clientInfo.component.tsx";
import TextPanel from "../text/TextPanel.component.tsx";
import {
  imagesFoldersTypes,
  textTypes,
} from "../../constants/app.constants.ts";
import TextBox from "../text/textBox.component.tsx";
import './slideshow.css'
import { CustomArrow } from "./customSliderArrows.component.tsx";
import { ColorsType } from "../../types/colors.types.ts";

// https://react-multi-carousel.surge.sh/?selectedKind=Carousel&selectedStory=With%20infinite%20mode&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel

const Testimonials: React.FC<TestimonialProps> = ({
  testimonials,
  items = 2,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const numberOfTestimonials = testimonials.length;

  const responsive = {
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
      partialVisibilityGutter: 0,
    },
    tablet: {
      breakpoint: { max: 900, min: 601 },
      items: 1,
      partialVisibilityGutter: 0,
    },
    desktop: {
      breakpoint: { max: 5000, min: 900 },
      items: items,
      partialVisibilityGutter: 0,
    },
  };

  const handleTransition = (index: number) => {
    setActiveIndex(index);
  };

  return (
    <Box>
      <Carousel
        ssr={true}
        showDots={false}
        responsive={responsive}
        partialVisible={true}
        focusOnSelect={true}
        rewindWithAnimation={true}
        beforeChange={handleTransition}
        sliderClass="carousel-slider-class"
        containerClass="testimonial-container-class"
        itemClass="carousel-item-class"
        customRightArrow={<CustomArrow side={'right'} color={ColorsType.WHITE} size={40} />}
        customLeftArrow={<CustomArrow side={'left'} color={ColorsType.WHITE} size={40} iconSize={25}/>}
        //infinite={true}
      >
        {testimonials?.map((item: TestimonialType, index) => (
          <Box
            key={index}
            padding={'10%'}
            sx={{
              backgroundColor: colors.BLUE_0067F6,
              borderRadius: "20px",
              transition: "transform 0.3s ease",
              transform: index === activeIndex ? "scale(1)" : "scale(0.9)",
              opacity: index === activeIndex ? "100%" : "30%",
            }}
          >
            
            <TextPanel
              icon={"Upper-Commas-Grey"}
              variant={imagesFoldersTypes.ELEMENTS}
              title={item?.title}
              titleColor={colors.WHITE}
              titleFontSize={26}
              text={item?.content}
              textColor={colors.WHITE}
            />

            <ClientInfo
              icon={item?.icon}
              name={item?.name}
              opacity={0.8}
              nameColor={colors.WHITE}
              role={item?.role}
              roleColor={colors.WHITE}
              alignItems={"flex-start"}
            />

            {activeIndex === index && (
              <TextBox
                variant={textTypes.TITLE}
                color={colors.WHITE}
                fontSize={24}
                fontFamily={"Inter Bold"}
                fontWeight={600}
                textAlign={"right"}
                marginBottom={0}
              >
                {index + 1} / {numberOfTestimonials}
              </TextBox>
            )}
          </Box>
        ))}
      </Carousel>
    </Box>
  );
};

export default Testimonials;
