import React from "react";
import Box from "@mui/material/Box";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

import { TimelineProps } from "../../interfaces/timeline.interface.ts";
import {
  TimelineDescriptionType,
  TimelineEventsListType,
  TimelineEventType,
} from "../../types/timeline.types.ts";
import colors from "../../constants/colors.ts";
import TextBox from "../text/textBox.component.tsx";
import useStyles from "../../hooks/styles.hook.tsx";
import {
  imagesFoldersTypes,
  textTypes,
} from "../../constants/app.constants.ts";
import Image from "../media/Image.component.tsx";

// https://www.npmjs.com/package/react-vertical-timeline-component

const Timeline: React.FC<TimelineProps> = ({
  events,
  periodColor = colors.BLUE_0067F6,
  titleColor = colors.BLUE_242737,
  descriptionColor = colors.BLUE_242737,
}) => {
  const { textMarginBottomStyles } = useStyles();

  return (
    <Box>
      <VerticalTimeline animate={true} lineColor={colors.BLUE_0067F6}>
        {events?.map((event: TimelineEventsListType, i) => (
          <VerticalTimelineElement
            key={i}
            iconStyle={{
              width: 30,
              height: 30,
              marginLeft: "-15px",
              backgroundColor: colors.BLUE_0067F6,
            }}
            contentStyle={{
              boxShadow: "none",
              display: "flex",
              flexDirection: "column",
              alignItems: i % 2 === 0 ? "flex-end" : "flex-start",
              textAlign: i % 2 == 0 ? "right" : "left",
            }}
          >
            <TextBox
              variant={textTypes.SUBTITLE}
              text={event?.period}
              color={periodColor}
            />

            {event?.events?.map((item: TimelineEventType, j) => (
              <Box key={j}>
                {item?.icon && (
                  <Image
                    variant={imagesFoldersTypes.ICONS}
                    name={item?.icon}
                    sx={textMarginBottomStyles}
                  />
                )}
                {item?.description?.map(
                  (content: TimelineDescriptionType, k) => (
                    <Box>
                      <TextBox
                        text={content?.label}
                        color={descriptionColor}
                        needsFormatting={true}
                        sx={textMarginBottomStyles}
                      >
                        {content?.items?.map((line, l) => (
                          <TextBox
                            key={l}
                            text={line}
                            color={descriptionColor}
                            needsFormatting={true}
                          />
                        ))}
                      </TextBox>
                    </Box>
                  )
                )}
              </Box>
            ))}
          </VerticalTimelineElement>
        ))}
      </VerticalTimeline>
    </Box>
  );
};

export default Timeline;
