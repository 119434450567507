import React from "react";
import TextImageGrid from "../../../components/grids/textImageGrid.component.tsx";
import colors from "../../../constants/colors.ts";
import { imagesFoldersTypes } from "../../../constants/app.constants.ts";

const Section2: React.FC = () => {
  return (
    <TextImageGrid
      title={"TITLE.SedintaDeMasajDe15Minute"}
      titleColor={colors.BLUE_0067F6}
    />
  );
};

export default Section2;
