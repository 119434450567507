import React from "react";
import useStyles from "../../../hooks/styles.hook.tsx";
import MainLayout from "../../MainLayout.tsx";
import colors from "../../../constants/colors.ts";
import Box from "@mui/material/Box";
import LinkGrid from "../../../components/grids/linkGrid.component.tsx";
import TextBox from "../../../components/text/textBox.component.tsx";
import { textTypes } from "../../../constants/app.constants.ts";
import { ColorsType } from "../../../types/colors.types.ts";
import { servicesGridItems } from "../../../constants/grids.contants.ts";

const Section11: React.FC = () => {
  const { verticalPaddingBoxStyles } = useStyles();

  return (
    <MainLayout backgroundColor={colors.PINK_F4F4FB} isFooterDisplayed={true}>
      <Box sx={verticalPaddingBoxStyles} textAlign={"center"}>
        <TextBox variant={textTypes.TITLE} text={"TITLE.PacheteDeServicii"} />

        <LinkGrid
          columnsNumber={4}
          items={servicesGridItems}
          itemBackgroundColour={colors.WHITE}
          iconColour={ColorsType.BLUE}
        />
      </Box>
    </MainLayout>
  );
};

export default Section11;
