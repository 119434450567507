import React from "react";
import LinkGrid from "../../../components/grids/linkGrid.component.tsx";
import MainLayout from "../../MainLayout.tsx";
import Box from "@mui/material/Box";
import useStyles from "../../../hooks/styles.hook.tsx";
import colors from "../../../constants/colors.ts";
import TextPanel from "../../../components/text/TextPanel.component.tsx";
import { satisfiedCustomersGridItems } from "../../../constants/grids.contants.ts";

const Section3: React.FC = () => {
  const { verticalPaddingBoxStyles, smallMarginBottomStyles } = useStyles();
  return (
    <MainLayout>
      <Box sx={verticalPaddingBoxStyles}>
        <LinkGrid
          columnsNumber={4}
          itemBackgroundColour={colors.GREY_F9F9FB}
          items={satisfiedCustomersGridItems}
          sx={smallMarginBottomStyles}
        />
        <TextPanel
          subtitle={"TEXT.TopBrands"}
          caption={"TEXT.GoodCompany"}
          captionColor={colors.GREY_707280}
          textAlign={"center"}
        />
      </Box>
    </MainLayout>
  );
};

export default Section3;
