import React from "react";
import { CardProps } from "../../interfaces/card.interface.ts";
import Box from "@mui/material/Box/Box";
import colors from "../../constants/colors.ts";
import TextPanel from "../text/TextPanel.component.tsx";
import Image from "./Image.component.tsx";
import { imagesFoldersTypes } from "../../constants/app.constants.ts";

const Card: React.FC<CardProps & React.PropsWithChildren> = ({
  image,
  title,
  titleBoxHeight = 40,
  description,
  buttonText,
  textBoxHeight,
  endIcon,
  backgroundColor = colors.GREY_E9E9EF,
  buttonBackgroundColour,
  border = `1px solid ${colors.GREY_E9E9EF}`,
  isCardFullWidth,
  children,
}) => {
  return (
    <Box
      sx={{
        border: border,
        backgroundColor: backgroundColor,
        borderRadius: "20px",
      }}
    >
      {/* Image */}
      {image && (
        <Image
          variant={image?.variant ? image?.variant : imagesFoldersTypes.IMAGES}
          name={image?.image}
          extension={image?.extension}
          sx={{width: '100%'}}
        />
      )}

      {/* Content */}
      <TextPanel
        title={title}
        titleFontSize={24}
        text={description}
        textAlign={"justify"}
        button={buttonText}
        buttonEndIcon={endIcon}
        buttonBackgroundColor={buttonBackgroundColour}
        style={{ padding: "0 20px"}}
        titleBoxHeight={40}
      />

      {children}
    </Box>
  );
};

export default Card;
