import React from "react";
import { VideoPlayerProps } from "../../interfaces/media.interface";

// https://www.npmjs.com/package/react-player

const VideoPlayer: React.FC<VideoPlayerProps> = ({ videoPath, variant, sx }) => {
  return (
    <video
      preload="auto"
      autoPlay
      muted
      loop
      controls
      src={`/images/${variant}/${videoPath}.mp4`}
      style={{
        borderRadius: "20px",
        width: '100%',
        height: 'auto',
        ...sx
      }}
    />
  );
};

export default VideoPlayer;
