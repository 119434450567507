import { CardProps } from "../interfaces/card.interface.ts";
import { TextImageSliderItemType } from "../types/items.types.ts";
import { imagesFoldersTypes, mediaExtensionTypes } from "./app.constants.ts";

export const maxRelaxServices: CardProps[] = [
  {
    image: { image: "Chair-Massage-Card-Cover" },
    title: "TITLE.OnSiteChairMassage",
    description: "TEXT.OnSiteChairMassage",
  },
  {
    image: { image: "Table-Massage-Card-Cover" },
    title: "TITLE.TableMassage",
    description: "TEXT.TableMassage",
  },
  {
    image: { image: "Indian-Head-Massage-Card-Cover" },
    title: "TITLE.IndianHeadMassage",
    description: "TEXT.IndianHeadMassage",
  },
];

export const maxActiveServices: CardProps[] = [
  {
    image: {
      image: "Breathing-Therapy-Compressed",
      extension: mediaExtensionTypes.PNG,
    },
    title: "TITLE.BreathingTherapy",
    description: "TEXT.OnSiteChairMassage",
  },
  {
    image: { image: "Ergonomics" },
    title: "TITLE.Ergonomie",
    description: "TEXT.Ergonomie",
  },
];

export const therapyTypes: TextImageSliderItemType[] = [
  // CONTAINER 1
  {
    image: { image: "Kinesiotherapy" },
    title: "TITLE.Kinetoterapie",
    description: "TEXT.Kinetoterapie",
    redirectUrl: "",
    buttonText: "BUTTON.CitesteMaiMult",
  },

  // CONTAINER 2
  {
    image: { image: "Ergonomics" },
    title: "TITLE.Ergonomie",
    description: "TEXT.Ergonomie",
    redirectUrl: "",
    buttonText: "BUTTON.CitesteMaiMult",
  },

  // CONTAINER 3
  {
    image: {
      image: "Kineto-Gym",
    },
    title: "TITLE.KinetoGym",
    description: "TEXT.KinetoGym",
    redirectUrl: "",
    buttonText: "BUTTON.CitesteMaiMult",
  },

  // CONTAINER 4
  {
    image: {
      image: "Kineto-Gym",
    },
    title: "TITLE.HealthyBack",
    description: "TEXT.HealthyBack",
    redirectUrl: "",
    buttonText: "BUTTON.CitesteMaiMult",
  },

  // CONTAINER 5
  {
    image: {
      image: "Kineto-Gym",
    },
    title: "TITLE.BreathingTherapy",
    description: "TEXT.BreathingTherapy",
    redirectUrl: "",
    buttonText: "BUTTON.CitesteMaiMult",
  },
];

export const massageTypes: TextImageSliderItemType[] = [
  // ON SITE
  {
    image: {
      image: "On-Site-Massage",
      variant: imagesFoldersTypes.PAGE_IMAGES,
    },
    title: "TITLE.OnSiteChairMassage",
    description: "TEXT.OnSiteChairMassage",
    redirectUrl: "#",
  },

  // TABLE MASSAGE
  {
    image: { image: "Table-Massage", variant: imagesFoldersTypes.PAGE_IMAGES },
    title: "TITLE.TableMassage",
    description: "TEXT.TableMassage",
    redirectUrl: "#",
  },

  // BAMBOO CHAIR MASSAGE
  {
    image: {
      image: "Bamboo-Chair-Massage",
      variant: imagesFoldersTypes.PAGE_IMAGES,
    },
    title: "TITLE.BambooChairMassage",
    description: "TEXT.BambooChairMassage",
    redirectUrl: "#",
  },

  // HOT STONE CHAIR MASSAGE
  {
    image: {
      image: "Bamboo-Chair-Massage",
      variant: imagesFoldersTypes.PAGE_IMAGES,
    },
    title: "TITLE.HotStoneChairMassage",
    description:
      "O sesiune de masaj în care terapeutul folosește pietre calde pentru o relaxare profundă și pentru eliberarea tensiunii musculare. Masajul este realizat pe un scaun profesional de masaj.",
    redirectUrl: "#",
  },

  // INDIAN HEAD MASSAGE
  {
    image: {
      image: "Bamboo-Chair-Massage",
      variant: imagesFoldersTypes.PAGE_IMAGES,
    },
    title: "TITLE.IndianHeadMassage",
    description: "TEXT.IndianHeadMassage",
    redirectUrl: "#",
  },

  // HANDS MASSAGE
  {
    image: {
      image: "Bamboo-Chair-Massage",
      variant: imagesFoldersTypes.PAGE_IMAGES,
    },
    title: "TITLE.HandsMassage",
    description: "TEXT.HandsMassage",
    redirectUrl: "#",
  },
];
