import React from "react";
import Section1 from "./sections/Section1.on-site-massage.tsx";
import Section2 from "./sections/Section2.on-site-massage.tsx";
import Section3 from "./sections/Section3.on-site-massage.tsx";
import Section4 from "./sections/Section4.on-site-massage.tsx";
import Section5 from "./sections/Section5.on-site-massage.tsx";

const OnSiteChairMassageHomepage: React.FC = () => {
  return (
    <>
      <Section1 />
      {/* <Section2 /> is included in section1*/}
      <Section3 />
      <Section4 />
      <Section5 />
    </>
  );
};

export default OnSiteChairMassageHomepage;
