import React from "react";

import colors from "../../../constants/colors.ts";
import MainLayout from "../../MainLayout.tsx";
import TextImageGrid from "../../../components/grids/textImageGrid.component.tsx";
import { imagesFoldersTypes } from "../../../constants/app.constants.ts";

const CorporareMassage: React.FC = () => {
  return (
    <MainLayout marginBottom={30}>
      <TextImageGrid
        title={"Masajul Corporate"}
        text={"TEXT.MasajulCorporate"}
        buttonLabel={"BUTTON.SolicitaOfertaPersonalizata"}
        image={'On-Site-Chair-Massage'}
        imageVariant={imagesFoldersTypes.PAGE_IMAGES}
        textColor={colors.WHITE}
        titleColor={colors.WHITE}
        subtitleColour={colors.WHITE}
        backgroundColor={colors.BLUE_0059D4}
        imageSx={{width: '65%'}}
      />
    </MainLayout>
  );
};

export default CorporareMassage;
