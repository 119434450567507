import React from "react";
import Box from "@mui/material/Box";

import MainLayout from "../../MainLayout.tsx";
import useStyles from "../../../hooks/styles.hook.tsx";
import colors from "../../../constants/colors.ts";
import TextBox from "../../../components/text/textBox.component.tsx";
import { textTypes } from "../../../constants/app.constants.ts";

const Section6: React.FC = () => {
  const { textBoxPaddingStyles } = useStyles();

  return (
    <MainLayout>
      <Box
        //display={"flex"}
        flexDirection={"column"}
        textAlign={"center"}
        sx={{
          backgroundColor: colors.PINK_F4F4FB,
          borderRadius: "20px",
          ...textBoxPaddingStyles,
        }}
      >
        <TextBox
          variant={textTypes.TITLE}
          text={"// simulare corner - work in progress"}
        />
      </Box>
    </MainLayout>
  );
};

export default Section6;
