import React from "react";
import MainLayout from "../../MainLayout.tsx";
import TextImageGrid from "../../../components/grids/textImageGrid.component.tsx";
import colors from "../../../constants/colors.ts";
import { imagesFoldersTypes } from "../../../constants/app.constants.ts";

const Section4: React.FC = () => {

  return (
    <MainLayout>
      <TextImageGrid
        title={"TITLE.CalatorieSenzorialaAutentica"}
        subtitle={"SUBTITLE.CalatorieSenzorialaAutentica"}
        text={"TEXT.CalatorieSenzorialaAutentica"}
        textColor={colors.GREY_575967}
        image={'Picture-Pattern'}
        imageVariant={imagesFoldersTypes.PATTERNS}
        alignImage={"center"}
      />
    </MainLayout>
  );
};

export default Section4;
