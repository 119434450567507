import React from "react";
import { ClientInfoProps } from "../../interfaces/testimonials.interface.ts";
import Grid from "@mui/material/Grid2";
import useStyles from "../../hooks/styles.hook.tsx";
import Box from "@mui/material/Box";
import colors from "../../constants/colors.ts";
import TextBox from "../text/textBox.component.tsx";
import {
  imagesFoldersTypes,
  textTypes,
} from "../../constants/app.constants.ts";
import Image from "./Image.component.tsx";
import TextPanel from "../text/TextPanel.component.tsx";

const ClientInfo: React.FC<ClientInfoProps> = ({
  motto,
  icon,
  name,
  nameFontFamily,
  nameFontWeight,
  nameTextTransform = "capitalize",
  nameColor = colors.WHITE,
  nameTextDecoration = "none",
  role,
  roleFontFamily,
  roleFontWeight,
  roleTextTransform = "capitalize",
  roleColor = colors.WHITE,
  opacity,
  alignItems = 'center'
}) => {
  const { avatarImageScalingStyles, smallVerticalPaddingStyles } = useStyles();

  return (
    <Grid
      container
      spacing={2}
      display={"flex"}
      alignItems={"center"}
      sx={smallVerticalPaddingStyles}
    >
      {/* Icon */}
      <Grid>
        {icon && (
          <Box alignItems={"center"} position={"relative"}>
            <Image
              name={icon}
              sx={avatarImageScalingStyles}
              variant={imagesFoldersTypes.TEST_IMAGES}
            />
          </Box>
        )}
      </Grid>

      {/* Name & Role */}
      <Grid display={"flex"} alignItems={alignItems} flexDirection={"column"} justifyContent={"center"}>
        <TextBox
          variant={textTypes.SUBTITLE}
          fontWeight={nameFontWeight}
          textTransform={nameTextTransform}
          color={nameColor}
          opacity={opacity}
          textDecoration={'underline'}
          text={name}
          marginBottom={1}
        />

        <TextBox
          textTransform={roleTextTransform}
          fontWeight={roleFontWeight}
          color={roleColor}
          letterSpacing={0.35}
          text={role}
          marginBottom={0}
        />
      </Grid>
    </Grid>
  );
};

export default ClientInfo;
