import React from "react";
import TextImageGrid from "../../../components/grids/textImageGrid.component.tsx";
import colors from "../../../constants/colors.ts";
import MainLayout from "../../MainLayout.tsx";
import { imagesFoldersTypes } from "../../../constants/app.constants.ts";

const Section4: React.FC = () => {
  const paragraphs = [
    "TEXT.EchipaMaxRelax_Paragraph1",
    "TEXT.EchipaMaxRelax_Paragraph2",
  ];
  
  return (
    <MainLayout>
      <TextImageGrid
        backgroundColor={colors.BLUE_0067F6}
        title={"TITLE.MaxRelaxTeam"}
        titleColor={colors.WHITE}
        subtitle={"TEXT.MaxRelaxTeam_Paragraph1"}
        subtitleColour={colors.WHITE}
        text={paragraphs}
        textColor={colors.WHITE}
        image={'Max-Relax-Team'}
        imageVariant={imagesFoldersTypes.TEST_IMAGES}
      />
    </MainLayout>
  );
};

export default Section4;
