import React from "react";
import MainLayout from "../../MainLayout.tsx";
import TextImageGrid from "../../../components/grids/textImageGrid.component.tsx";
import colors from "../../../constants/colors.ts";
import { imagesFoldersTypes } from "../../../constants/app.constants.ts";

const Section5: React.FC = () => {
  return (
    <MainLayout>
      <TextImageGrid
        title={"TITLE.UsorDeMontat"}
        text={"TEXT.UsorDeMontat"}
        buttonLabel={"BUTTON.ExperimenteazaAcum"}
        buttonBgColor={colors.BLUE_0067F6}
        buttonTextColor={colors.WHITE}
        image={"Picture-Pattern"}
        imageVariant={imagesFoldersTypes.PATTERNS}
        alignImage={"center"}
        isTextLeftAligned={false}
      />
    </MainLayout>
  );
};

export default Section5;
