import React from "react";
import { Box } from "@mui/material";
import MainLayout from "../../MainLayout.tsx";

import useStyles from "../../../hooks/styles.hook.tsx";
import LinkGrid from "../../../components/grids/linkGrid.component.tsx";
import colors from "../../../constants/colors.ts";
import { ColorsType } from "../../../types/colors.types.ts";
import TextBox from "../../../components/text/textBox.component.tsx";
import { textTypes } from "../../../constants/app.constants.ts";
import { mainEffectsGridItems } from "../../../constants/grids.contants.ts";

const Section4: React.FC = () => {
  const { verticalPaddingBoxStyles, smallMarginBottomStyles } = useStyles();

  return (
    <MainLayout>
      <Box sx={verticalPaddingBoxStyles}>
        <TextBox
          variant={textTypes.TITLE}
          text={"TITLE.EfectelePrincipaleAsupraAngajatilor"}
          sx={smallMarginBottomStyles}
        />
        <LinkGrid columnsNumber={4} items={mainEffectsGridItems} itemBackgroundColour={colors.PINK_F4F4FB} iconColour={ColorsType.CYAN}/>
      </Box>
    </MainLayout>
  );
};

export default Section4;
