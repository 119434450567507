import React from "react";
import MainLayout from "../../MainLayout.tsx";
import Box from "@mui/material/Box";
import colors from "../../../constants/colors.ts";
import useStyles from "../../../hooks/styles.hook.tsx";
import MaxConfigChart from "../../../components/charts/maxConfigChart.component.tsx";
import Button from "../../../components/button/button.component.tsx";
import TextPanel from "../../../components/text/TextPanel.component.tsx";

const Section5: React.FC = () => {
  const {
    textBoxPaddingStyles,
    smallMarginBottomStyles,
    smallPaddingStyles
  } = useStyles();

  return (
    <MainLayout>
      <Box
        //display={"flex"}
        flexDirection={"column"}
        textAlign={"center"}
        sx={{
          backgroundColor: colors.BLUE_DDEFFF,
          borderRadius: "20px",
          ...textBoxPaddingStyles,
        }}
      >
        <TextPanel
          title={"TITLE.DespreConfiguratorulMax"}
          text={"TEXT.DespreConfiguratorulMax"}
        />

        <Box
          sx={{
            backgroundColor: colors.WHITE,
            borderRadius: "20px",
            overflow: "hidden",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
            ...smallPaddingStyles,
            ...smallMarginBottomStyles
          }}
        >
          <MaxConfigChart />
        </Box>

        <Button 
          color={colors.WHITE}
          backgroundColor={colors.BLUE_0067F6}
          text={"BUTTON.AflaMaiMultDespreConfigurator"}
        />
      </Box>
    </MainLayout>
  );
};

export default Section5;
