import React, { useState } from "react";
import { Box, Tab, Tabs } from "@mui/material";
import { InfoBoxSliderProps } from "../../interfaces/media.interface.ts";
import Image from "../media/Image.component.tsx";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import TextPanel from "../text/TextPanel.component.tsx";
import colors from "../../constants/colors.ts";
import useStyles from "../../hooks/styles.hook.tsx";
import ClientInfo from "../media/clientInfo.component.tsx";
import { imagesFoldersTypes } from "../../constants/app.constants.ts";

const InfoBoxSlider: React.FC<InfoBoxSliderProps> = ({ items }) => {
  const [value, setValue] = useState<number | null>(null);

  const { smallPaddingStyles } = useStyles();

  const handleChange = (newValue: number) => {
    setValue((prevValue) => (prevValue === newValue ? null : newValue));
  };

  return (
    <TabContext value={value ? value : ""}>
      <TabList centered onChange={() => handleChange}>
        {items?.map((item, index) => (
          <Tab
            label={
              <>
                <Image
                  name={item?.image}
                  variant={imagesFoldersTypes.TEST_IMAGES}
                />

                <ClientInfo
                  name={item?.name}
                  nameFontFamily={"'SF Pro', 'sans-serif'"}
                  nameFontWeight={800}
                  nameTextDecoration={"none"}
                  nameColor={colors.BLUE_242737}
                  roleFontFamily="'SF Pro Text Regular Italic', 'sans-serif'"
                  role={item?.role}
                  roleColor={colors.BLUE_242737}
                />
              </>
            }
            value={index}
            onClick={() => handleChange(index)}
          />
        ))}
      </TabList>

      {items?.map((item, index) => (
        <TabPanel value={index}>
          <Box
            display={"flex"}
            alignItems={"flex-start"}
            justifyContent={"center"}
            sx={{
              backgroundColor: colors.TURQUOISE_01FFFF,
              borderRadius: "20px",
            }}
          >
            <TextPanel
              icon={"Flag-Icon-Black"}
              variant={imagesFoldersTypes.ICONS}
              style={smallPaddingStyles}
            />
            <TextPanel
              title={item?.testimonial?.title}
              text={item?.testimonial?.text}
              style={smallPaddingStyles}
            />
          </Box>
        </TabPanel>
      ))}
    </TabContext>
  );
};

export default InfoBoxSlider;
