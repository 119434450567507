import React from "react";
import { MenuSubitemType } from "../../../types/text.types";
import { motion, Variants } from "framer-motion";
import colors from "../../../constants/colors.ts";
import useStyles from "../../../hooks/styles.hook.tsx";
import { imagesFoldersTypes } from "../../../constants/app.constants.ts";
import Image from "../../media/Image.component.tsx";
import Box from "@mui/material/Box";
import AnimatedBox from "../../button/animatedBox.component.tsx";
import TextBox from "../../text/textBox.component.tsx";

const NavSubmenu: React.FC<{
  submenus: MenuSubitemType[];
  icon?: string;
  iconRedirectUrl?: string;
}> = ({ submenus, icon, iconRedirectUrl }) => {
  const {
    headerImageScalingStyles,
    headerMenuItemFontSizeStyles,
  } = useStyles();

  const itemVariants: Variants = {
    open: {
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 24,
      },
    },

    closed: {
      opacity: 0,
      y: 20,
      transition: { duration: 0.2 },
    },
  };

  return (
    <Box>
      <motion.li
        variants={itemVariants}
        style={{
          listStyle: "none",
        }}
      >
        {icon && (
          <AnimatedBox route={iconRedirectUrl} paddingLeft={0}>
            <Image
              name={icon}
              sx={headerImageScalingStyles}
              variant={imagesFoldersTypes.LOGOS}
            />
          </AnimatedBox>
        )}
      </motion.li>

      {submenus?.map((item, index) => (
        <motion.li
          key={index}
          variants={itemVariants}
          style={{
            maxWidth: '200px',
            listStyle: "none",
          }}
        >
          <AnimatedBox
            width={"100%"}
            justifyContent={"flex-start"}
            paddingLeft={0}
            route={item?.redirectUrl}
            >
            <TextBox
              key={index}
              color={colors.BLUE_242737}
              fontFamily="'Inter Regular', 'sans-serif'"
              //sx={headerMenuItemFontSizeStyles}
              fontSize={14}
              text={item?.label}
              lineHeight={2.75}
              textAlign="left"
              letterSpacing={0.05}
              marginBottom={0}
            />
          </AnimatedBox>
        </motion.li>
      ))}
    </Box>
  );
};

export default NavSubmenu;
