import React, { act, useEffect, useRef, useState } from "react";
import { motion, Variants } from "framer-motion";
import Box from "@mui/material/Box";
import { MenuItemType, MenuSubitemType } from "../../../types/text.types.ts";
import colors from "../../../constants/colors.ts";
import useStyles from "../../../hooks/styles.hook.tsx";
import NavSubmenu from "./navsubmenu.component.tsx";
import { useCategory } from "../../../context/PageCategory.context.tsx";
import AnimatedBox from "../../button/animatedBox.component.tsx";
import { imagesFoldersTypes } from "../../../constants/app.constants.ts";
import Image from "../../media/Image.component.tsx";
import { useScreenSize } from "../../../context/ScreenSize.context.tsx";
import TextBox from "../../text/textBox.component.tsx";

interface NavmenuProps {
  items: MenuItemType[];
}

const Navmenu: React.FC<NavmenuProps> = ({ items }) => {
  const { categoryIndex } = useCategory();
  const screenSize = useScreenSize()

  const [activeIndex, setActiveIndex] = useState<number>(0); // Tracks which menu is open
  const [isSubmenuVisible, setIsSubmenuVisible] = useState<boolean>(false);

  const menuRef = useRef<HTMLButtonElement | null>(null);
  const lineRef = useRef<HTMLDivElement | null>(null);

  const {  smallPaddingStyles } = useStyles();

  const submenuVariants: Variants = {
    open: {
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        bounce: 0,
        duration: 0.7,
        staggerChildren: 0.1, // Stagger submenu item animations
      },
    },
    closed: {
      opacity: 0,
      y: -20,
      transition: {
        type: "spring",
        bounce: 0,
        duration: 0.3,
      },
    },
  };

  const toggleSubmenu = (index: number, e) => {
    setActiveIndex(index);
    setIsSubmenuVisible(true);

    var line = document.querySelector(`.line`) as HTMLElement;
    if (line) {
      line.style.width = `${e?.currentTarget?.offsetWidth}px`;
      line.style.left = `${e?.currentTarget?.offsetLeft}px`;
    }
  };

  const handleExternalClick = (e: MouseEvent) => {
    const isOutsideMenu =
      menuRef?.current && !menuRef.current.contains(e.target as Node);
    const isOutsideSubmenu = !document
      .querySelector(".submenu")
      ?.contains(e.target as Node);

    if (isOutsideMenu && isOutsideSubmenu) {
      setActiveIndex(categoryIndex);
      setIsSubmenuVisible(false);

      renderBlueBorder(categoryIndex);
    }
  };

  const renderBlueBorder = (renderedIndex: number) => {
    const targetItem = document.querySelector(
      `.menu-item-${renderedIndex}`
    ) as HTMLElement;

    const line = document.querySelector(`.line`) as HTMLElement;
    if (line && targetItem) {
      requestAnimationFrame(() => {
      line.style.width = `${targetItem.offsetWidth}px`;
      line.style.left = `${targetItem.offsetLeft}px`;
       });
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleExternalClick, true);
    return () => {
      document.removeEventListener("click", handleExternalClick, true);
    };
  }, []);

  // handle a line follow-up effect in case of screen resize / page inspection
  useEffect(() => {
    const handleResize = () => {
      renderBlueBorder(activeIndex);
    };

    window.addEventListener("resize", handleResize);
    // Cleanup listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [activeIndex, screenSize]);

  useEffect(() => {
    renderBlueBorder(categoryIndex)
  }, [])

  return (
    <Box display="flex" alignItems="center" gap={1}>
      {items?.map((item: MenuItemType, index) => (
        <>
          <Box key={index}>
            {/* Menu button */}
            <motion.button
              ref={menuRef}
              className={`menu-item-${index}`}
              initial={false}
              animate={activeIndex === index ? "open" : "closed"}
              whileTap={{ scale: 0.97 }}
              onClick={(e) => toggleSubmenu(index, e)}
              style={{
                position: "relative",
                border: "none",
                letterSpacing: 0.15,
                lineHeight: "20px",
                backgroundColor: colors.WHITE,
                color:
                  activeIndex === index
                    ? colors.BLUE_0067F6
                    : colors.BLUE_242737,
                cursor: "pointer",
              }}
            >
              <TextBox
                fontSize={14}
                fontFamily="'Inter Semibold', 'sans-serif'"
                //textShadow="1.5px 1.5px 1.5px rgba(50, 50, 50, 0.15)"
                text={item?.menuItem}
                marginBottom={0}
              />
            </motion.button>

            {/* Submenu */}
            {item.menuSubitems && index === activeIndex && isSubmenuVisible && (
              <motion.ul
                className="submenu"
                variants={submenuVariants}
                initial="closed"
                animate={activeIndex === index ? "open" : "closed"}
                exit="closed"
                style={{
                  position: "absolute",
                  top: "100%",
                  backgroundColor: colors.WHITE,
                  padding: '0.5rem 0.7rem',
                  boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
                  borderRadius: "10px",
                }}
              >
                <NavSubmenu
                  iconRedirectUrl={item?.iconRedirectUrl}
                  submenus={item?.menuSubitems}
                  icon={item?.icon}
                />
              </motion.ul>
            )}
          </Box>

          {/* Blue line */}
          <Box
            ref={lineRef}
            className={`line`}
            sx={{
              backgroundColor: colors.BLUE_0067F6,
              transition: "all .3s ease-in-out",
            }}
            position={"absolute"}
            height={3}
            bottom={0}
            borderRadius={10}
          />
        </>
      ))}
    </Box>
  );
};

export default Navmenu;
