import React from "react";
import { Route, Routes } from "react-router-dom";
import MassageHomepage from "../modules/massage-homepage/MassageHomepage.tsx";
import Homepage from "../modules/max-relax-homepage/Homepage.tsx";
import OnSiteChairMassageHomepage from "../modules/on-site-chair-massage/OnSiteChairMassageHomepage.tsx";
import MaxActiveHomepage from "../modules/max-active-homepage/MaxActiveHomepage.tsx";
import HealthyBackHomepage from "../modules/healthy-back-homepage/HealthyBackHomepage.tsx";
import AppHomepage from "../modules/app-homepage/AppHomepage.tsx";
import EventsHomepage from "../modules/events-homepage/EventsHomepage.tsx";
import ClientsHomepage from "../modules/clients/ClientsHomepage.tsx";
import AboutUsHomepage from "../modules/about-us/AboutUsHomepage.tsx";
import ConfiguratorHomepage from "../modules/configurator/ConfiguratorHomepage.tsx";

const MaxRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Homepage />} />
      <Route path="/max-relax" element={<MassageHomepage />} />
      <Route
        path="/max-relax/on-site-chair-massage"
        element={<OnSiteChairMassageHomepage />}
      />
      <Route path="/max-active" element={<MaxActiveHomepage />} />
      <Route path="/max-active/healthy-back" element={<HealthyBackHomepage />} />
      <Route path="/max-relax/program/app" element={<AppHomepage />} />
      <Route path="/max-relax/program/configurator" element={<ConfiguratorHomepage />} />
      <Route path="/max-relax/events" element={<EventsHomepage />} />
      <Route path="/max-relax/about-us/clients" element={<ClientsHomepage />} />
      <Route path="/max-relax/about-us/mission-vision-principles" element={<AboutUsHomepage />} />
    </Routes>
  );
};

export default MaxRoutes;
