import React, { useEffect, useState } from "react";
import MainLayout from "../../MainLayout.tsx";
import Card from "../../../components/media/card.component.tsx";
import TextImageGrid from "../../../components/grids/textImageGrid.component.tsx";
import ClientInfo from "../../../components/media/clientInfo.component.tsx";
import { ImageList, ImageListItem } from "@mui/material";
import { useScreenSize } from "../../../context/ScreenSize.context.tsx";
import { imagesFoldersTypes, screenTypes } from "../../../constants/app.constants.ts";
import { testimonials } from "../../../constants/slideshow.constants.ts";

const Section6: React.FC = () => {
  const [colsNum, setColsNum] = useState<number>();
  const screenSize = useScreenSize();

  const columnsNumber = () => {
    const colsNumber =
      screenSize === screenTypes.PHONE || screenSize === screenTypes.TABLET
        ? 1
        : 2;
    console.log(colsNumber);
    setColsNum(colsNumber);
  };

  useEffect(() => {
    columnsNumber();
  }, []);

  return (
    <MainLayout>
      <ImageList variant="masonry" cols={colsNum} gap={8}>
        {testimonials?.map((item, index) => (
          <ImageListItem key={index}>
            <Card backgroundColor={item?.backgroundColor}>
              <TextImageGrid
                icon={item?.commaIcon}
                iconVariant={imagesFoldersTypes.ELEMENTS}
                title={item?.title}
                titleColor={item?.titleColor}
                text={item?.content}
                textColor={item?.textColor}
                lg={12}
                md={12}
              >
                <ClientInfo
                  name={item?.name}
                  opacity={0.8}
                  nameColor={item?.clientTextColor}
                  role={item?.role}
                  roleColor={item?.clientTextColor}
                  icon={item?.icon}
                />
              </TextImageGrid>
            </Card>
          </ImageListItem>
        ))}
      </ImageList>
    </MainLayout>
  );
};

export default Section6;
