import React from "react";
import MainLayout from "../../MainLayout.tsx";
import TextImageGrid from "../../../components/grids/textImageGrid.component.tsx";
import colors from "../../../constants/colors.ts";

const Section6: React.FC = () => {
  return (
    <MainLayout>
      <TextImageGrid
        backgroundColor={colors.PINK_F4F4FB}
        title={"TITLE.PrezentaNationala"}
        subtitle={"TEXT.PrezentaNationala"}
        text={"TEXT.PrezentaNationala"}
        image={'Wellington-Map-Locations'}
        hasVerticalPadding={true}
      />
    </MainLayout>
  );
};

export default Section6;
