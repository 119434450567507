import React from "react";
import Section1 from "./sections/Section1.massage.tsx";
import Section2 from "./sections/Section2.massage.tsx";
import Section3 from "./sections/Section3.massage.tsx";
import Section4 from "./sections/Section4.massage.tsx";
import Section5 from "./sections/Section5.massage.tsx";
import Section6 from "./sections/Section6.massage.tsx";
import Section8 from "./sections/Section8.massage.tsx";
import Section9 from "./sections/Section9.massage.tsx";
import Section10 from "./sections/Section10.massage.tsx";
import Section11 from "./sections/Section11.massage.tsx";
import Section12 from "./sections/Section12. massage.tsx";
import Section13 from "./sections/Section13.massage.tsx";

const MassageHomepage: React.FC = () => {
  return (
    <>
      <Section1 />
      {/* <Section2 /> is included in the 1st section*/}
      <Section3 />
      <Section4 />
      <Section5 />
      <Section6 />

      <Section8 />
      <Section9 />
      <Section10 />
      <Section11 />
      <Section12 />
      <Section13 />
    </>
  );
};

export default MassageHomepage;
