import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import {
  ListGridProps,
  ListItemsProps,
} from "../../interfaces/grid.interface.ts";
import { ListItemType } from "../../types/text.types.ts";
import TextBox from "../text/textBox.component.tsx";
import useStyles from "../../hooks/styles.hook.tsx";
import {
  imagesFoldersTypes,
  textTypes,
} from "../../constants/app.constants.ts";
import Image from "../media/Image.component.tsx";

const ListGrid: React.FC<ListGridProps> = ({
  columnsNumber = 6,
  itemIcon = "Rouned-Check-Blue",
  itemIconVariant = imagesFoldersTypes.ICONS,
  items,
}) => {
  const { listItemsBoxStyles } = useStyles();
  return (
    <Grid container spacing={8} justifyContent={"center"}>
      {items?.map((item: ListItemsProps, index) => (
        <Grid
          key={index}
          container
          justifyContent={"flex-start"}
          size={{ md: columnsNumber, xs: 12 }}
        >
          <Box>
            <TextBox fontWeight={600} variant={textTypes.SUBTITLE} text={item?.title} />

            {item?.subitems?.map((subItem: ListItemType, sIndex) => (
              <Box
                display={"flex"}
                key={sIndex}
                gap={2}
                sx={listItemsBoxStyles}
              >
                {subItem?.icon ? (
                  <Image
                    name={subItem?.icon}
                    variant={
                      subItem?.iconVariant || imagesFoldersTypes.ELEMENTS
                    }
                  />
                ) : (
                  <Image name={itemIcon} variant={itemIconVariant} />
                )}

                <TextBox
                  text={subItem?.text}
                  marginBottom={0}
                />
              </Box>
            ))}
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

export default ListGrid;
