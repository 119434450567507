import React from "react";
import MainLayout from "../../MainLayout.tsx";
import TextImageGrid from "../../../components/grids/textImageGrid.component.tsx";
import colors from "../../../constants/colors.ts";
import { mondayRisksList } from "../../../constants/lists.constants.ts";
import ListGrid from "../../../components/grids/listGrid.component.tsx";

const Section7: React.FC = () => {
  return (
    <MainLayout backgroundColor={colors.WHITE_F4F4F6} isFooterDisplayed={true}>
      <TextImageGrid
        header={"HEADER.ActiveazaEchipa"}
        caption={"TITLE.Configurator"}
        title={"WEEKDAY.Marti"}
        subtitle={"SUBTITLE.ConfiguratorMarti"}
        text={"TEXT.ConfiguratorMarti"}
        childrenReplacingImage={true}
      >
        <ListGrid items={mondayRisksList} columnsNumber={12} />
      </TextImageGrid>
    </MainLayout>
  );
};

export default Section7;
