import React from "react";
import colors from "../../../constants/colors.ts";
import Section2 from "./Section2.on-site-massage.tsx";
import HomepageSecondaryLayout from "../../../components/homepages/homepageSecondaryLayout.tsx";
import { imagesFoldersTypes } from "../../../constants/app.constants.ts";

const Section1: React.FC = () => {
  return (
    <HomepageSecondaryLayout
      backgroundColor={colors.BLUE_DDEFFF}
      title={"TITLE.OnSiteChairMassage"}
      button={"BUTTON.CereOferta"}
      titleColour={colors.BLUE_242737}
      buttonBackgroundColour={colors.BLUE_242737}
      buttonColour={colors.WHITE}
      media={'Max-Relax-Header'}
      mediaVariant={imagesFoldersTypes.VIDEOS}
      isDark={true}
    >
      <Section2 />
    </HomepageSecondaryLayout>
  );
};

export default Section1;
