import React from "react";
import TextImageGrid from "../../../components/grids/textImageGrid.component.tsx";
import colors from "../../../constants/colors.ts";
import TextBox from "../../../components/text/textBox.component.tsx";
import MainLayout from "../../MainLayout.tsx";
import { imagesFoldersTypes } from "../../../constants/app.constants.ts";

const Section3: React.FC = () => {
  return (
    <MainLayout>
      <TextImageGrid
        title={"TITLE.DespreProgramulDeMasajMaxRelax"}
        buttonBgColor={colors.BLUE_0067F6}
        buttonLabel={"BUTTON.ImplementeazaAcum"}
        buttonTextColor={colors.WHITE}
        isTextLeftAligned={false}
        alignImage={"flex-end"}
        image={'Secondary-Homepage-Image-Layout'}
        imageVariant={imagesFoldersTypes.PATTERNS}
      >
        <TextBox text={"TEXT.DespreProgramulDeMasajMaxRelax_Part1"} sx={{display: "inline"}}>
          <TextBox
            needsFormatting={true}
            color={colors.BLUE_0067F6}
            text={"TITLE.ConfiguratorulMax"}
            sx={{display: "inline"}}
          />
          <TextBox text={"TEXT.DespreProgramulDeMasajMaxRelax_Part2"} sx={{display: "inline"}}/>
        </TextBox>
      </TextImageGrid>
    </MainLayout>
  );
};

export default Section3;
