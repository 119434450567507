import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ArrowProps } from "react-multi-carousel";
import { imagesFoldersTypes } from "../../constants/app.constants.ts";
import Image from "../media/Image.component.tsx";
import { ColorsType } from "../../types/colors.types.ts";

interface CustomArrowProps extends ArrowProps {
  side: "right" | "left";
  color: ColorsType;
  size?: number;
  iconSize?: number;
}

export const CustomArrow: React.FC<CustomArrowProps> = ({
  onClick,
  side,
  color,
  size = 35,
  iconSize,
}) => {
  const [position, setPosition] = useState<number>(0);

  // Update position on window resize
  useEffect(() => {
    const updatePosition = () => {
      const offset = window.innerWidth - 1280;
      const newPosition = offset > 0 ? offset / 2 : 35;
      setPosition(newPosition);
    };

    // Call the function on initial load
    updatePosition();

    // Add event listener for window resize
    window.addEventListener("resize", updatePosition);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("resize", updatePosition);
    };
  }, []);

  return (
    <Button
      onClick={onClick}
      sx={{
        position: "absolute",
        [side]: position,
        borderRadius: "50%",
        border: `1px solid ${color}`,
        minWidth: 35,
        height: size,
        width: size,
      }}
    >
      <Image
        name={`${side.charAt(0).toUpperCase() + side.slice(1)}-Arrow-${color}`}
        variant={imagesFoldersTypes.ICONS}
        size={iconSize}
      />
    </Button>
  );
};
