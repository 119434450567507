import React from "react";
import TextImageGrid from "../../../components/grids/textImageGrid.component.tsx";
import ListGrid from "../../../components/grids/listGrid.component.tsx";
import { mondayRisksList } from "../../../constants/lists.constants.ts";
import MainLayout from "../../MainLayout.tsx";

const Section3: React.FC = () => {
  const paragraphs = [
    "TEXT.NivelRidicateDeStres_Paragraph1",
    "TEXT.NivelRidicateDeStres_Paragraph2",
  ];

  return (
    <MainLayout>
      <TextImageGrid
        caption={"TITLE.Configurator"}
        title={"WEEKDAY.Luni"}
        subtitle={"SUBTITLE.NivelRidicateDeStres"}
        text={paragraphs}
        childrenReplacingImage={true}
      >
        <ListGrid items={mondayRisksList} columnsNumber={12} />
      </TextImageGrid>
    </MainLayout>
  );
};

export default Section3;
