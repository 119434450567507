import React, { createContext, ReactNode, useContext } from "react";
import Box from "@mui/material/Box";

const I18N_CONFIG_KEY = process.env.REACT_APP_I18N_CONFIG_KEY || "i18nConfig";

type Props = {
  selectedLang: "ro" | "en";
  formatText: (paragraphs: string | string[]) => ReactNode;
};
const initialState: Props = {
  selectedLang: "ro",
  formatText: (paragraphs) => {
    const paragraphsArray = Array.isArray(paragraphs)
      ? paragraphs
      : [paragraphs];
    return paragraphsArray?.map((paragraph, index) => (
      <React.Fragment key={index}>
        {paragraph?.split(" ").map((word, i) => {
          const isBold = /\*\*(.*?)\*\*/.test(word); // **bolded words**
          const isUnderline = /__(.*?)__/.test(word); // __underlined words__
          const isItalic = /\/\/(.*?)\/\//.test(word); // //italic words//

          let cleanedWord = word
            .replace(/\*\*/g, "")
            .replace(/__/g, "")
            .replace(/\/\//g, "");

          return (
            <span
              key={i}
              style={{
                fontWeight: isBold ? "bold" : '',
                textDecoration: isUnderline ? "underline" : "",
                fontStyle: isItalic ? "italic" : "",
              }}
            >
              {cleanedWord}{" "}
            </span>
          );
        })}
      </React.Fragment>
    ));
  },
};

function getConfig(): Props {
  const ls = localStorage.getItem(I18N_CONFIG_KEY);
  if (ls) {
    try {
      const parsedConfig = JSON.parse(ls) as { selectedLang: "ro" | "en" };
      return { ...initialState, ...parsedConfig };
    } catch (error) {
      console.log(error);
    }
  }

  return initialState;
}

export function setLanguage(lang: string) {
  localStorage.setItem(I18N_CONFIG_KEY, JSON.stringify({ selectedLang: lang }));
  window.location.reload();
}

const I18nContext = createContext<Props>(initialState);

const useLang = () => {
  return useContext(I18nContext).selectedLang;
};

const useFormatText = () => {
  return useContext(I18nContext).formatText;
};

const MetronicI18nProvider: React.FC<any> = ({ children }) => {
  const lang = getConfig();
  return <I18nContext.Provider value={lang}>{children}</I18nContext.Provider>;
};

export { MetronicI18nProvider, useLang, useFormatText };
