import React, { ReactNode, useContext } from "react";
import { useMediaQuery } from "@mui/material";
import { createContext } from "react";
import { ScreenTypes, screenTypes } from "../constants/app.constants.ts";

const ScreenSizeContext = createContext<ScreenTypes | undefined>(undefined);

const ScreenSizeProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const isPhone = useMediaQuery('(max-width:600px)');
    const isTablet = useMediaQuery('(min-width: 600px) and (max-width: 900px')
    const isLaptop = useMediaQuery('(min-width: 900px) and (max-width: 1280px)')
    const isDesktop = useMediaQuery('(min-width: 1280px) and (max-width: 1920px)')
    const isUltrawide = useMediaQuery('(min-width: 1920px)');
    
    const screenType = isPhone ? screenTypes.PHONE
        : isTablet ? screenTypes.TABLET
        : isLaptop ? screenTypes.LAPTOP
        : isDesktop ? screenTypes.DESKTOP
        : isUltrawide ? screenTypes.ULTRAWIDE
        : screenTypes.DESKTOP

    return (
        <ScreenSizeContext.Provider value={screenType}>
            {children}
        </ScreenSizeContext.Provider>
    )
}

export const useScreenSize = (): ScreenTypes => {
    const context = useContext(ScreenSizeContext)
    if (!context) {
        console.log("useScreenSize must be used within a ScreenSizeProvider")
        return screenTypes.DESKTOP
    }

    return context
}

export default ScreenSizeProvider