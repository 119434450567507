import React from "react";
import TextImageGrid from "../../../components/grids/textImageGrid.component.tsx";
import TextBox from "../../../components/text/textBox.component.tsx";
import colors from "../../../constants/colors.ts";
import { imagesFoldersTypes, textTypes } from "../../../constants/app.constants.ts";
import MainLayout from "../../MainLayout.tsx";

const Section3: React.FC = () => {
  return (
    <MainLayout>
      <TextImageGrid
        title={"TITLE.TerapiileComplementare"}
        subtitle={"TEXT.1/2Din2Milioane"}
        text={"TEXT.TerapiileComplementare"}
        isTextLeftAligned={false}
        image={'Complementary-Therapies'}
        imageVariant={imagesFoldersTypes.PAGE_IMAGES}
        alignImage={"center"}
        hasVerticalPadding={true}
      >
        <TextBox
          variant={textTypes.CAPTION}
          color={colors.GREY_808092}
          text="SURSA.TerapiileComplementare"
          sx={{display: "inline"}}
          needsFormatting={true}
        >
          <TextBox
            variant={textTypes.CAPTION}
            color={colors.BLUE_0067F6}
            text={"TEXT.GrowthAGISTA"}
            sx={{ display: "inline" }}
          />
        </TextBox>
      </TextImageGrid>
    </MainLayout>
  );
};

export default Section3;
