import React from "react";
import { useIntl } from "react-intl";
import TextImageGrid from "../../../components/grids/textImageGrid.component.tsx";
import colors from "../../../constants/colors.ts";
import MainLayout from "../../MainLayout.tsx"
import { imagesFoldersTypes } from "../../../constants/app.constants.ts";

const Section4: React.FC = () => {
  const intl = useIntl();

  const paragraphs = [
    intl.formatMessage({ id: "TEXT.CePresupuneMasajul_Paragraph1" }),
    intl.formatMessage({ id: "TEXT.CePresupuneMasajul_Paragraph2" }),
    intl.formatMessage({ id: "TEXT.CePresupuneMasajul_Paragraph3" }),
    intl.formatMessage({ id: "TEXT.CePresupuneMasajul_Paragraph4" }),
  ];

  return (
    <MainLayout>
      <TextImageGrid
        title={"TITLE.CePresupuneMasajul"}
        titleColor={colors.BLUE_242737}
        text={paragraphs}
        isTextLeftAligned={false}
        image={'Massage-Homepage-Image'}
        imageVariant={imagesFoldersTypes.PAGE_IMAGES}
        alignImage={"center"}
      />
    </MainLayout>
  );
};

export default Section4;
