import React, { Suspense } from "react";
import {
  BrowserRouter,
  Routes as ReactRoutes,
  Routes,
  Route as RRoute,
} from "react-router-dom";

import "./index.css";
import "./fonts/fonts.css";
import MaxRoutes from "./routing/max-routes.tsx";
import ScreenSizeProvider from "./context/ScreenSize.context.tsx";
import { I18nProvider } from "./i18n/i18nProvider.tsx";
import { MetronicI18nProvider } from "./i18n/Metronici18n.tsx";
import { PageProvider } from "./context/Page.context.tsx";
import Loading from "./modules/Loading.tsx";
import { CategoryProvider } from "./context/PageCategory.context.tsx";

type Props = {
  basename: string;
};

function App({ basename }: Props) {
  return (
    <MetronicI18nProvider>
      <React.StrictMode>
        <ScreenSizeProvider>
          <PageProvider>
            <CategoryProvider>
              <Suspense fallback={<Loading />}>
                <BrowserRouter basename={basename}>
                  <I18nProvider>
                    <ReactRoutes>
                      <RRoute path="/*" element={<MaxRoutes />} />
                    </ReactRoutes>
                  </I18nProvider>
                </BrowserRouter>
              </Suspense>
            </CategoryProvider>
          </PageProvider>
        </ScreenSizeProvider>
      </React.StrictMode>
    </MetronicI18nProvider>
  );
}

export default App;
