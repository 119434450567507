import React from "react";
import ReactApexChart from "react-apexcharts";
import { useIntl } from "react-intl";

import colors from "../../constants/colors.ts";
import useStyles from "../../hooks/styles.hook.tsx";

const MaxConfigChart: React.FC = () => {
  const intl = useIntl();
  const { titleFontSizeStyles } = useStyles();

  const series = [
    {
      type: 'line',
      name: `${intl.formatMessage({ id: "CHART.Red_Line" })}`,
      data: [10, 50, 90, 85, 30, 15, 10],
    },
    {
      type: 'line',
      name: `${intl.formatMessage({ id: "CHART.Turquoise_Line" })}`,
      data: [90, 75, 60, 50, 40, 30, 10],
    },
  ];

  const options = {
    chart: {
      fontFamily: "'SF Pro', 'sans-serif'",
      zoom: {
        enabled: false,
      },
      animations: {
        enabled: true,
        speed: 800,
        animateGradually: {
            enabled: true,
            delay: 150
        },
        dynamicAnimation: {
            enabled: true,
            speed: 350
        }
      },
      toolbar: {
        show: false,
      },
    },

    colors: [colors.RED_E20037, colors.TURQUOISE_04EEC4],

    stroke: {
      curve: "smooth",
    },

    title: {
      text: "Chart title",
      margin: 20,
      style: {
        fontFamily: "'SF Pro', 'sans-serif'",
        fontSize: 24,
        fontWeight: 600,
        color: colors.BLUE_242737,
      },
    },

    grid: {
      show: true,
      position: "back",
      borderColor: colors.GREY_ECECF5,
      yaxis: {
        lines: {
          show: true,
        },
      },
      xaxis: {
        lines: {
          show: false,
        },
      },
    },

    xaxis: {
      categories: [
        `${intl.formatMessage({ id: "WEEKDAY.Luni" })}`,
        `${intl.formatMessage({ id: "WEEKDAY.Marti" })}`,
        `${intl.formatMessage({ id: "WEEKDAY.Miercuri" })}`,
        `${intl.formatMessage({ id: "WEEKDAY.Joi" })}`,
        `${intl.formatMessage({ id: "WEEKDAY.Vineri" })}`,
        `${intl.formatMessage({ id: "WEEKDAY.Sambata" })}`,
        `${intl.formatMessage({ id: "WEEKDAY.Duminica" })}`,
      ],
      labels: {
        style: {
          fontFamily: "'SF Pro', 'sans-serif'",
          fontSize: 16,
          colors: colors.BLUE_242737,
        },
      },
      axisTicks: {
        show: false,
      },
      axisBorder: {
        show: true,
        color: colors.GREY_ECECF5,
      },
    },

    yaxis: {
      min: 0,
      max: 100,
      stepSize: 20,
      labels: {
        style: {
          fontFamily: "'SF Pro', 'sans-serif'",
          fontSize: 16,
          colors: colors.GREY_A2A2B2,
        },
      },
      axisBorder: {
        show: true,
        color: colors.GREY_ECECF5,
      },
    },

    legend: {
      position: "top",
      horizontalAlign: "right",
      floating: true,
      offsetY: -55,
      offsetX: -5,
      fontFamily: "'SF Pro', 'sans-serif'",
      onItemHover: {
        highlightDataSeries: true,
      },
    },
  };

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="line"
      height={350}
    />
  );
};

export default MaxConfigChart;
