import React from "react";
import TextImageGrid from "../../../components/grids/textImageGrid.component.tsx";
import colors from "../../../constants/colors.ts";

const Section2: React.FC = () => {
  return (
    <TextImageGrid
      title={"TEXT.MaxRelaxMassageProgram"}
      titleColor={colors.BLUE_0067F6}
    />
  );
};

export default Section2;
