import React from "react";
import { Button } from "@mui/material";
import { AnimatedBoxProps } from "../../interfaces/button.interface";
import colors from "../../constants/colors.ts";
import { useNavigate } from "react-router-dom";
import TextBox from "../text/textBox.component.tsx";

const AnimatedBox: React.FC<
  React.HtmlHTMLAttributes<HTMLDivElement> & AnimatedBoxProps
> = ({
  onClick,
  route = null,
  justifyContent = "center",
  fontFamily,
  color = colors.GREY_575967,
  width,
  textAlign,
  paddingLeft,
  letterSpacing = 0.05,
  text,
  minWidth,
  children,
  textSx,
  ...sx
}) => {
  const navigate = useNavigate();

  const handleNavigation = () => {
    if (route?.startsWith("http://") || route?.startsWith("https://")) {
      // External link, open a new window
      window.open(route, "_blank");
    } else {
      // Internal route, use navigate
      navigate(`${route}`);
    }
  };

  return (
    <Button
      onClick={route === null ? onClick : handleNavigation}
      sx={{
        textAlign: 'left',
        color: color,
        width: width,
        justifyContent: justifyContent,
        paddingLeft: paddingLeft,
        minWidth: 2,
        ...sx,
      }}
    >
      <TextBox text={text} marginBottom={0}/>
      {children}
    </Button>
  );
};

export default AnimatedBox;
