import React from "react";
import HomepageLayout from "../../../components/homepages/homepageLayout.component.tsx";

const Section1: React.FC = () => {
  return (
    <HomepageLayout
      title="TITLE.DespreNoi"
      button="BUTTON.Contacteaza"
      backgroundImage={'Triangle'}
      backgroundImageOpacity={0.5}
      coverImage={'About-Us-Cover'}
      isFooterBackgroundTransparent={true}
    />
  );
};

export default Section1;
