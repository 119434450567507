import React from "react";
import { ButtonProps } from "../../interfaces/button.interface.ts";
import { Box, Button as MUIButton } from "@mui/material";
import colors from "../../constants/colors.ts";
import { useIntl } from "react-intl";
import { ThemeProvider } from "@emotion/react";
import BUTTON_THEME from "../../themes/button.themes.ts";
import { imagesFoldersTypes } from "../../constants/app.constants.ts";
import Image from "../media/Image.component.tsx";

const Button: React.FC<ButtonProps & React.PropsWithChildren<{}>> = ({
  color = colors.BLUE_0067F6,
  fontFamily = "'Inter Medium'",
  lineHeight = 2,
  fontSize = 16,
  fontWeight,
  backgroundColor = colors.WHITE,
  letterSpacing = 0.15,
  endIcon,
  startIcon,
  border,
  borderRadius = 2,
  height,
  padding = 0.5,
  width,
  marginBottom,
  text,
  onClick,
  children,
  sx,
}) => {
  const intl = useIntl();

  return (
    <ThemeProvider theme={BUTTON_THEME}>
      <MUIButton
        onClick={onClick}
        sx={{
          fontFamily: fontFamily,
          fontWeight: fontWeight,
          color: color,
          backgroundColor: backgroundColor,
          border: border,
          textTransform: "none",
          borderRadius: borderRadius,
          width: width,
          height: height,
          marginBottom: marginBottom,
        }}
        endIcon={endIcon && <Image name={endIcon} variant={imagesFoldersTypes.ICONS} />}
        startIcon={startIcon && <Image name={startIcon} variant={imagesFoldersTypes.ICONS} />}
      >
        <Box
          display={"flex"}
          fontSize={fontSize}
          lineHeight={lineHeight}
          letterSpacing={letterSpacing}
          padding={padding}
          sx={sx}
          alignItems={"center"}
          gap={startIcon ? 2 : 0}
        >
          {text && intl.formatMessage({ id: text })}
          {children}
        </Box>
      </MUIButton>
    </ThemeProvider>
  );
};

export default Button;
