import React from "react";
import TextImageSlider from "../../../components/slideshow/textImageSlider.component.tsx";
import MainLayout from "../../MainLayout.tsx";
import { massageTypes } from "../../../constants/cards.constants.ts";

const Section11: React.FC = () => {
  return (
    <MainLayout>
      <TextImageSlider
        subtitle="TITLE.VarietateDeTipuriDeMasaj"
        caption="TITLE.TipuriDeMasaj"
        items={massageTypes}
      />
    </MainLayout>
  );
};

export default Section11;
