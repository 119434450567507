
export const headerCategoriesTypes = {
  MASSAGE: 'Massage',
  THERAPY: '',
  PROGRAM: '',
  EVENTS: '',
  ABOUT_US: '',
  MEDIA: ''
}

export type HeaderCategoriesTypes = (typeof headerCategoriesTypes)[keyof typeof headerCategoriesTypes]

export const headerItems = [
  // MASSAGE
  {
    icon: 'Max-Relax',
    iconRedirectUrl: '/max-relax',
    menuItem: "TITLE.Masaj",
    menuSubitems: [
      {label: "TITLE.OnSiteChairMassage", redirectUrl: '/max-relax/on-site-chair-massage'},
      {label: "TITLE.TableMassage", redirectUrl: ''},
      {label: "TITLE.BambooChairMassage", redirectUrl: ''},
      {label: "TITLE.HotStoneChairMassage", redirectUrl: ''},
      {label: "TITLE.IndianHeadMassage", redirectUrl: ''},
      {label: "TITLE.HandsMassage", redirectUrl: ''}
    ],
  },

  // THERAPY
  {
    icon: 'Max-Active',
    iconRedirectUrl: '/max-active',
    menuItem: "TITLE.Terapie",
    menuSubitems: [
      {label: "TITLE.PhysicalTherapy", redirectUrl: ''},
      {label: "TITLE.Ergonomics", redirectUrl: ''},
      {label: "TITLE.KinetoGym", redirectUrl: ''},
      {label: "TITLE.HealthyBack", redirectUrl: '/max-active/healthy-back'},
      {label: "TITLE.BreathingTherapy", redirectUrl: ''}
    ],
  },

  // PROGRAM
  {
    menuItem: "TITLE.Concept",
    menuSubitems: [
      {label: "TITLE.App", redirectUrl: '/max-relax/program/app'}, 
      {label: "TITLE.Configurator", redirectUrl: '/max-relax/program/configurator'}
    ],
  },

  // EVENTS
  {
    menuItem: "TITLE.AplicatieMobila",
    menuSubitems: [
      {label: "TITLE.Outdoor", redirectUrl: '/max-relax/events'}, 
      {label: "TITLE.Indoor", redirectUrl: ''}
    ],
  },

  // ABOUT US
  {
    menuItem: "TITLE.DespreNoi",
    menuSubitems: [
      {label: "TITLE.MissionVisionPrinciples", redirectUrl: '/max-relax/about-us/mission-vision-principles'},
      {label: "TITLE.Coverage", redirectUrl: ''},
      {label: "TITLE.Team", redirectUrl: ''},
      {label: "TITLE.Clients", redirectUrl: '/max-relax/about-us/clients'},
      {label: "TITLE.History", redirectUrl: ''}
    ],
  },

  // MEDIA
  {
    menuItem: "TITLE.Resurse",
  },
];
