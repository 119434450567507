import React from "react";
import { CardProps, CardsGridProps } from "../../interfaces/card.interface.ts";
import Card from "../media/card.component.tsx";
import Grid from "@mui/material/Grid2";

const CardsGrid: React.FC<CardsGridProps> = ({
  cards,
  backgroundColor,
  border,
  buttonText,
  buttonBackgroundColour,
  isCardFullWidth = false,
  endIcon,
  sx,
}) => {
  // ALL CARDS IMAGES MUST BE STORED INSIDE THE /IMAGES folder
  return (
    <Grid container spacing={4} sx={sx}>
      {cards?.map((card: CardProps, index) => (
        <Grid container size={{ md: 6, xs: 12 }} justifyContent={"center"}>
          <Card
            key={index}
            image={card?.image}
            title={card?.title}
            description={card?.description}
            backgroundColor={backgroundColor}
            border={border}
            buttonText={buttonText}
            buttonBackgroundColour={buttonBackgroundColour}
            isCardFullWidth={isCardFullWidth}
            endIcon={endIcon}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default CardsGrid;
