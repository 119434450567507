import React from "react";
import MainLayout from "../../MainLayout.tsx";
import TextBox from "../../../components/text/textBox.component.tsx";
import {
  imagesFoldersTypes,
  textTypes,
} from "../../../constants/app.constants.ts";
import Box from "@mui/material/Box";
import colors from "../../../constants/colors.ts";
import SlotCounter from "react-slot-counter";
import "../../../styles/slotcounter.css";
import Image from "../../../components/media/Image.component.tsx";
import Grid from "@mui/material/Grid2";

const OurImpactWidgets: React.FC = () => {
  return (
    <MainLayout sx={{ marginTop: 100 }} marginBottom={30}>
      <TextBox
        textAlign={"center"}
        fontFamily={"Inter Regular"}
        fontWeight={600}
        fontSize={40}
        variant={textTypes.TITLE}
        text={"TITLE.ImpactulNostru"}
        marginBottom={5}
      />

      <Grid container justifyContent={"center"} spacing={5}>
        {/* begin::Beneficiari */}
        <Grid
          size={{ lg: 3, md: 6, xs: 12 }}
          sx={{
            textAlign: "center",
            backgroundColor: colors.BLUE_F0FAFF,
            borderRadius: "12px",
            padding: 2,
          }}
        >
          <TextBox
            marginBottom={0}
            fontFamily="Inter Semibold"
            fontSize={40}
            color={colors.BLUE_0067F6}
          >
            <Box>
              <Image
                name={"Group-of-People"}
                variant={imagesFoldersTypes.ICONS}
              />
            </Box>
            <SlotCounter
              value={8}
              duration={2}
              animateOnVisible={{
                triggerOnce: false,
                rootMargin: "0px 0px -40px 0px",
              }}
              charClassName="slot-number"
              separatorClassName="slot-number-gap"
            />{" "}
            milioane +
          </TextBox>

          <TextBox
            fontFamily="Inter Semibold"
            variant={textTypes.SUBTITLE}
            text={"beneficiari"}
            textAlign={"center"}
            fontSize={18}
            color={colors.GREY_575967}
          />
        </Grid>
        {/* end::Beneficiari */}

        {/* begin::Experienta */}
        <Grid
          size={{ lg: 3, md: 6, xs: 12 }}
          sx={{
            textAlign: " center",
            backgroundColor: colors.BLUE_F0FAFF,
            borderRadius: "12px",
            padding: 2,
          }}
        >
          <TextBox
            marginBottom={0}
            fontFamily="Inter Semibold"
            fontSize={40}
            color={colors.BLUE_0067F6}
          >
            <Box>
              <Image name={"Cup-Icon"} variant={imagesFoldersTypes.ICONS} />
            </Box>
            <SlotCounter
              value={19}
              duration={2}
              animateOnVisible={{
                triggerOnce: false,
                rootMargin: "0px 0px -40px 0px",
              }}
              charClassName="slot-number"
              separatorClassName="slot-number-gap"
            />{" "}
            ani
          </TextBox>

          <TextBox
            fontFamily="Inter Semibold"
            variant={textTypes.SUBTITLE}
            text={"de experiență"}
            textAlign={"center"}
            fontSize={18}
            color={colors.GREY_575967}
          />
        </Grid>
        {/* end::Experienta */}

        {/* begin::CompaniiPartenere */}
        <Grid
          size={{ lg: 3, md: 6, xs: 12 }}
          sx={{
            textAlign: "center",
            backgroundColor: colors.BLUE_F0FAFF,
            borderRadius: "12px",
            padding: 2,
          }}
        >
          <TextBox
            fontFamily="Inter Semibold"
            fontSize={40}
            color={colors.BLUE_0067F6}
            marginBottom={0}
          >
            <Box>
              <Image
                name={"Handshake-Icon"}
                variant={imagesFoldersTypes.ICONS}
                sx={{
                  marginBottom: "-18px",
                }}
              />
            </Box>
            <SlotCounter
              value={500}
              duration={2}
              animateOnVisible={{
                triggerOnce: false,
                rootMargin: "0px 0px -40px 0px",
              }}
              charClassName="slot-number"
              separatorClassName="slot-number-gap"
            />{" "}
            +
          </TextBox>
          <TextBox
            fontFamily="Inter Semibold"
            variant={textTypes.SUBTITLE}
            text={"companii partenere"}
            textAlign={"center"}
            fontSize={18}
            color={colors.GREY_575967}
            marginBottom={0}
          />
        </Grid>
        {/* end::CompaniiaPartenere */}

        {/* begin::Acoperire */}
        <Grid
          size={{ lg: 3, md: 6, xs: 12 }}
          sx={{
            textAlign: " center",
            backgroundColor: colors.BLUE_F0FAFF,
            borderRadius: "12px",
            padding: 2,
          }}
        >
          <TextBox
            marginBottom={0}
            fontFamily="Inter Semibold"
            fontSize={40}
            color={colors.BLUE_0067F6}
          >
            <Box>
              <Image
                name={"Romania-Icon"}
                variant={imagesFoldersTypes.ICONS}
                sx={{
                  height: 60,
                  marginBottom: "-12px",
                }}
              />
            </Box>
            <SlotCounter
              value={82}
              duration={2}
              animateOnVisible={{
                triggerOnce: false,
                rootMargin: "0px 0px -40px 0px",
              }}
              charClassName="slot-number"
              separatorClassName="slot-number-gap"
            />
            %
          </TextBox>
          <TextBox
            fontFamily="Inter Semibold"
            variant={textTypes.SUBTITLE}
            text={"acoperire în țară"}
            textAlign={"center"}
            fontSize={18}
            color={colors.GREY_575967}
          />
        </Grid>
        {/* end::Acoperire */}
      </Grid>
    </MainLayout>
  );
};

export default OurImpactWidgets;
