import React from "react";
import { useIntl } from "react-intl";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
} from "@mui/material";
import { headerItems } from "../../constants/header.constants.ts";
import useStyles from "../../hooks/styles.hook.tsx";
import AnimatedBox from "../button/animatedBox.component.tsx";
import TextBox from "../text/textBox.component.tsx";
import colors from "../../constants/colors.ts";
import { imagesFoldersTypes } from "../../constants/app.constants.ts";
import Image from "../media/Image.component.tsx";

interface headerMenuDrawerProps {
  isHeaderMenuOpen: boolean;
  setIsHeaderMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const HeaderMenuDrawer: React.FC<headerMenuDrawerProps> = ({
  isHeaderMenuOpen,
  setIsHeaderMenuOpen,
}) => {
  const intl = useIntl();
  const { smallPaddingStyles } = useStyles();

  return (
    <Drawer open={isHeaderMenuOpen} onClose={() => setIsHeaderMenuOpen(false)}>
      <Box
        sx={{
          width: "60vw",
        }}
      >
        <AnimatedBox
          color={colors.BLUE_242737}
          onClick={() => setIsHeaderMenuOpen(false)}
        >
          <Image name={'Left-Arrow-Dark'} variant={imagesFoldersTypes.ICONS} />
        </AnimatedBox>

        <Divider />

        <List>
          {headerItems?.map((item, index) => (
            <ListItem key={index} sx={smallPaddingStyles}>
                {(item?.menuSubitems?.length  ?? 0 ) > 0 ? (
              <Accordion sx={{ width: "100%" }}>
                <AccordionSummary
                  expandIcon={<Image name={'Down-Keyboard-Arrow-Dark'} variant={imagesFoldersTypes.ELEMENTS} />}
                  sx={{ width: "100%" }}
                >
                  <ListItemButton>
                    <TextBox
                      fontFamily={"'SF Pro Text Semibold', 'sans-serif'"}
                      color={colors.BLUE_242737}
                      text={item?.menuItem}
                    />
                  </ListItemButton>
                </AccordionSummary>

                <AccordionDetails>
                  {item?.menuSubitems?.map((subitem, sIndex) => (
                    <ListItemButton key={sIndex}>
                      <TextBox sx={smallPaddingStyles}
                        text={subitem?.label}
                      />
                    </ListItemButton>
                  ))}
                </AccordionDetails>
              </Accordion>
              ) : (
                <ListItemButton>
                    <TextBox
                      fontFamily={"'SF Pro Text Semibold', 'sans-serif'"}
                      color={colors.BLUE_242737}
                      text={item?.menuItem}
                    />
                  </ListItemButton>
              )}
            </ListItem>
          ))}
        </List>
      </Box>
    </Drawer>
  );
};

export default HeaderMenuDrawer;
