import React from "react";
import Grid from "@mui/material/Grid2";
import { Box, Button } from "@mui/material";
import colors from "../../constants/colors.ts";
import { Link } from "react-router-dom";
import useStyles from "../../hooks/styles.hook.tsx";
import AnimatedBox from "../button/animatedBox.component.tsx";
import LanguageSelector from "./languageSelector.component.tsx";
import TextBox from "../text/textBox.component.tsx";
import {
  imagesFoldersTypes,
  textTypes,
} from "../../constants/app.constants.ts";
import Image from "../media/Image.component.tsx";

const SettingsBar: React.FC<{ isDark?: boolean; backgroundColor?: string }> = ({
  isDark = false,
  backgroundColor = "transparent",
}) => {
  const { headerMenuFontSizeStyles } = useStyles();

  return (
    // Layout
    <Box
      zIndex={2}
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
      sx={{
        backgroundColor: backgroundColor,
      }}
    >
      {/* Return Button */}
      <Box>
        <Link to={"/"}>
          <Button
            startIcon={
              <Image
                variant={imagesFoldersTypes.ICONS}
                name={isDark ? "Left-Arrow-Dark" : "Left-Arrow-Light"}
              />
            }
          >
            {isDark ? (
              <Image
                variant={imagesFoldersTypes.LOGOS}
                name={"Wellington-Logo-Dark"}
              />
            ) : (
              <Image
                variant={imagesFoldersTypes.LOGOS}
                name={"Wellington-Logo-Light"}
              />
            )}
          </Button>
        </Link>
      </Box>

      {/* Options */}
      <Box
        display={"flex"}
        justifyContent={"flex-end"}
        gap={1}
        alignItems={"center"}
      >
        {/* Items */}
        <Button
          sx={{
            textTransform: "none",
            color: colors.BLUE_242737,
          }}
        >
          <TextBox
            fontFamily="'Inter Semibold', 'sans-serif'"
            fontSize={12}
            lineHeight={16}
            letterSpacing={0.15}
            variant={textTypes.CAPTION}
            //sx={headerMenuFontSizeStyles}
            text={"BUTTON.Contacteaza"}
            marginBottom={0}
          />
        </Button>

        <AnimatedBox>
          <Image variant={imagesFoldersTypes.ICONS} name={"Shop"} />
        </AnimatedBox>

        <AnimatedBox>
          <Image name={"Heart"} variant={imagesFoldersTypes.ICONS} />
        </AnimatedBox>

        <AnimatedBox>
          <Image name={"User"} variant={imagesFoldersTypes.ICONS} />
        </AnimatedBox>

        {/* Language Selector */}
        <LanguageSelector />
      </Box>
    </Box>
  );
};

export default SettingsBar;
