import { createTheme } from "@mui/material";
import colors from "../constants/colors.ts";

const NEWSLETTER_EMAIL_INPUT = createTheme({
  components: {

    // https://mui.com/material-ui/api/input/
    MuiInput: {
      styleOverrides: {
        root: {
          backgroundColor: colors.WHITE_9294A4,
          color: colors.WHITE_F4F4F6,
          opacity: 1,
          border: "1px solid white",
          borderRadius: "6px",
          //width: "50%",
          height: '50px',
          marginRight: "16px",
          textDecoration: 'none',
          outline: 'none',
        },

        //.MuiOutlinedInput-input	
        input: {
          color: colors.WHITE
        }
      },
    },
    
    // MuiInputLabel: {
    //   styleOverrides: {
    //     root: {
    //       fontFamily: "'SF Pro', 'sans-serif'",
    //       fontSize: "16px",
    //       letterSpacing: "0.05px",
    //       lineHeight: "24px",
    //       color: colors.bla,
    //       flexShrink: 'false'
    //     },
    //   },
    // },
  },
});

export default NEWSLETTER_EMAIL_INPUT;
