import React from "react";
import TextImageGrid from "../../../components/grids/textImageGrid.component.tsx";
import colors from "../../../constants/colors.ts";
import MainLayout from "../../MainLayout.tsx";

const Section2: React.FC = () => {
  return (
    <TextImageGrid
      title={"TITLE.ProgramulHealthyBack"}
      titleColor={colors.BLUE_0067F6}
      lg={5}
      md={6}
    />
  );
};

export default Section2;
