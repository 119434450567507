import React from "react";
import Box from "@mui/material/Box";
import MainLayout from "../../MainLayout.tsx";
import LinkGrid from "../../../components/grids/linkGrid.component.tsx";
import { ColorsType } from "../../../types/colors.types.ts";
import colors from "../../../constants/colors.ts";
import useStyles from "../../../hooks/styles.hook.tsx";
import TextBox from "../../../components/text/textBox.component.tsx";
import { textTypes } from "../../../constants/app.constants.ts";
import { mainEffectsGridItems } from "../../../constants/grids.contants.ts";

const Section7: React.FC = () => {
  const { verticalPaddingBoxStyles, smallMarginBottomStyles } = useStyles();

  return (
    <MainLayout
      isFooterDisplayed={true}
      backgroundColor={colors.CYAN_E6FFFF}
      marginBottom={0}
    >
      <Box
        sx={{ ...verticalPaddingBoxStyles, ...smallMarginBottomStyles }}
        textAlign={"left"}
      >
        <TextBox
          variant={textTypes.TITLE}
          text={"TITLE.EfectelePrincipaleAsupraAngajatilor"}
          sx={smallMarginBottomStyles}
        />
        <LinkGrid
          columnsNumber={4}
          items={mainEffectsGridItems}
          itemBackgroundColour={colors.WHITE}
          iconColour={ColorsType.CYAN}
        />
      </Box>
    </MainLayout>
  );
};

export default Section7;
