import React from "react";
import MainLayout from "../../MainLayout.tsx";
import { therapyTypes } from "../../../constants/cards.constants.ts";
import TextBox from "../../../components/text/textBox.component.tsx";
import { textTypes } from "../../../constants/app.constants.ts";
import CardsGrid from "../../../components/grids/cardsGrid.component.tsx";
import colors from "../../../constants/colors.ts";

const Section8: React.FC = () => {
  return (
    <MainLayout>
      <TextBox variant={textTypes.TITLE} text={"TITLE.TipuriDeTerapii"} />

      <CardsGrid
        cards={therapyTypes}
        isCardFullWidth={true}
        backgroundColor={colors.WHITE_F4F4F6}
      />
    </MainLayout>
  );
};

export default Section8;
