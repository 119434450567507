import React from "react";
import MainLayout from "../../MainLayout.tsx";
import Grid from "@mui/material/Grid2";
import useStyles from "../../../hooks/styles.hook.tsx";
import Box from "@mui/material/Box";
import SlotCounter from "react-slot-counter";
import colors from "../../../constants/colors.ts";
import TextBox from "../../../components/text/textBox.component.tsx";
import { useIntl } from "react-intl";
import "../../../styles/slotcounter.css";

// https://almond-bongbong.github.io/react-slot-counter/

const Section3: React.FC = () => {
  const { textBoxPaddingStyles, textBoxFontSizeStyles } = useStyles();

  // const intl = useIntl();

  return (
    <MainLayout>
      <Grid 
        container 
        display={"flex"} 
        alignItems={"stretch"} 
        spacing={3}
      >
        <Grid
          container
          display={"flex"}
          flexDirection={"column"}
          size={{ md: 6, xs: 12 }}
          justifyContent={"center"}
          textAlign={"center"}
          sx={{
            backgroundColor: colors.BLUE_DDEFFF,
            borderRadius: "20px",
          }}
        >
          <Box sx={textBoxPaddingStyles}>
            <SlotCounter
              value={"8000000+"}
              duration={2}
              animateOnVisible={{
                triggerOnce: false,
                rootMargin: "0px 0px -80px 0px",
              }}
              charClassName="slot-number"
              separatorClassName="slot-number-gap"
            />
            <TextBox sx={textBoxFontSizeStyles} text={"MASAJ_HOMEPAGE_SECTION3.MassageText"}/>
          </Box>
        </Grid>
        <Grid
          container
          size={{ md: 6, xs: 12 }}
          justifyContent={"center"}
          textAlign={"center"}
          sx={{
            backgroundColor: colors.BLUE_DDEFFF,
            borderRadius: "20px",
          }}
        >
          <Box sx={textBoxPaddingStyles}>
            <SlotCounter
              value={"500+"}
              duration={2}
              animateOnVisible={{
                triggerOnce: false,
                rootMargin: "0px 0px -80px 0px",
              }}
              charClassName="slot-number"
              separatorClassName="slot-number-gap"
            />
            <TextBox sx={textBoxFontSizeStyles} text={"MASAJ_HOMEPAGE_SECTION3.CompaniesCounter"}/>
          </Box>
        </Grid>
      </Grid>
    </MainLayout>
  );
};

export default Section3;
