import React from "react";
import Section1 from "./sections/Section1.app.tsx";
import Section2 from "./sections/Section2.app.tsx";
import Section3 from "./sections/Section3.app.tsx";
import Section4 from "./sections/Section4.app.tsx";
import Section5 from "./sections/Section5.app.tsx";
import Section6 from "./sections/Section6.app.tsx";
import Section7 from "./sections/Section7.app.tsx";

const AppHomepage: React.FC = () => {
  return (
    <>
        <Section1 />
        {/* <Section2 /> is included in section1*/}
        <Section3 />
        <Section4 />
        {/* <Section5 /> */}
        <Section6 />
        <Section7 />
    </>
  );
};

export default AppHomepage;
