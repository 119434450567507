import React from "react";
import MainLayout from "../../modules/MainLayout.tsx";
import { HomepageLayoutProps } from "../../interfaces/homepageLayout.interface.ts";
import colors from "../../constants/colors.ts";
import Box from "@mui/material/Box";
import Image from "../media/Image.component.tsx";
import MediaDisplay from "./mediaDisplay.component.tsx";
import { useScreenSize } from "../../context/ScreenSize.context.tsx";
import {
  imagesFoldersTypes,
  mediaExtensionTypes,
  screenTypes,
} from "../../constants/app.constants.ts";
import TextPanel from "../text/TextPanel.component.tsx";
import Grid from "@mui/material/Grid2";

const HomepageLayout: React.FC<HomepageLayoutProps> = ({
  backgroundColor = colors.BLUE_DDEFFF,
  backgroundImage,
  backgroundImageOpacity,
  coverImage,
  media,
  mediaVariant,
  title,
  titleColour = colors.WHITE,
  description,
  descriptionColour = colors.WHITE,
  button,
  buttonColour,
  buttonBackgroundColour,
  areBubblesDisplayed = false,
  isDark = false,
  isFooterBackgroundTransparent = false,
}) => {
  const screenSize = useScreenSize();
  return (
    <>
      <MainLayout
        backgroundColor={backgroundColor}
        backgroundImage={coverImage && `/images/images/${coverImage}.svg`}
        isHeaderDisplayed={true}
        isDark={isDark}
        isFooterBackgroundTransparent={isFooterBackgroundTransparent}
        hasSlidingAnimation={false}
        marginBottom={0}
        isOverflowHidden={false}
      >
        {/* begin::Text grid */}
        {backgroundImage && (
          <Box position={"absolute"} sx={{ overflowX: "hidden" }} maxWidth={'100%'}>
            <Image
              name={backgroundImage}
              variant={imagesFoldersTypes.ELEMENTS}
              extension={mediaExtensionTypes.PNG}
              sx={{
                opacity: backgroundImageOpacity,
                maxHeight: "700px",
              }}
            />
          </Box>
        )}
        <Grid
          container
          display={"flex"}
          height={"550px"}
          spacing={3}
          overflow={"hidden"}
          padding={'0 80px'}
        >
          <Grid
            size={{ md: 5, sm: 12 }}
            container
            alignItems={"center"}
            marginTop={20}
            position={"relative"}
            
          >
            <TextPanel
              title={title}
              titleColor={titleColour}
              text={description}
              textColor={descriptionColour}
              buttonBackgroundColor={buttonBackgroundColour}
              buttonColor={buttonColour}
              button={button}
            />
          </Grid>
          {/* end::Text grid */}

          {/* begin:: Media */}
          <Grid
            size={{ md: 7, sm: 12 }}
            container
            alignItems={"center"}
            marginTop={13}
            justifyContent={"center"}
            position={"relative"}
          >
            <MediaDisplay
              media={media}
              areBubblesDisplayed={areBubblesDisplayed}
              variant={mediaVariant}
              justifyContent="center"
              sx={{width: '98%'}}
            />
          </Grid>
        </Grid>
      </MainLayout>
    </>
  );
};

export default HomepageLayout;
