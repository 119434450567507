import React from "react";
import TextImageGrid from "../../../components/grids/textImageGrid.component.tsx";
import AccordionText from "../../../components/text/accordionText.component.tsx";
import { onSiteMassageAccordion } from "../../../constants/homepage.constants.ts";
import MainLayout from "../../MainLayout.tsx";
import { imagesFoldersTypes } from "../../../constants/app.constants.ts";

const Section3: React.FC = () => {

  const imageArray = ['Image-1', 'Image-2', 'Image-3', 'Image-4'];

  const paragraphs = [
    "TEXT.DescoperaOnSiteChairMassage_Paragrah1",
    "TEXT.DescoperaOnSiteChairMassage_Paragrah2",
  ];

  return (
    <MainLayout>
      <TextImageGrid
        isTextLeftAligned={false}
        caption={"TITLE.TipuriDeMasaj"}
        title={"TITLE.DescoperaOnSiteChairMassage"}
        text={paragraphs}
        image={imageArray}
        imageVariant={imagesFoldersTypes.PAGE_IMAGES}
        alignImage={"center"}
      >
        <AccordionText items={onSiteMassageAccordion} />
      </TextImageGrid>
    </MainLayout>
  );
};

export default Section3;
