import React from "react";
import HomepageSecondaryLayout from "../../../components/homepages/homepageSecondaryLayout.tsx";
import colors from "../../../constants/colors.ts";
import Section2 from "./Section2.healthy-back.tsx";
import { maxTypes } from "../../../constants/app.constants.ts";

const Section1: React.FC = () => {
  return (
    <HomepageSecondaryLayout
      title={"TITLE.HealthyBack"}
      button={"BUTTON.SolicitaOferta"}
      media={'Secondary-Homepage-Image-Layout'}
      backgroundColor={colors.CYAN_CCFFFF}
      maxType={maxTypes.MAX_ACTIVE}
    >
      <Section2 />
    </HomepageSecondaryLayout>
  );
};

export default Section1;
