import { FooterColumns } from "../types/footer.types.ts";
import { imagesFoldersTypes, mediaExtensionTypes } from "./app.constants.ts";

const footerRows: FooterColumns = [
  [
    // ROW 1
    {
      section: "Contact",
      data: [
        {
          icon: 'Home',
          label: "TEXT.Adresa",
          redirectTo: ''
        },
        { icon: 'Phone', label: "+40 372 917 876", redirectTo: '' },
        { icon: 'Email', label: "office@wellington.ro", redirectTo: '' },
        { icon: 'Calendar', label: "TEXT.BookADemo", redirectTo: '' },
        { icon: 'Upper-Right-Arrow-Light', label: "TEXT.VizualizeazaToateDateleDeContact", redirectTo: '' },
      ],
    },

    //  MAX RELAX
    {
      section: "Max Relax",
      data: [
        { label: "TITLE.AboutOnSiteMassage", redirectTo: '' },
        { label: "TITLE.HealthyBackProgramOnSite", redirectTo: '' },
        { label: "TITLE.Beneficii", redirectTo: '' },
        { label: "TITLE.MotivelePentruCareClientiiNeAleg", redirectTo: '' },
        { label: "TITLE.EchipaDeTerapeuti", redirectTo: '' },
        { label: "TITLE.PrezentaNationala", redirectTo: '' },
      ],
    },
    // WELL EVENTS
    {
      section: "Well Events",
      data: [{ label: "Well Goodies", redirectTo: '' }, { label: "TITLE.EvenimenteTematiceDeWellbeing", redirectTo: '' }],
    },
    // WELL KNOWLEDGE
    {
      section: "Well Knowledge",
      data: [{ label: "Catalog", redirectTo: '' }, { label: "Campaign", redirectTo: '' }],
    },

    // WELL TECH
    {
      section: "Well Tech",
      data: [
        { label: "TITLE.CaracteristiciCheieModuleBeneficii", redirectTo: '' },
        { label: "TITLE.ProvocariCorporative", redirectTo: '' },
        { label: "TITLE.ObiceiuriSanatoase", redirectTo: '' },
        { label: "TITLE.ModulePersonalizateDeWellbeing", redirectTo: '' },
        { label: "TITLE.AplicatiePentruProvocariCorporative", redirectTo: '' },
      ],
    },
  ],

  // ROW 2
  [
    // ICONS - SOCIAL LINKS
    {
      section: "",
      data: [
        { icon: 'Facebook-Light', redirectTo: 'https://www.facebook.com/MaxRelaxMasajLaBirou/?locale=ro_RO' },
        { icon: 'Instagram-Light', redirectTo: 'https://www.instagram.com/maxrelaxmasajlabirou/profilecard/?igsh=MXdoa2N6OHZyY3A4eg==' },
        { icon: 'Linkedin-Logo', variant: mediaExtensionTypes.PNG, redirectTo: 'https://ro.linkedin.com/company/max-relax?original_referer=https%3A%2F%2Fwww.google.com%2F' },
        { icon: 'Twitter-Light', redirectTo: '' },
        { icon: 'Youtube-Light', redirectTo: '' },
      ],
    },

    // GET TO KNOW US
    {
      section: "Get to know us",
      data: [
        { label: "Who we are", redirectTo: '' },
        { label: "Policies & Accreditation", redirectTo: '' },
        { label: "Join the team", redirectTo: '' },
      ],
    },

    // OUR IMPACT
    {
      section: "Our Impact",
      data: [{ label: "Clients experience", redirectTo: '' }, { label: "Case studies", redirectTo: '' }],
    },

    // WELL RESOURCES
    {
      section: "Well Resources",
      data: [
        { label: "Webinars", redirectTo: '' },
        { label: "Podcast", redirectTo: '' },
        { label: "Videos", redirectTo: '' },
        { label: "Wellbeing Calendar", redirectTo: '' },
      ],
    },
    // BLOG
    {
      section: "Blog",
      data: [{ label: "Articles", redirectTo: '' }, { label: "News", redirectTo: '' }],
    },
  ],
];

export default footerRows;
