import React from "react";
import MainLayout from "../../MainLayout.tsx";
import TextImageGrid from "../../../components/grids/textImageGrid.component.tsx";
import ClientInfo from "../../../components/media/clientInfo.component.tsx";
import colors from "../../../constants/colors.ts";
import { imagesFoldersTypes } from "../../../constants/app.constants.ts";

const Section5: React.FC = () => {
  return (
    <MainLayout>
      <TextImageGrid
        icon={'Upper-Commas-Blue'}
        iconVariant={imagesFoldersTypes.ELEMENTS}
        title="The Testimonials Meaningful Headline for the Impactful Solution"
        text={"“The informative, and brief subheading that builds the interest around the created product. The copy encourages user to discover it.The informative, and brief subheading that builds the interest around the created product. The copy encourages user to discover it.”"}
        textColor={colors.GREY_707280}
        image={'Testimonial-Image'}
        imageVariant={imagesFoldersTypes.TEST_IMAGES}
        alignImage="center"
        hasVerticalPadding={true}
      >
        <ClientInfo
          motto="The copy encourages user to discover it."
          name="James Appleseed"
          nameColor={colors.BLUE_242737}
          role="CEO, Pineapple Inc."
          roleColor={colors.GREY_707280}
          icon={'Testimonial-Avatar'}
        />
      </TextImageGrid>
    </MainLayout>
  );
};

export default Section5;
