import React from "react";
import TextImageGrid from "../../../components/grids/textImageGrid.component.tsx";
import colors from "../../../constants/colors.ts";
import MainLayout from "../../MainLayout.tsx";

const Section4: React.FC = () => {
  const imageArray = ['Well-App-Phone', 'Well-App-Phone'];

  return (
    <MainLayout>
      <TextImageGrid
        backgroundColor={colors.BLUE_0067F6}
        icon={'Mobile-Apps-Icon-White'}
        title={"TITLE.DespreAplicatiaMaxRelax"}
        titleColor={colors.WHITE}
        subtitle={"SUBTITLE.DespreAplicatiaMaxRelax"}
        subtitleColour={colors.WHITE}
        text={"TEXT.DespreAplicatiaMaxRelax"}
        textColor={colors.WHITE}
        image={imageArray}
      />
    </MainLayout>
  );
};

export default Section4;
