import React from "react";
import Box from "@mui/material/Box";

import TextImageGrid from "../../../components/grids/textImageGrid.component.tsx";
import { healthyBackBenefits } from "../../../constants/homepage.constants.ts";
import TextBox from "../../../components/text/textBox.component.tsx";
import useStyles from "../../../hooks/styles.hook.tsx";
import MainLayout from "../../MainLayout.tsx";
import Image from "../../../components/media/Image.component.tsx";
import { imagesFoldersTypes } from "../../../constants/app.constants.ts";

const Section4: React.FC = () => {
  const { listItemsBoxStyles } = useStyles();
  return (
    <MainLayout>
      <TextImageGrid
        header={"TITLE.EfectelePrincipaleAsupraAngajatilor"}
        image={'Picture-Pattern'}
        imageVariant={imagesFoldersTypes.PATTERNS}
        alignImage="center"
      >
        <Box>
          {healthyBackBenefits?.map((item, index) => (
            <Box display={"flex"} key={index} gap={2} sx={listItemsBoxStyles}>
              <Image name={'Rounded-Check-Cyan'} variant={imagesFoldersTypes.ICONS} />
              <TextBox text={item} marginBottom={0}/>
            </Box>
          ))}
        </Box>
      </TextImageGrid>
    </MainLayout>
  );
};

export default Section4;
