import React from "react";
import TextImageGrid from "../../../components/grids/textImageGrid.component.tsx";
import { useIntl } from "react-intl";
import colors from "../../../constants/colors.ts";

const Section2: React.FC = () => {
  const intl = useIntl();

  return (
    <TextImageGrid
      title={intl.formatMessage({ id: "MASAJ_HOMEPAGE_SECTION2.Title" })}
      text={intl.formatMessage({ id: "MASAJ_HOMEPAGE_SECTION2.Text" })}
      titleColor={colors.BLUE_0067F6}
      textColor={colors.BLUE_242737}
    />
  );
};

export default Section2;
