import React, { useEffect, useRef } from "react";
import { useSpring, animated } from "@react-spring/web";
import Box from "@mui/material/Box";
import MediaDisplay from "../homepages/mediaDisplay.component.tsx";
import {
  ImagesFoldersTypes,
  imagesFoldersTypes,
} from "../../constants/app.constants.ts";
import Grid from "@mui/material/Grid2";

const calc = (o) => `translateY(${o * 0.55}px)`;

interface ScrollableMediaProps {
  media: any;
  variant?: ImagesFoldersTypes;
  areBubblesDisplayed?: boolean;
  outerBoxHeight: number;
  sx?: object;
}

function ScrollableMedia({
  media,
  variant = imagesFoldersTypes.PATTERNS,
  sx,
  areBubblesDisplayed,
  outerBoxHeight,
}: ScrollableMediaProps) {
  const ref = useRef<HTMLElement>();
  const [{ offset }, set] = useSpring(() => ({ offset: 0 }));

  const handleScroll = () => {
    if (ref.current) {
      const containerTop = ref.current.getBoundingClientRect().top;
      const scrollPosition = window.scrollY - containerTop;
      const newOffset = Math.min(
        Math.max(scrollPosition - 20, 0), // Start 20px down
        outerBoxHeight - 20 // End 20px before outer box ends
      );
      set({ offset: newOffset });
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  return (
    <Box ref={ref} sx={sx}>
      <animated.div
        style={{
          
          display: "flex",
          justifyContent: "flex-end",
          transform: offset.interpolate(calc),
          position: "relative",
        }}
      >
        <MediaDisplay
          media={media}
          areBubblesDisplayed={areBubblesDisplayed}
          variant={variant}
          // sx={{width: '60%'}}
        />
      </animated.div>
    </Box>
  );
}

export default ScrollableMedia;
