import React, { useEffect } from "react";
import Box from "@mui/material/Box";

import useStyles from "../../hooks/styles.hook.tsx";
import colors from "../../constants/colors.ts";
import { SlideshowProps } from "../../interfaces/media.interface.ts";
import "./slideshow.css";
import { imagesFoldersTypes } from "../../constants/app.constants.ts";

const Slideshow: React.FC<SlideshowProps> = ({
  items,
  justifyContent = 'flex-end',
  variant = imagesFoldersTypes.PAGE_IMAGES,
  areBubblesDisplayed = false,
}) => {
  const [index, setIndex] = React.useState(0);

  const delay = 5000;

  const { imagesScalingStyles } = useStyles();

  const handleDotClick = (index: number) => {
    setIndex(index);
  };

  useEffect(() => {
    const timeout = setTimeout(
      () =>
        setIndex((prevIndex) =>
          prevIndex === items?.length - 1 ? 0 : prevIndex + 1
        ),
      delay
    );

    return () => {
      clearTimeout(timeout);
    };
  }, [index, items]);

  return (
    <Box
      position={"relative"}
      overflow={"hidden"}
    >
      {/* Media */}
      <Box
        display={"flex"}
        justifyContent={justifyContent}
        sx={{
          whiteSpace: "nowrap",
          transition: "ease 1000ms",
        }}
        style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
      >
        {items?.map((item, i) => (
          <img
            key={i}
            className={`slideshow-image ${
              i === index ? "fade-in" : "fade-out"
            }`}
            src={`/images/${variant}/${item}.svg`}
          />
        ))}
      </Box>

      {/* Slideshow dots */}
      {areBubblesDisplayed && (
        <Box justifyContent={"center"} display={"flex"} gap={2}>
          {items?.map((_, counter) => (
            <Box
              key={counter}
              sx={{
                borderRadius: "50%",
                cursor: "pointer",
                transition: "background-color 0.3s ease",
                backgroundColor:
                  index === counter ? colors.BLUE_66A6FF : colors.WHITE,
                  height: '15px',
                  width: '15px'
              }}
              onClick={() => handleDotClick(counter)}
            />
          ))}
        </Box>
      )}
    </Box>
  );
};

export default Slideshow;
