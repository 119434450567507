import React from "react";
import MainLayout from "../../../modules/MainLayout.tsx";
import colors from "../../../constants/colors.ts";
import TextImageGrid from "../../../components/grids/textImageGrid.component.tsx";
import ListGrid from "../../../components/grids/listGrid.component.tsx";
import {
  indoorEventsList,
  outdoorEventsList,
} from "../../../constants/lists.constants.ts";
import useStyles from "../../../hooks/styles.hook.tsx";
import Button from "../../../components/button/button.component.tsx";
import TextBox from "../../../components/text/textBox.component.tsx";
import { imagesFoldersTypes, textTypes } from "../../../constants/app.constants.ts";
import Box from "@mui/material/Box";

const Section12: React.FC = () => {
  const { verticalPaddingBoxStyles, smallMarginBottomStyles } = useStyles();

  return (
    <MainLayout backgroundColor={colors.PINK_F4F4FB} isFooterDisplayed={true}>
      <Box sx={verticalPaddingBoxStyles}>
        <TextBox
          variant={textTypes.TITLE}
          text={"TITLE.CornerulPoateFiIntegrat"}
        />

        <TextImageGrid
          image={'Picture-Pattern'}
          imageVariant={imagesFoldersTypes.PATTERNS}
          alignImage="center"
          sx={verticalPaddingBoxStyles}
        >
          <ListGrid
            items={indoorEventsList}
            itemIcon={'Rounded-Check-Blue'}
            columnsNumber={12}
          />
        </TextImageGrid>

        <TextImageGrid
          image={'Picture-Pattern'}
          imageVariant={imagesFoldersTypes.PATTERNS}
          isTextLeftAligned={false}
          alignImage="center"
          sx={{ ...verticalPaddingBoxStyles, ...smallMarginBottomStyles }}
        >
          <ListGrid
            items={outdoorEventsList}
            itemIcon={'Rounded-Check-Blue'}
            columnsNumber={12}
          />
        </TextImageGrid>

        <Button
          backgroundColor={colors.BLUE_0067F6}
          color={colors.WHITE}
          text={"BUTTON.CereOfertaEveniment"}
        />
      </Box>
    </MainLayout>
  );
};

export default Section12;
