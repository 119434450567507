import React from "react";
import MainLayout from "../../MainLayout.tsx";
import useStyles from "../../../hooks/styles.hook.tsx";
import colors from "../../../constants/colors.ts";
import CardsGrid from "../../../components/grids/cardsGrid.component.tsx";
import { therapyTypes } from "../../../constants/cards.constants.ts";
import TextBox from "../../../components/text/textBox.component.tsx";
import { textTypes } from "../../../constants/app.constants.ts";

const Section6: React.FC = () => {
  const { verticalPaddingBoxStyles } = useStyles();

  return (
    <MainLayout backgroundColor={colors.CYAN_E6FFFF} marginBottom={0}>
      <TextBox
        variant={textTypes.TITLE}
        text={"TITLE.TipuriDeTerapii"}
        sx={verticalPaddingBoxStyles}
      />

      <CardsGrid
        cards={therapyTypes}
        isCardFullWidth={true}
        backgroundColor={colors.WHITE}
        buttonText="BUTTON.CitesteMaiMult"
        endIcon={'Right-Arrow-Blue'}
      />
    </MainLayout>
  );
};

export default Section6;
