import { createTheme } from "@mui/material";
import colors from "../constants/colors.ts";

const SELECTOR_THEME = createTheme({
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontFamily: "'Inter Semibold', 'sans-serif'",
          fontSize: 12,
          letterSpacing: 0.15,
          lineHeight: 2,
          color: colors.BLUE_242737,
        },
      },
    },

    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontFamily: "'Inter Semibold', 'sans-serif'",
          fontSize: 12,
          color: colors.BLUE_242737,
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          border: 0,
        },
      },
    },
  },
});

export default SELECTOR_THEME;
