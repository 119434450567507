import { createTheme } from "@mui/material";

const BUTTON_THEME = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        endIcon: {
          paddingRight: 4,
          marginLeft: 4,
        },

        startIcon: {
          paddingLeft: 4,
          marginRight: 4
        },
      },
    },
  },
});


export default BUTTON_THEME