import React from "react";
import { motion } from "framer-motion";
import colors from "../../../constants/colors.ts";
import useStyles from "../../../hooks/styles.hook.tsx";
import {
  imagesFoldersTypes,
  MaxTypes,
  maxTypes,
} from "../../../constants/app.constants.ts";
import Image from "../../media/Image.component.tsx";
import Navmenu from "./navmenu.component.tsx";
import { MenuItemType } from "../../../types/text.types.ts";

interface NavbarProps {
  items: MenuItemType[];
  maxType: MaxTypes;
}

const Navbar: React.FC<NavbarProps> = ({ items, maxType }) => {
  const { headerImageScalingStyles } = useStyles();
  
  return (
    <motion.nav
      style={{
        display: "flex",
        justifyContent: "space-between",
        backgroundColor: colors.WHITE,
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
        borderRadius: "48px",
        padding: "10px 24px",
      }}
    >
      {/* begin::Logo - left side */}
      <Image
        name={maxType === maxTypes.MAX_RELAX ? "Max-Relax" : "Max-Active"}
        variant={imagesFoldersTypes.LOGOS}
        sx={headerImageScalingStyles}
      />
      {/* end::Logo - left side */}

      {/* begin::Menu - right side */}
      <Navmenu items={items} />
    </motion.nav>
  );
};

export default Navbar;
