import React from "react";
import Box from "@mui/material/Box";

import colors from "../../../constants/colors.ts";
import Button from "../../../components/button/button.component.tsx";
import MainLayout from "../../MainLayout.tsx";
import TextPanel from "../../../components/text/TextPanel.component.tsx";
import Testimonials from "../../../components/slideshow/testimonials.component.tsx";
import { testimonials } from "../../../constants/slideshow.constants.ts";

const ClientsReviews: React.FC = () => {
  return (
    <MainLayout
      backgroundColor={colors.MAIN_BLUE_800}
      sx={{padding: '40px 0'}}
    >
      {/* Title & Description */}
      <TextPanel
        title={"TITLE.Testimonials"}
        titleColor={colors.WHITE}
        text={"TEXT.Testimonials"}
        textColor={colors.WHITE}
        alignItems="center"
        style={{
          marginBottom: 5
        }}
      />

      {/* Reviews Container */}
      <Testimonials testimonials={testimonials} />

      {/* Button */}
      <Box textAlign={"center"} marginTop={5}>
        <Button text={"BUTTON.Testimonials"} />
      </Box>
    </MainLayout>
  );
};

export default ClientsReviews;
