import React, { ReactNode } from "react";
import Box from "@mui/material/Box";
import Header from "../components/header/header.component.tsx";

import Newsletter from "../components/newsletter/newsletter.component.tsx";
import Footer from "../components/footer/footer.component.tsx";
import Links from "../components/footer/links.component.tsx";
import useStyles from "../hooks/styles.hook.tsx";
import { useScreenSize } from "../context/ScreenSize.context.tsx";
import { MaxTypes, screenTypes } from "../constants/app.constants.ts";
import CollapsedHeader from "../components/header/collapsedHeader.Component.tsx";
import { useInView } from "@react-spring/web";
import './App.css'
import clsx from "clsx";

const MainLayout: React.FC<{
  isHeaderDisplayed?: boolean;
  isFooterDisplayed?: boolean;
  marginBottom?: number;
  backgroundColor?: string;
  backgroundImage?: string;
  isDark?: boolean;
  isFooterBackgroundTransparent?: boolean;
  isOverflowHidden?: boolean;
  hasSlidingAnimation?: boolean;
  children: ReactNode;
  maxType?: MaxTypes;
  sx?: object;
}> = ({
  backgroundColor,
  backgroundImage,
  maxType,
  children,
  isDark = false,
  isHeaderDisplayed = false,
  isFooterDisplayed = false,
  marginBottom = 80,
  isFooterBackgroundTransparent,
  hasSlidingAnimation = true,
  isOverflowHidden = true,
  sx,
}) => {
  const screenType = useScreenSize();

  const buildInteractionObserverThreshold = (count = 100) => {
    const threshold: any[] = [];
    const parts = 1 / count;

    for (let i = 0; i <= count; i++) {
      threshold.push(parseFloat((parts * i).toFixed(2)));
    }

    return threshold;
  };

  const [ref, springs] = useInView(
    () => ({
      from: {
        opacity: 0,
        y: 80,
      },
      to: {
        opacity: 1,
        y: 0,
      },
    }),
    {
      rootMargin: "-45% 0px -45% 0px",
      amount: buildInteractionObserverThreshold(),
    }
  );

  return (
    <>
      {/* // external container - background color, especially for 100% width regardless the device */}
      <div
      className={clsx("outer-div", {
        "small-screen": window.innerWidth < 1280,
        "phone-screen": window.innerWidth < 600,
      })}
        style={{
          backgroundColor: backgroundColor,
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "flex",
          justifyContent: "center",
          marginBottom: marginBottom
        }}
      >
        {isHeaderDisplayed &&
          (screenType === screenTypes.PHONE ||
          screenType === screenTypes.TABLET ? (
            <CollapsedHeader maxType={maxType} />
          ) : (
            <Header
              isDark={isDark}
              backgroundColor={
                isFooterBackgroundTransparent ? "none" : backgroundColor
              }
              maxType={maxType}
            />
          ))}

        {/* content container */}
        <div
          style={{
            width: "100%",
            maxWidth: "1280px",
            overflow: isOverflowHidden ? 'hidden' : 'unset',
            ...sx,
            //...boxStyles,
          }}
        >
          {/* {hasSlidingAnimation ? (
          <animated.div ref={ref} style={springs}>
            {children}
          </animated.div>
        ) : (
          children
        )} */}
          {children}
        </div>
      </div>

      {isFooterDisplayed && (
        <>
          <Newsletter />
          <Links />
          <Footer />
        </>
      )}
    </>
  );
};

export default MainLayout;
