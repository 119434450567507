import React from "react";
import MainLayout from "../../MainLayout.tsx";
import TextPanel from "../../../components/text/TextPanel.component.tsx";
import SliderCards from "../../../components/slideshow/sliderCards.component.tsx";
import { therapyTypes } from "../../../constants/cards.constants.ts";

const TherapyTypes: React.FC = () => {
  return (
    <MainLayout marginBottom={20}>
      <TextPanel
        title={"Tipuri de terapii"}
        text={
          "Terapiile la locul de muncă contribuie semnificativ la îmbunătățirea calității vieții la locul de muncă și la prevenirea problemelor de sănătate pe termen lung."
        }
        alignItems={"center"}
        style={{
          marginBottom: 3
        }}
      />

      <SliderCards items={therapyTypes} />
    </MainLayout>
  );
};

export default TherapyTypes;
