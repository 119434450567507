import React from "react";
import MainLayout from "../../MainLayout.tsx";
import InfoBoxSlider from "../../../components/slideshow/InfoBoxSlider.component.tsx";
import { employeesInfoSliderItems } from "../../../constants/slideshow.constants.ts";

const Section5: React.FC = () => {
  return (
    <MainLayout>
      <InfoBoxSlider items={employeesInfoSliderItems} />
    </MainLayout>
  );
};

export default Section5;
