import React from "react";
import MainLayout from "../../MainLayout.tsx";
import TextImageGrid from "../../../components/grids/textImageGrid.component.tsx";
import { imagesFoldersTypes } from "../../../constants/app.constants.ts";
import colors from "../../../constants/colors.ts";

const MaxRelaxMobileApp: React.FC = () => {
  return (
    <MainLayout marginBottom={24}>
      <TextImageGrid
        image={"Max-Mobile-App"}
        imageVariant={imagesFoldersTypes.IMAGES}
        isTextLeftAligned={false}
        backgroundColor={colors.BLUE_0067F6}
        caption="Aplicație Mobilă"
        textColor={colors.WHITE}
        title={"Max Relax Mobile App"}
        titleColor={colors.WHITE}
        text={
          "Noua aplicație Max Relax, disponibilă atât pe IOS cât si pe android, facilitează programarea, gestionarea și monitorizarea programului de masaj."
        }
        buttonLabel={"BUTTON.FindOutMore"}
        buttonEndIcon={"Right-Arrow-White"}
        buttonTextColor={colors.WHITE}
        buttonBgColor="transparent"
      />
    </MainLayout>
  );
};

export default MaxRelaxMobileApp;
