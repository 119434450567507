import React from "react";
import colors from "../../../constants/colors.ts";
import HomepageLayout from "../../../components/homepages/homepageLayout.component.tsx";
import { imagesFoldersTypes } from "../../../constants/app.constants.ts";

const Section1: React.FC = () => {
  return (
    <HomepageLayout
      isDark={true}
      media={'Configurator'}
      mediaVariant={imagesFoldersTypes.IMAGES}
      title={"TITLE.ConfiguratorulAngajatilor"}
      titleColour={colors.BLUE_242737}
      backgroundColor={colors.BLUE_F0FAFF}
    />
  );
};

export default Section1;
