import React, { createContext, useContext, useRef } from "react";

interface TopPageRefType {
  topPageRef: React.RefObject<HTMLDivElement | undefined>;
}

const PageContext = createContext<TopPageRefType | undefined>(undefined);

export const PageProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const topPageRef = useRef<HTMLDivElement | null>(null);

  return (
    <PageContext.Provider value={{ topPageRef }}>
      {children}
    </PageContext.Provider>
  );
};

export const usePageContext = () => {
  const context = useContext(PageContext);

  if (!context) {
    throw new Error("usePageContext must be used withing a pageProvider");
  }

  return context;
};
