import colors from "../constants/colors.ts";

export type ColorsKeys = keyof typeof colors;

export enum ColorsType {
  BLUE = "Blue",
  CYAN = "Cyan",
  RED = "Red",
  GREY = "Grey",
  WHITE = "White"
}
