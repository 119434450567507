export const screenTypes = {
  PHONE: "phone",
  TABLET: "tablet",
  LAPTOP: "laptop",
  DESKTOP: "desktop",
  ULTRAWIDE: "ultrawide",
};

export type ScreenTypes = (typeof screenTypes)[keyof typeof screenTypes];

export const textTypes = {
  CAPTION: "caption",
  TITLE: "title",
  SUBTITLE: "subtitle",
  TEXT: "text",
  BUTTON: "button"
};

export type TextTypes = (typeof textTypes)[keyof typeof textTypes];

export const maxTypes = {
  MAX_ACTIVE: "max-active",
  MAX_RELAX: "max-relax",
};

export type MaxTypes = (typeof maxTypes)[keyof typeof maxTypes];

export const imagesFoldersTypes = {
  ICONS: 'icons',
  LOGOS: 'logos',
  PATTERNS: 'patterns',
  VIDEOS: 'videos',
  ELEMENTS: 'elements',
  IMAGES: 'images',
  PAGE_IMAGES: 'page-images',
  TEST_IMAGES: 'test-images', // used as replaced values for official avatars / icons etc.,
}

export type ImagesFoldersTypes = (typeof imagesFoldersTypes)[keyof typeof imagesFoldersTypes]

export const mediaExtensionTypes = {
  PNG: 'png',
  JPEG: 'jpeg',
  JPG: 'jpg',
  SVG: 'svg'
}

export type MediaExtensionsTypes = (typeof mediaExtensionTypes)[keyof typeof mediaExtensionTypes]