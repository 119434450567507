import React from "react";
import MainLayout from "../../MainLayout.tsx";
import { massageTypes } from "../../../constants/cards.constants.ts";
import SliderCards from "../../../components/slideshow/sliderCards.component.tsx";
import TextPanel from "../../../components/text/TextPanel.component.tsx";

const Section6: React.FC = () => {
  return (
    <MainLayout marginBottom={20}>
      <TextPanel
        alignItems="center"
        title={"Tipuri de masaj"}
        text={
          "În funcție de profilul și nevoile fiecărui client, Max Relax oferă o varietate de tipuri de masaj."
        }
        textAlign={"center"}
        style={{
          marginBottom: 3
        }}
      />

      <SliderCards items={massageTypes} />
    </MainLayout>
  );
};

export default Section6;
