import React from "react";
import TextImageGrid from "../../../components/grids/textImageGrid.component.tsx";
import colors from "../../../constants/colors.ts";
import MainLayout from "../../MainLayout.tsx"
import { imagesFoldersTypes } from "../../../constants/app.constants.ts";

const Section7: React.FC = () => {
  return (
    <MainLayout>
      <TextImageGrid
        backgroundColor={colors.TURQUOISE_81FFFF}
        hasVerticalPadding={true}
        title={"TITLE.TipuriDeTerapii"}
        text={"TEXT.Terapii"}
        image={'Image-Section'}
        imageVariant={imagesFoldersTypes.PATTERNS}
        alignImage="flex-end"
      />
    </MainLayout>
  );
};

export default Section7;
