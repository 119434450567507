import { screenTypes } from "../constants/app.constants.ts";

export const gridHeightStyle = {
    [screenTypes.PHONE]: {
        height: '300px'
    },

    [screenTypes.TABLET]: {
        height: '350px'
    },

    [screenTypes.LAPTOP]: {
        height: '150px'
    },

    [screenTypes.DESKTOP]: {
        height: '200px'
    },

    [screenTypes.ULTRAWIDE]: {
        height: '500px'
    }
}