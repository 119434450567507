import React from "react";
import TextImageGrid from "../../../components/grids/textImageGrid.component.tsx";
import TextPanel from "../../../components/text/TextPanel.component.tsx";
import { imagesFoldersTypes } from "../../../constants/app.constants.ts";

const Section10: React.FC = () => {

  return (
    <TextImageGrid
      title="TITLE.3PasiInCompanie"
      isTextLeftAligned={false}
      image={'Picture-Pattern'}
      imageVariant={imagesFoldersTypes.PATTERNS}
      alignImage="center"
    >
      {[1, 2, 3].map((index) => (
        <TextPanel
          key={index}
          subtitle={`TITLE.Pasul${index}`}
          text={`TEXT.3PasiInCompanie_Paragraf${index}`}
        />
      ))}
    </TextImageGrid>
  );
};

export default Section10;
