import React from "react";
import { Box, Fab } from "@mui/material";
import Grid from "@mui/material/Grid2";
import MainLayout from "../../modules/MainLayout.tsx";
import colors from "../../constants/colors.ts";
import useStyles from "../../hooks/styles.hook.tsx";
import { FooterLink, FooterSection } from "../../types/footer.types.ts";
import AnimatedBox from "../button/animatedBox.component.tsx";
import { usePageContext } from "../../context/Page.context.tsx";
import TextBox from "../text/textBox.component.tsx";
import {
  imagesFoldersTypes,
  textTypes,
} from "../../constants/app.constants.ts";
import Image from "../media/Image.component.tsx";
import footerRows from "../../constants/footer-links.constants.ts";

// -----------------------------------------------------------------
// |  LOGO   |           |             |                |           |
// |----------------------------------------------------------------
// | CONTACT | MAX RELAX | WELL EVENTS | WELL KNOWLEDGE | WELL TECH |
// | -items- |  -items-  |   -items-   |     -items-    |  -items-  |
// |----------------------------------------------------------------
// |  ICONS  |  KNOW US  |  OUR IMPACT | WELL RESOURCES |    BLOG   |
// |         |  -items-  |   -items-   |     -items-    |  -items-  |
// |----------------------------------------------------------------
// |   TEXT  |   ICON_1  |    ICON_2   |      ICON_3    |           |
// |_________________________________________________________________

const Links: React.FC = () => {
  const { topPageRef } = usePageContext();

  const {
    imagesScalingStyles,
    smallPaddingStyles,
    footerWideGapStyles,
    verticalMarginsStyles,
    captionFontSizeStyles,
    verticalPaddingBoxStyles,
    footerLinksTitleStyles,
  } = useStyles();

  const returnToTop = () => {
    if (topPageRef?.current) {
      topPageRef?.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <MainLayout backgroundColor={colors.GREY_575967} marginBottom={0}>
      <Box
        width={{ xs: "80%", sm: "50%", md: "30%" }}
        sx={verticalPaddingBoxStyles}
      >
        <Image
          name={"Wellington-Logo-Colored"}
          variant={imagesFoldersTypes.LOGOS}
        />
      </Box>

      {/* Content grid - 2 rows */}
      <Grid
        container
        display={"flex"}
        size={12}
        justifyContent="space-between"
        alignItems="flex-start"
      >
        {/* First row */}
        {footerRows[0]?.map((item: FooterSection, i) => (
          <Grid
            key={i}
            container
            size={{ md: 2, sm: 6, xs: 12 }}
            sx={i === 0 ? footerWideGapStyles : { flexGrow: 1 }}
            display={"flex"}
            justifyContent={{ md: "flex-start", sm: "center", xs: "center" }}
          >
            <Box
              sx={verticalMarginsStyles}
              textAlign={{ md: "left", xs: "center" }}
            >
              {item?.section && (
                <TextBox
                  variant={textTypes.SUBTITLE}
                  color={colors.WHITE}
                  sx={footerLinksTitleStyles}
                  text={item?.section}
                />
              )}

              {item?.data?.map((link: FooterLink, l) => (
                <Box
                  key={l}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={{ md: "flex-start", xs: "center" }}
                  textAlign={{ md: "left", sm: "center", xs: "center" }}
                  gap={1}
                >
                  {link?.icon && (
                    <Image
                      name={link?.icon}
                      variant={imagesFoldersTypes.ICONS}
                    />
                  )}
                  <AnimatedBox
                    paddingLeft={{ md: 0, sm: "" }}
                    route={link?.redirectTo}
                    textAlign={{ md: "left", sm: "center", xs: "center" }}
                    sx={{ cursor: "pointer" }}
                    color={colors.GREY_CECFDA}
                    text={link?.label}
                  />
                </Box>
              ))}
            </Box>
          </Grid>
        ))}
      </Grid>

      <Grid
        container
        display={"flex"}
        justifyContent="space-between"
        alignItems="flex-start"
      >
        {footerRows[1]?.map((item: FooterSection, j) => (
          <Grid
            key={j}
            size={{ md: 2, sm: 6, xs: 12 }}
            sx={j === 0 ? footerWideGapStyles : {}}
            alignItems={
              item?.section === "" ? { xs: "center", md: "flex-start" } : ""
            }
            justifyContent={"center"}
          >
            <Box
              textAlign={{ md: "left", xs: "center" }}
              sx={verticalMarginsStyles}
            >
              {item?.section && (
                <TextBox
                  variant={textTypes.SUBTITLE}
                  color={colors.WHITE}
                  text={item?.section}
                  sx={footerLinksTitleStyles}
                />
              )}

              {item?.section === "" ? (
                <Box
                  display={"flex"}
                  justifyContent={{ md: "space-between", xs: "center" }}
                  textAlign={{ md: "left", sm: "center", xs: "center" }}
                >
                  {item?.data?.map((link: FooterLink, l) => (
                    <AnimatedBox
                      key={l}
                      paddingLeft={0}
                      route={link?.redirectTo}
                      minWidth={3}
                    >
                      {link.icon && (
                        <Image name={link?.icon} variant={imagesFoldersTypes.LOGOS} extension={link?.variant} />
                      )}
                    </AnimatedBox>
                  ))}
                </Box>
              ) : (
                item?.data?.map((link, l) => (
                  <Box
                    key={l}
                    display={"flex"}
                    alignItems={"cener"}
                    justifyContent={{ md: "flex-start", xs: "center" }}
                    textAlign={{ md: "left", sm: "center", xs: "center" }}
                    gap={1}
                  >
                    <AnimatedBox
                      textAlign={{ md: "left", xs: "center" }}
                      paddingLeft={{ md: 0, sm: "" }}
                      route={link?.redirectTo}
                      textSx={captionFontSizeStyles}
                      color={colors.GREY_CECFDA}
                      text={link?.label}
                    />
                  </Box>
                ))
              )}
            </Box>
          </Grid>
        ))}
      </Grid>

      {/* Line */}
      <Box>
        {/* Return to top button */}
        <Box
          sx={smallPaddingStyles}
          justifyContent={"flex-end"}
          display={"flex"}
        >
          <Fab
            size="medium"
            onClick={() => returnToTop()}
            sx={{
              backgroundColor: colors.GREY_151A41,
              opacity: "20%",
            }}
          >
            <Image
              name={"Circled-Upper-Arrow-Light"}
              variant={imagesFoldersTypes.ELEMENTS}
            />
          </Fab>
        </Box>

        {/* Full width line */}
        <Box
          sx={{
            border: "1px solid",
            borderColor: colors.GREY_707280,
            width: "100%",
          }}
        />
      </Box>

      {/* Certifications */}
      <Grid
        container
        spacing={3}
        display={"flex"}
        justifyContent={"space-between"}
        size={12}
        sx={verticalPaddingBoxStyles}
        alignItems={"center"}
      >
        <Grid
          container
          size={{ md: 3, sm: 6, xs: 12 }}
          justifyContent={{ md: "flex-start", xs: "center" }}
        >
          <TextBox
            variant={textTypes.CAPTION}
            color={colors.WHITE}
            fontWeight={600}
            text={"TITLE.CertificariInternationale"}
          />
        </Grid>

        <Grid
          container
          size={{ md: 2, sm: 6, xs: 12 }}
          justifyContent={{ md: "flex-start", xs: "center" }}
          //sx={verticalMarginsStyles}
        >
          <Image name={"Grey-Circle"} variant={imagesFoldersTypes.ELEMENTS} />
        </Grid>

        <Grid
          container
          size={{ md: 2, sm: 6, xs: 12 }}
          justifyContent={{ md: "flex-start", xs: "center" }}
          //sx={verticalMarginsStyles}
        >
          <Image
            name={"Grey-Rectangle"}
            variant={imagesFoldersTypes.ELEMENTS}
          />
        </Grid>

        <Grid
          container
          size={{ md: 2, sm: 6, xs: 12 }}
          justifyContent={{md: "flex-start", xs: "center"}}
          //sx={vert}
        >
          <Image
            name={"Grey-Rectangle-Wide"}
            variant={imagesFoldersTypes.ELEMENTS}
          />
        </Grid>

        {/* a 5th empty grid to match the upper collumns alignment */}
        <Grid container size={{ md: 2, sm: 6, xs: 12 }} />
      </Grid>
    </MainLayout>
  );
};

export default Links;
