import React, { useEffect } from "react";
import {
  ImagesFoldersTypes,
  MediaExtensionsTypes,
  mediaExtensionTypes,
} from "../../constants/app.constants.ts";

const Image: React.FC<{
  name: string;
  size?: number | string;
  sx?: React.CSSProperties;
  variant: ImagesFoldersTypes;
  extension?: MediaExtensionsTypes;
}> = ({ name, sx, variant, extension = mediaExtensionTypes.SVG, size }) => {
  const image: string = `/images/${variant}/${name}.${extension}`;

  const preloadImage = (src: string): Promise<string> => {
    return new Promise((resolve, reject) => {
      const img = new window.Image();
      img.src = src;
      img.onload = () => resolve(src); // Optionally pass the `src` back on resolve
      img.onerror = () => reject(new Error(`Failed to load image: ${src}`));
    });
  };

  useEffect(() => {
    if (image) {
      preloadImage(image)
        .catch((error) => console.error(error));
    }
  }, [image]);

  return <img src={image} alt={name} style={sx} loading="lazy" width={size} height={size}/>;
};

export default Image;
