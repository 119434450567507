import React from "react";
import MainLayout from "../../MainLayout.tsx";
import TextImageGrid from "../../../components/grids/textImageGrid.component.tsx";
import colors from "../../../constants/colors.ts";
import { imagesFoldersTypes } from "../../../constants/app.constants.ts";

const Section3: React.FC = () => {
  const imageArray = ['Picture-Pattern', 'Secondary-Homepage-Image-Layout']

  return (
    <MainLayout>
      <TextImageGrid
        caption={"TITLE.DespreEvenimente"}
        title={"TITLE.DescoperaCornerulDeMasaj"}
        text={"TEXT.DescoperaCornerulDeMasaj"}
        buttonLabel={"BUTTON.ImplementeazaAcum"}
        textColor={colors.GREY_575967}
        image={imageArray}
        imageVariant={imagesFoldersTypes.PATTERNS}
        alignImage={"center"}
        buttonBgColor={colors.BLUE_0067F6}
        buttonTextColor={colors.WHITE}
        isTextLeftAligned={false}
      />
    </MainLayout>
  );
};

export default Section3;
