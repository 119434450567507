import React from "react";
import colors from "../../../constants/colors.ts";
import MainLayout from "../../MainLayout.tsx";
import Box from "@mui/material/Box";
import TextPanel from "../../../components/text/TextPanel.component.tsx";
import useStyles from "../../../hooks/styles.hook.tsx";
import LinkGrid from "../../../components/grids/linkGrid.component.tsx";
import { satisfiedCustomersGridItems } from "../../../constants/grids.contants.ts";

const TopBrands: React.FC = () => {
  const { verticalPaddingBoxStyles, smallMarginBottomStyles } = useStyles();

  return (
    <MainLayout>
      <TextPanel
        alignItems="center"
        title={"Top Brands. XXX+ satisfied customers"}
        text={"You are in good company"}
        textColor={colors.GREY_707280}
        captionColor={colors.GREY_707280}
        textAlign={"center"}
        style={{
          marginBottom: 5,
        }}
      />

      <LinkGrid
        columnsNumber={4}
        itemBackgroundColour={colors.GREY_F9F9FB}
        items={satisfiedCustomersGridItems}
        sx={smallMarginBottomStyles}
        md={6}
      />
    </MainLayout>
  );
};

export default TopBrands;
