import React from "react";
import TextImageGrid from "../../../components/grids/textImageGrid.component.tsx";
import MainLayout from "../../MainLayout.tsx";
import colors from "../../../constants/colors.ts";
import { imagesFoldersTypes } from "../../../constants/app.constants.ts";

const Section7: React.FC = () => {
  return (
    <MainLayout isFooterDisplayed={true}>
      <TextImageGrid
        backgroundColor={colors.PINK_F4F4FB}
        title={"TITLE.DespreConfiguratorulMax"}
        text={"TEXT.DespreConfiguratorulMax"}
        buttonLabel={"BUTTON.ConfigureazaProgramulDeMasaj"}
        buttonBgColor={colors.BLUE_0067F6}
        buttonTextColor={colors.WHITE}
        image={'App-Connection'}
        imageVariant={imagesFoldersTypes.PAGE_IMAGES}
        alignImage="center"
        lg={7}
      />
    </MainLayout>
  );
};

export default Section7;
