import { screenTypes } from "../constants/app.constants.ts";

export const mainLayoutBoxStyle = {
  [screenTypes.PHONE]: {
    boxSizing: "border-box",
    overflowX: 'hidden',
    padding: '0 10px',
  },

  [screenTypes.TABLET]: {
    padding: "0 50px",
    boxSizing: "border-box",
    overflowX: 'hidden'
  },

  [screenTypes.LAPTOP]: {
    boxSizing: "border-box",
    padding: "0 80px",
    overflowX: 'clip'
  },

  [screenTypes.DESKTOP]: {
    boxSizing: "border-box",
    padding: "0 80px",
    overflowX: 'clip'
  },

  [screenTypes.ULTRAWIDE]: {
    boxSizing: "border-box",
    padding: "0 250px",
    overflowX: 'clip',
  },
};

export const mainLayoutPaddingStyle = {
  [screenTypes.PHONE]: {
    padding: '0 10px'
  },

  [screenTypes.TABLET]: {
    padding: "0 50px"
  },

  [screenTypes.LAPTOP]: {
    padding: "0 110px"
  },

  [screenTypes.DESKTOP]: {
    padding: "0 110px"
  },

  [screenTypes.ULTRAWIDE]: {
    padding: "0 250px"
  },
};

export const smallMarginBottomStyle = {
  [screenTypes.PHONE]: {
    marginBottom: 3,
  },

  [screenTypes.TABLET]: {
    marginBottom: 4,
  },

  [screenTypes.LAPTOP]: {
    marginBottom: 5,
  },

  [screenTypes.DESKTOP]: {
    marginBottom: 5,
  },

  [screenTypes.ULTRAWIDE]: {
    marginBottom: 6,
  },
};
export const smallMarginTopStyle = {
  [screenTypes.PHONE]: {
    marginTop: 3,
  },

  [screenTypes.TABLET]: {
    marginTop: 4,
  },

  [screenTypes.LAPTOP]: {
    marginTop: 5,
  },

  [screenTypes.DESKTOP]: {
    marginTop: 5,
  },

  [screenTypes.ULTRAWIDE]: {
    marginTop: 6,
  },
};

export const colouredComponentHeightStyle = {
  [screenTypes.PHONE]: {
    height: '400px'
  },
  [screenTypes.TABLET]: {
    height: '500px'
  },
  [screenTypes.LAPTOP]: {
    height: '600px'
  },
  [screenTypes.DESKTOP]: {
    height: '700px'
  },
  [screenTypes.ULTRAWIDE]: {
  height: '800px'
  }
}

export const mainBackgroundColourHeightStyle = {
  [screenTypes.PHONE]: {
    height: '60vh'
  },
  [screenTypes.TABLET]: {
    height: '65vh'
  },
  [screenTypes.LAPTOP]: {
    height: '50vh'
  },
  [screenTypes.DESKTOP]: {
    height: '70vh'
  },
  [screenTypes.ULTRAWIDE]: {
    height: '75vh'
  }
}

export const secondaryBackgroundColourHeightStyle = {
  [screenTypes.PHONE]: {
    height: '70vh'
  },
  [screenTypes.TABLET]: {
    height: '80vh'
  },
  [screenTypes.LAPTOP]: {
    height: '70vh'
  },
  [screenTypes.DESKTOP]: {
    height: '70vh'
  },
  [screenTypes.ULTRAWIDE]: {
    height: '75vh'
  }
}

export const marginTopStyle = {
  [screenTypes.PHONE]: {
    marginTop: '30px'
  },
  [screenTypes.TABLET]: {
    marginTop: '50px'
  },
  [screenTypes.LAPTOP]: {
    marginTop: '70px'
  },
  [screenTypes.DESKTOP]: {
    marginTop: '80px'
  },
  [screenTypes.ULTRAWIDE]: {
    marginTop: '100px'
  }
}

export const marginBottomStyle = {
  [screenTypes.PHONE]: {
    marginBottom: '30px'
  },
  [screenTypes.TABLET]: {
    marginBottom: '50px'
  },
  [screenTypes.LAPTOP]: {
    marginBottom: '70px'
  },
  [screenTypes.DESKTOP]: {
    marginBottom: '80px'
  },
  [screenTypes.ULTRAWIDE]: {
    marginBottom: '100px'
  }
}