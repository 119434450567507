import React from "react";
import Section1 from "./sections/Section1.events.tsx";
import Section3 from "./sections/Section3.events.tsx";
import Section4 from "./sections/Section4.events.tsx";
import Section5 from "./sections/Section5.events.tsx";
import Section6 from "./sections/Section6.events.tsx";
import Section7 from "./sections/Section7.events.tsx";
import Section8 from "./sections/Section8.events.tsx";
import Section9 from "./sections/Section9.events.tsx";
import Section10 from "./sections/Section10.events.tsx";
import Section12 from "./sections/Section12.events.tsx";

const EventsHomepage: React.FC = () => {
  return (
    <>
      <Section1 />
      {/* <Section2 /> is included in section 1*/}
      <Section3 />
      <Section4 />
      <Section5 />
      <Section6 />
      <Section7 />
      <Section8 />
      <Section9 />
      <Section10 />
      <Section12 />
    </>
  );
};

export default EventsHomepage;
