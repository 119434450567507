import { FooterLink } from "../types/footer.types.ts";
import { imagesFoldersTypes } from "./app.constants.ts";

export const servicesGridItems: FooterLink[] = [
  {
    icon: "Transport-Icon",
    label: "TITLE.TransportLaCompaniaDvs",
    redirectTo: "",
  },
  {
    icon: "Status-Icon",
    label: "TITLE.Rapoarte&StatisticiPersonalizate",
    redirectTo: "",
  },
  {
    icon: "Massage-Chair-Icon",
    label: "TITLE.ScauneMeseAccesorii",
    redirectTo: "",
  },
  {
    icon: "Music-System-Icon",
    label: "TITLE.SistemAudioMuzicaRelaxanta",
    redirectTo: "",
  },
  {
    icon: "Aromatherapy-Icon",
    label: "TITLE.Aromaterapie",
    redirectTo: "",
  },
  {
    icon: "Therapists-Icon",
    label: "TITLE.TerapeutiProfesionisti",
    redirectTo: "",
  },
  {
    icon: "Mobile-Apps-Icon",
    label: "TITLE.AplicatieIosAndroid",
    redirectTo: "",
  },
  {
    icon: "Lotions-Icon",
    label: "TITLE.UleiuriCremeSolutii",
    redirectTo: "",
  },
];

export const mainEffectsGridItems: FooterLink[] = [
  {
    icon: "Posture-Icon",
    label: "TITLE.PosturaCorecta",
  },
  {
    icon: "Muscle-Icon",
    label: "TITLE.DurereMusculara",
  },
  {
    icon: "Energy-Icon",
    label: "TITLE.NivelDeEnergie",
  },
  {
    icon: "Mind-Icon",
    label: "TITLE.FocusSiConcentrare",
  },
  {
    icon: "Balance-Icon",
    label: "TITLE.Echilibru&StareDeBine",
  },
  {
    icon: "Immunity-System-Icon",
    label: "TITLE.SistemImunitar",
  },
  {
    icon: "Pillow-Icon",
    label: "TITLE.SomnOdihnitor",
  },
  {
    icon: "Circulatory-System-Icon",
    label: "TITLE.SistemulCirculator",
  },
];

export const healthyBackGridItems: FooterLink[] = [
  {
    icon: "Posture-Icon",
    label: "TITLE.Postura",
  },
  {
    icon: "Muscle-Icon",
    label: "TITLE.EliminaDurereaMusculara",
  },
  {
    icon: "New-Icon-Pattern",
    label: "TITLE.Tonifiere&FortaMusculara",
  },
  {
    icon: "New-Icon-Pattern",
    label: "TITLE.CresteFlexibilitatea",
  },
  {
    icon: "Energy-Icon",
    label: "TITLE.NivelDeEnergie",
  },
  {
    icon: "New-Icon-Pattern",
    label: "TITLE.PrevenireAfectiuniMusculoscheletice",
  },
];

export const satisfiedCustomersGridItems: FooterLink[] = [
  { icon: 'Arrange', variant: imagesFoldersTypes.LOGOS },
  { icon: 'Rasterize', variant: imagesFoldersTypes.LOGOS },
  { icon: 'Substract', variant: imagesFoldersTypes.LOGOS },
  { icon: 'Superbly', variant: imagesFoldersTypes.LOGOS },
  // { icon: 'Freeform', variant: imagesFoldersTypes.LOGOS },
  // { icon: 'Squarter', variant: imagesFoldersTypes.LOGOS },
];
