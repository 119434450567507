import React from "react";
import { benefitsRow } from "../../../constants/homepage.constants.ts";
import BenefitsGrid from "../../../components/grids/benefitsGrid.component.tsx";
import colors from "../../../constants/colors.ts";
import MainLayout from "../../MainLayout.tsx";
import { textTypes } from "../../../constants/app.constants.ts";
import TextBox from "../../../components/text/textBox.component.tsx";

const MassageBenefits: React.FC = () => {
  return (
    <MainLayout>
      {/* Page Title */}
      <TextBox
        variant={textTypes.TITLE}
        fontWeight={600}
        fontFamily={"Inter Regular"}
        fontSize={36}
        text={"TITLE.BeneficiileMasajului"}
        textAlign={'center'}
      />

      <BenefitsGrid
        containers={benefitsRow}
        backgroundColor={colors.BLUE_F1FAFF}
        color={colors.BLUE_242737}
      />
    </MainLayout>
  );
};

export default MassageBenefits;
