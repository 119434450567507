import React from "react";
import CoverListGrid from "../../../components/grids/coverListsGrid.component.tsx";
import { useIntl } from "react-intl";
import MainLayout from "../../MainLayout.tsx";
import colors from "../../../constants/colors.ts";
import { testimonials } from "../../../constants/homepage.constants.ts";
import Testimonials from "../../../components/slideshow/testimonials.component.tsx";
import { coverListGridItems } from "../../../constants/lists.constants.ts";

const Section9: React.FC = () => {
  const intl = useIntl();

  return (
    <MainLayout backgroundColor={colors.BLUE_F0FAFF}>
      {coverListGridItems?.map((item, index) => (
        <CoverListGrid
          key={index}
          title={intl.formatMessage({ id: `${item?.title}` })}
          cover={item?.cover}
          list={item?.items}
        />
      ))}
    </MainLayout>
  );
};

export default Section9;
