import React from "react";
import Box from "@mui/material/Box";
import MainLayout from "../../MainLayout.tsx";
import useStyles from "../../../hooks/styles.hook.tsx";
import Timeline from "../../../components/timeline/timeline.component.tsx";
import { wellingtonTimeline } from "../../../constants/timeline.constants.ts";
import TextPanel from "../../../components/text/TextPanel.component.tsx";

const Section8: React.FC = () => {
  const {textBoxPaddingStyles } = useStyles();
  return (
    <MainLayout isFooterDisplayed={true}>
      <Box display={"flex"} justifyContent={"center"} sx={textBoxPaddingStyles}>
        <Box>
          <TextPanel
            title={"TITLE.ParcursIstoric"}
            text={"TEXT.ParcursulIstoric"}
          />

          <Timeline events={wellingtonTimeline} />
        </Box>
      </Box>
    </MainLayout>
  );
};

export default Section8;
