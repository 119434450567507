import React from "react";
import MainLayout from "../../MainLayout.tsx";
import Box from "@mui/material/Box";
import LinkGrid from "../../../components/grids/linkGrid.component.tsx";
import useStyles from "../../../hooks/styles.hook.tsx";
import colors from "../../../constants/colors.ts";
import { ColorsType } from "../../../types/colors.types.ts";
import TextBox from "../../../components/text/textBox.component.tsx";
import { textTypes } from "../../../constants/app.constants.ts";
import { mainEffectsGridItems } from "../../../constants/grids.contants.ts";

const Section9: React.FC = () => {
  const { verticalPaddingBoxStyles } = useStyles();

  return (
    <MainLayout>
      <Box sx={verticalPaddingBoxStyles} textAlign={"left"}>
        <TextBox
          variant={textTypes.TITLE}
          text={"TITLE.EfectelePrincipaleAsupraAngajatilor"}
        />
        <LinkGrid
          columnsNumber={4}
          items={mainEffectsGridItems}
          itemBackgroundColour={colors.CYAN_E6FFFF}
          iconColour={ColorsType.CYAN}
        />
      </Box>
    </MainLayout>
  );
};

export default Section9;
