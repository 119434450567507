import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";

import colors from "../../constants/colors.ts";
import NewsletterInput from "../footer/newsletterInput.component.tsx";
import {
  imagesFoldersTypes,
} from "../../constants/app.constants.ts";
import Image from "../media/Image.component.tsx";
import TextPanel from "../text/TextPanel.component.tsx";
import MainLayout from "../../modules/MainLayout.tsx";

const Newsletter: React.FC = () => {
  return (
    <MainLayout backgroundColor={colors.GREY_707280} marginBottom={0}>
      <Grid
        container
        spacing={4} // {{ sm: 0, md: 8 }}
      >
        {/* IMAGE */}
        <Grid
          container
          size={{ xs: 12, md: 6, lg: 6 }}
          display="flex"
          // alignItems="center"
          justifyContent={{ xs: "center", md: "flex-start" }}
        >
          <Image
            name={"Newsletter-Logo"}
            variant={imagesFoldersTypes.IMAGES}
            sx={{width: '90%'}}
          />
        </Grid>

        <Grid
          container
          alignItems="center"
          justifyContent={"center"}
          size={{ xs: 12, md: 6 }}
        >
          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"flex-start"}
          >
            <TextPanel
              captionColor={colors.WHITE}
              caption={"NEWSLETTER"}
              titleColor={colors.WHITE}
              title={"TITLE.Newsletter"}
            />

            <NewsletterInput />
          </Box>
        </Grid>
      </Grid>
    </MainLayout>
  );
};

export default Newsletter;
