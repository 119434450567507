import React from "react";
import HomepageSecondaryLayout from "../../../components/homepages/homepageSecondaryLayout.tsx";
import colors from "../../../constants/colors.ts";
import Section2 from "./Section2.events.tsx";

const Section1: React.FC = () => {
  return (
    <HomepageSecondaryLayout
      backgroundColor={colors.BLUE_DDEFFF}
      title={"TITLE.Events"}
      button={"BUTTON.CereOfertaPersonalizata"}
      media={'Secondary-Homepage-Image-Layout'}
    >
      <Section2 />
    </HomepageSecondaryLayout>
  );
};

export default Section1;
