import React from "react";
import Box from "@mui/material/Box";
import { useIntl } from "react-intl";

import { useFormatText } from "../../i18n/Metronici18n.tsx";
import { TextProps } from "../../interfaces/text.interface.ts";
import { textTypes, TextTypes } from "../../constants/app.constants.ts";

const TextBox: React.FC<
  TextProps & React.PropsWithChildren<{ variant?: TextTypes }>
> = ({
  variant = textTypes.TEXT,
  fontFamily = "Inter Regular",
  fontSize = 16,
  letterSpacing = 0.05,
  color,
  textAlign,
  textShadow,
  cursor,
  fontWeight = 400,
  text,
  children,
  textTransform = "none",
  textDecoration,
  onClick,
  needsFormatting = false,
  marginBottom = 2,
  ref,
  sx,
}) => {
  const intl = useIntl();
  const formatText = useFormatText();

  // Format and translate the text
  const handleTextFormatting = (text: string | string[]): React.ReactNode => {
    if (Array.isArray(text)) {
      return text.map((sentence, index) => (
        <React.Fragment key={`sentence-${index}`}>
          {needsFormatting ? 
          formatText(intl.formatMessage({ id: sentence }))
          : intl.formatMessage({ id: sentence })
        }
          {index !== text.length - 1 && " "}
        </React.Fragment>
      ));
    }
    return needsFormatting ? formatText(intl.formatMessage({ id: text })) : intl.formatMessage({ id: text })
  };

  return (
    <Box
      className={''}
      onClick={onClick}
      ref={ref}
      textTransform={textTransform ? textTransform : "none"}
      fontSize={fontSize}
      textAlign={textAlign}
      sx={{
        textDecoration: textDecoration,
        color: color,
        letterSpacing: `${letterSpacing}px`,
        fontWeight: fontWeight,
        fontFamily: fontFamily,
        textShadow: textShadow,
        cursor: cursor,
        marginBottom: marginBottom,
        ...sx,
      }}
    >
      {" "}
      {text && handleTextFormatting(text)}
      {children}
    </Box>
  );
};

export default TextBox;
