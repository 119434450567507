import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Carousel, { ArrowProps } from "react-multi-carousel";
import { TextImageSliderItemType } from "../../types/items.types.ts";
import Card from "../media/card.component.tsx";
import "./slideshow.css"
import { CustomArrow } from "./customSliderArrows.component.tsx";
import { ColorsType } from "../../types/colors.types.ts";

interface SliderCardsProps {
  items: TextImageSliderItemType[];
  numberOfVisibleItems?: number;
}

const SliderCards: React.FC<SliderCardsProps> = ({
  items,
  numberOfVisibleItems = 3,
}) => {

  const responsive = {
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
      partialVisibilityGutter: 0,
    },
    tablet: {
      breakpoint: { max: 900, min: 601 },
      items: 2,
      partialVisibilityGutter: 0,
    },
    desktop: {
      breakpoint: { max: 5000, min: 900 },
      items: numberOfVisibleItems,
      partialVisibilityGutter: 0,
    },
  };

  return (
    <Box>
      <Carousel
        ssr={true}
        draggable={false}
        swipeable={false}
        showDots={false}
        responsive={responsive}
        partialVisible={false}
        focusOnSelect={false}
        rewindWithAnimation={true}
        itemClass="carousel-item-class"
        sliderClass="carousel-slider-class"
        containerClass="carousel-container-class"
        customRightArrow={<CustomArrow side="right" color={ColorsType.GREY} />}
        customLeftArrow={<CustomArrow side="left" color={ColorsType.GREY} />}
        infinite={true}
      >
        {items?.map((item: TextImageSliderItemType, index) => (
          <Card
            key={index}
            image={item?.image}
            title={item?.title}
            description={item?.description}
            backgroundColor="transparent"
            border="none"
            buttonText="Află mai multe"
            endIcon="Right-Arrow-Blue"
          />
        ))}
      </Carousel>
    </Box>
  );
};

export default SliderCards;
