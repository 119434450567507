import React from "react";
import MainLayout from "../../MainLayout.tsx";
import TextImageGrid from "../../../components/grids/textImageGrid.component.tsx";
import colors from "../../../constants/colors.ts";
import { imagesFoldersTypes } from "../../../constants/app.constants.ts";

const Section7: React.FC = () => {
  return (
    <MainLayout>
      <TextImageGrid
        title={"TITLE.BeneficiiPentruCompanii"}
        text={"TEXT.BeneficiiPentruCompanii"}
        image={'Chair-Massage'}
        imageVariant={imagesFoldersTypes.IMAGES}
        backgroundColor={colors.BLUE_B4DBFF}
        heightLg={89}
      />
    </MainLayout>
  );
};

export default Section7;
