import React from "react";
import MainLayout from "../../MainLayout.tsx";
import TextImageGrid from "../../../components/grids/textImageGrid.component.tsx";
import colors from "../../../constants/colors.ts";
import TextBox from "../../../components/text/textBox.component.tsx";

const Section2: React.FC = () => {
  return (
    <MainLayout>
      <TextImageGrid
        title={"TITLE.Pionierul&Lider"}
        titleColor={colors.BLUE_0067F6}
        childrenReplacingImage={true}
        alignImage={"center"}
      >
        <TextBox text={"TEXT.Pionierul&Lider"} needsFormatting={true}/>
      </TextImageGrid>
    </MainLayout>
  );
};

export default Section2;
