import React from "react";
import Slideshow from "../slideshow/slideshow.component.tsx";
import useStyles from "../../hooks/styles.hook.tsx";
import VideoPlayer from "../media/videoPlayer.component.tsx";
import Image from "../media/Image.component.tsx";
import {
  imagesFoldersTypes,
  ImagesFoldersTypes,
} from "../../constants/app.constants.ts";

interface MediaDisplayProps {
  media: any;
  justifyContent?: string,
  variant?: ImagesFoldersTypes;
  areBubblesDisplayed?: boolean;
  sx?: object;
}

const MediaDisplay: React.FC<MediaDisplayProps> = ({
  media,
  areBubblesDisplayed = false,
  justifyContent = "flex-end",
  variant = imagesFoldersTypes.TEST_IMAGES,
  sx,
}) => {
  if (Array.isArray(media)) {
    return (
      <Slideshow
        items={media}
        variant={variant}
        areBubblesDisplayed={areBubblesDisplayed}
        justifyContent={justifyContent}
      />
    );
  } else if (React.isValidElement(media)) {
    return media;
  } else if (
    typeof media === "string" &&
    variant === imagesFoldersTypes.VIDEOS
  ) {
    return <VideoPlayer videoPath={media} variant={variant} sx={sx}/>;
  } else if (typeof media === "string") {
    return <Image variant={variant} name={media} sx={sx} />;
  } else {
    console.log(media);
    return null;
  }
};

export default MediaDisplay;
