import React from "react";

import colors from "../../../constants/colors.ts";
import TextImageGrid from "../../../components/grids/textImageGrid.component.tsx";
import MainLayout from "../../MainLayout.tsx";

const AboutTherapies: React.FC = () => {
  return (
    <MainLayout marginBottom={56}>
      <TextImageGrid
        title={"TITLE.DespreTerapii"}
        text={
          "Terapiile dedicate contribuie semificativ la îmbunătățirea calității vieții la locul de muncă și la prevenirea problemelor de sănătate pe termen lung."
        }
        buttonLabel={"BUTTON.CereOferta"}
        image={'Therapy-Homepage'}
        titleColor={colors.WHITE}
        textColor={colors.WHITE}
        backgroundImage={`linear-gradient(to right, ${colors.CYAN_12CFE9}, ${colors.CYAN_00FFFF})`}
        imageSx={{width: '76%'}}
      />
    </MainLayout>
  );
};

export default AboutTherapies;
