import React from "react";
import Section1 from "./sections/Section1.about-us.tsx";
import Section2 from "./sections/Section2.about-us.tsx";
import Section3 from "./sections/Section3.about-us.tsx";
import Section4 from "./sections/Section4.about-us.tsx";
import Section6 from "./sections/Section6.about-us.tsx";
import Section7 from "./sections/Section7.about-us.tsx";
import Section8 from "./sections/Section8.about-us.tsx";
import Section5 from "./sections/Section5.about-us.tsx";

const AboutUsHomepage: React.FC = () => {
  return (
    <>
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
      <Section6 />
      <Section7 />
      <Section8 />
    </>
  );
};

export default AboutUsHomepage;
