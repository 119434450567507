import React from "react";
import MainLayout from "../../MainLayout.tsx";
import colors from "../../../constants/colors.ts";
import Box from "@mui/material/Box";
import useStyles from "../../../hooks/styles.hook.tsx";
import Grid from "@mui/material/Grid2";
import TextBox from "../../../components/text/textBox.component.tsx";
import TextPanel from "../../../components/text/TextPanel.component.tsx";
import { textTypes } from "../../../constants/app.constants.ts";

const Section7: React.FC = () => {
  const {
    smallPaddingStyles,
    marginBottomStyles,
    verticalPaddingBoxStyles,
  } = useStyles();

  return (
    <MainLayout backgroundColor={colors.WHITE_F4F4F6} isFooterDisplayed={true}>
      <Box sx={verticalPaddingBoxStyles}>
        <TextPanel
          caption={"TITLE.Altele"}
          title={"TITLE.TeMaiPoateInteresa"}
        />

        <Grid container spacing={3} sx={marginBottomStyles}>
          <Grid
            display={"flex"}
            alignItems={"center"}
            size={{ md: 6, xs: 12 }}
            sx={{
              backgroundColor: colors.BLUE_0067F6,
              borderRadius: "20px",
            }}
          >
            <TextPanel
              subtitle={"Healthy Back Program"}
              subtitleColor={colors.WHITE}
              text="Lorem ipsum dolor sit amet, consectetnxvbxckb ,vx nx.nv,cvmn v, hjhhur adipiscing elit"
              textColor={colors.WHITE}
              button="BUTTON.CitesteMaiMult"
              buttonBackgroundColor="none"
              buttonColor={colors.WHITE}
              buttonBorderColor={colors.WHITE}
              style={smallPaddingStyles}
            />
          </Grid>

          <Grid
            display={"flex"}
            alignItems={"center"}
            size={{ md: 3, xs: 6 }}
            sx={{
              backgroundColor: colors.BLUE_0147A7,
              borderRadius: "20px",
            }}
          >
            <TextPanel
              subtitle={"TITLE.MotivelePentruCareClientiiNeAleg"}
              subtitleColor={colors.WHITE}
              text="Lorem ipsum dolor sit amet, consect tnxvbxckb ,vx nx.nv,cvmn v, hjhhur adipiscing elit"
              textColor={colors.WHITE}
              style={smallPaddingStyles}
            />
          </Grid>

          <Grid container display={"flex"} size={{ md: 3, xs: 6 }} spacing={4}>
            <Grid
              width={"100%"}
              justifyContent={"center"}
              display={"flex"}
              alignItems={"center"}
              sx={{
                backgroundColor: colors.BLUE_0067F6,
                borderRadius: "20px",
              }}
            >
              <Box textAlign={"center"} sx={smallPaddingStyles}>
                <TextBox
                  variant={textTypes.SUBTITLE}
                  color={colors.WHITE}
                  text={"TITLE.PrezentaNationala"}
                  marginBottom={0}
                />
              </Box>
            </Grid>

            <Grid
              width={"100%"}
              justifyContent={"center"}
              display={"flex"}
              alignItems={"center"}
              sx={{
                backgroundColor: colors.GREEN_00FFCF,
                borderRadius: "20px",
              }}
            >
              <Box textAlign={"center"} sx={smallPaddingStyles}>
                <TextBox
                  variant={textTypes.SUBTITLE}
                  text={"TITLE.EchipaDeTerapeuti"}
                  marginBottom={0}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>

        <Box
          display={"flex"}
          alignItems={"center"}
          width={"100%"}
          borderRadius={"20px"}
          height={150}
          justifyContent={"center"}
          sx={{ backgroundColor: colors.TURQUOISE_01FFFF }}
        >
          <TextBox
            variant={textTypes.TITLE}
            text="Max Relax ...Demo / Cere Oferta"
            marginBottom={0}
          />
        </Box>
      </Box>
    </MainLayout>
  );
};

export default Section7;
