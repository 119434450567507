import React from "react";
import { BenefitsGridProps } from "../../interfaces/grid.interface";
import Grid from "@mui/material/Grid2";
import { Box } from "@mui/material";
import TextBox from "../text/textBox.component.tsx";
import { imagesFoldersTypes, textTypes } from "../../constants/app.constants.ts";
import TextPanel from "../../components/text/TextPanel.component.tsx";
import Image from "../media/Image.component.tsx";

const BenefitsGrid: React.FC<BenefitsGridProps> = ({
  containers,
  backgroundColor,
  color,
}) => {

  return (
    <Box>
      <Grid container spacing={4} display={"flex"} justifyContent={"center"}>
        {containers?.map((section: any, sIndex) => (
          <Grid
            key={sIndex}
            display={{md: "flex", sm: 'column'}}
            justifyContent={"center"}
            alignItems={"center"}
            padding={2}
            sx={{
              backgroundColor: backgroundColor,
              borderRadius: "20px",
              width: "100%",
            }}
          >
            <Grid
              size={{ lg: 4, md: 4, xs: 12 }}
              display={"flex"}
              justifyContent={"flex-start"}
              padding={'0 48px'}
            >
              <TextPanel
                icon={section?.icon}
                iconExtension={section?.variant}
                variant={imagesFoldersTypes.ICONS}
                caption={section?.caption}
                title={section?.title}
                titleFontSize={32}
              />
            </Grid>

            {/* Checklist Grid */}
            {section?.data && (
              <>
                <Grid
                  container
                  spacing={2}
                  size={{ lg: 8, md: 8, xs: 12 }}
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  padding={'24px 0'}
                >
                  {/* 1st Column */}
                  <Grid size={{ lg: 6, md: 12, xs: 12 }}>
                    {section?.data
                      .slice(0, Math.ceil(section?.data?.length / 2))
                      .map((benefit, index) => (
                        <Box
                          gap={1}
                          key={index}
                          display={"flex"}
                          alignItems={"flex-start"}
                          sx={{ color: color }}
                        >
                          <Image name={'Rounded-Check'} variant={imagesFoldersTypes.ICONS} />
                          <TextBox text={benefit} />
                        </Box>
                      ))}
                  </Grid>

                  {/* 2nd Column */}
                  <Grid size={{ lg: 6, md: 12, xs: 12 }}>
                    {section?.data
                      .slice(Math.ceil(section?.data?.length / 2))
                      .map((benefit, index) => (
                        <Box
                          gap={1}
                          key={index}
                          display={"flex"}
                          alignItems={"flex-start"}
                          sx={{ color: color }}
                        >
                          <Image name={'Rounded-Check'} variant={imagesFoldersTypes.ICONS}/>
                          <TextBox text={benefit} />
                        </Box>
                      ))}
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default BenefitsGrid;
