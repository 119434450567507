import { ExtendedTestimonialType } from "../interfaces/testimonials.interface.ts";
import {
  CoverListGridTextType,
} from "../types/text.types.ts";
import { mediaExtensionTypes } from "./app.constants.ts";
import colors from "./colors.ts";

export const benefitsRow = [
  // CONTAINER 1
  {
    id: 1,
    icon: 'Group-of-People',
    caption: "TITLE.BeneficiileMasajului",
    title: "TITLE.PentruAngajat",
    data: [
      "TEXT.CombateOboseala",
      "TEXT.ScadeNivelulDeStres",
      "TEXT.InlaturaDurerile&ContracturiMusculare",
      "TEXT.CresteGradVigilenta&PutereConcentrare",
      "TEXT.IntaresteSistemulImunitar",
      "TEXT.Tonificare&Energizare",
      "TEXT.ImbunatatestePostura",
      "TEXT.ReduceNivelulDeAnxietate",
      "TEXT.ElibereazaEndorfine&StareDeBine",
      "TEXT.ReduceNiveldeAnxietateStresTensiune",
    ],
  },

  // CONTAINER 2
  {
    id: 2,
    icon: 'Building',
    caption: "TITLE.BeneficiileMasajului",
    title: "TITLE.PentruCompanie",
    data: [
      "TITLE.ProductivitateCrescuta",
      "TITLE.ROI",
      "TITLE.InstrumentCheieInProcesulDeRetentie",
      "TITLE.FactorDeDiferentiereInRecrutare",
      "TITLE.CulturaSanatatiiSiWellness",
      "TITLE.PromovareaSanatatiiPreventive",
      "TITLE.GestionareMonitorizareSiRaportareFacila",
      "TITLE.ScadereaAbsenteismului",
      "TITLE.PrevenireaBurnoutului",
      "TITLE.ComponentaAStrategieiDeAtragere",
      "TITLE.EmployerBranding",
    ],
  },
];

export const therapiesRow = [
  // CONTAINER 1
  {
    icon: 'Group-of-People-Cyan',
    variant: mediaExtensionTypes.PNG,
    caption: "TITLE.BeneficiileMasajului",
    title: "TITLE.PentruAngajat",
    data: [
      "TEXT.MinimizareaDurerilor",
      "TEXT.MentinereaUneiPosturiCorecte",
      "TEXT.CrestereaNiveluluiDeEnergie",
      "TEXT.CresteGradVigilenta&PutereConcentrare",
      "TEXT.ReducereaStres&AccidenteVasculare",
      "TEXT.Tonifierea&ActivareaMusculaturii",
      "TEXT.Revigorare&CrestereaEnergiei",
      "TEXT.PrevenireaAfectiunilorMuscoscheletice",
      "TEXT.ImportantaRespiratieiConstiente",
      "TEXT.ConstientizareaEfectelorSedentarismului",
      "TEXT.Identificarea&AmeliorareaAfectiunilorProfesionale",
    ],
  },

  // CONTAINER 2
  {
    icon: 'Building-Cyan',
    caption: "TITLE.BeneficiileMasajului",
    title: "TITLE.PentruCompanie",
    data: [
      "TITLE.ProductivitateCrescuta",
      "TITLE.ROI",
      "TITLE.InstrumentCheieInProcesulDeRetentie",
      "TITLE.FactorDeDiferentiereInRecrutare",
      "TITLE.CulturaSanatatiiSiWellness",
      "TITLE.PromovareaSanatatiiPreventive",
      "TITLE.GestionareMonitorizareSiRaportareFacila",
      "TITLE.ScadereaAbsenteismului",
      "TITLE.PrevenireaBurnoutului",
      "TITLE.ComponentaAStrategieiDeAtragere",
      "TITLE.EmployerBranding",
    ],
  },
];


export const onSiteMassageAccordion: CoverListGridTextType[] = [
  {
    title: "TITLE.Frictiunea",
    text: "TEXT.Frictiunea",
  },
  {
    title: "TITLE.Framantatul",
    text: "TEXT.Framantatul",
  },
  {
    title: "TITLE.PresiunePunctata",
    text: "TEXT.PresiunePunctata",
  },
  {
    title: "TITLE.Tractiunea",
    text: "TEXT.Tractiunea",
  },
  {
    title: "TITLE.Tapotamentul",
    text: "TEXT.Tapotamentul",
  },
];

export const HealthyBackAccordion: CoverListGridTextType[] = [
  {
    title: "TITLE.ExercitiiDeCrestere",
    text: "TEXT.ExercitiiDeCrestere",
  },
  {
    title: "TITLE.Stretching",
    text: "TEXT.Stretching",
  },
  {
    title: "TITLE.TehniciDeMobilizareArticulara",
    text: "TEXT.TehniciDeMobilizareArticulara",
  },
  {
    title: "TITLE.TehniciDeElongantieSiTractiune",
    text: "TEXT.TehniciDeElongantieSiTractiune",
  },
  {
    title: "TITLE.EducatiePosturala",
    text: "TEXT.EducatiePosturala",
  },
];

export const healthyBackBenefits: string[] = [
  "TEXT.AmeliorareaDurerilorPrinDetensionareMusculara",
  "TEXT.CorectareaPosturii",
  "TEXT.ImbunatatireaTonusului",
  "TEXT.CrestereaFlexibilitatii",
  "TEXT.CrestereaNiveluluiDeEnergie",
  "TEXT.Prevenirea&TratareaAfectiunilorColoanei",
];
