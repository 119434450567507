import React from "react";
import Section1 from "./sections/Section1.max-active.tsx";
import Section2 from "./sections/Section2.max-active.tsx";
import Section3 from "./sections/Section3.max-active.tsx";
import Section4 from "./sections/Section4.max-active.tsx";
import Section5 from "./sections/Section5.max-active.tsx";
import Section6 from "./sections/Section6.max-active.tsx";
import Section7 from "./sections/Section7.max-active.tsx";
import Section8 from "./sections/Section8.max-active.tsx";
import Section9 from "./sections/Section9.max-active.tsx";
import Section10 from "./sections/Section10.max-active.tsx";
import Section11 from "./sections/Section11.max-active.tsx";

const MaxActiveHomepage: React.FC = () => {
  return (
    <>
      <Section1 />
      {/* <Section2 /> is included in section1*/}
      <Section3 />
      <Section4 />
      <Section5 />
      <Section6 />
      <Section7 />
      <Section8 />
      <Section9 />
      <Section10 />
      <Section11 />
    </>
  );
};

export default MaxActiveHomepage;
