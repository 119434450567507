import React from "react";
import TextImageGrid from "../../../components/grids/textImageGrid.component.tsx";
import colors from "../../../constants/colors.ts";
import Grid from "@mui/material/Grid2";
import TextBox from "../../../components/text/textBox.component.tsx";
import Box from "@mui/material/Box";
import useStyles from "../../../hooks/styles.hook.tsx";
import { useIntl } from "react-intl";
import { useFormatText } from "../../../i18n/Metronici18n.tsx";
import MainLayout from "../../MainLayout.tsx";
import Button from "../../../components/button/button.component.tsx";
import CoverListsGrid from "../../../components/grids/coverListsGrid.component.tsx";
import { maxRelaxAppFeatures, maxRelaxAppGridItems } from "../../../constants/lists.constants.ts";

const Section6: React.FC = () => {
  const intl = useIntl();
  const formatText = useFormatText();

  const {
    listItemsBoxStyles,
    textBoxVerticalPaddingStyles,
    smallMarginBottomStyles,
    smallPaddingStyles,
    verticalPaddingBoxStyles
  } = useStyles();

  const imageArray = ['Well-App-Phone', 'Well-App-Phone'];

  return (
    <MainLayout backgroundColor={colors.BLUE_F0FAFF}>
      <CoverListsGrid
        title={"TITLE.BeneficiiMaxRelaxApp"}
        list={maxRelaxAppGridItems}
      />
      
      <TextImageGrid
        backgroundColor={colors.BLUE_DDEFFF}
        title={"TITLE.CaracteristiciMaxRelaxApp"}
        isTextLeftAligned={false}
        image={imageArray}
      >
        <Grid container sx={textBoxVerticalPaddingStyles}>
          {maxRelaxAppFeatures?.map((item, index) => (
            <Grid
              key={index}
              size={{ md: 6, xs: 12 }}
              sx={smallMarginBottomStyles}
            >
              <Box sx={listItemsBoxStyles}>
                <TextBox
                  fontWeight={600}
                  color={colors.BLUE_0067F6}
                  text={item?.title}
                />
                <TextBox>
                  {formatText(intl.formatMessage({ id: `${item?.text}` }))}
                </TextBox>
              </Box>
            </Grid>
          ))}
        </Grid>
      </TextImageGrid>

      {/* Social Buttons */}
      <Grid
        container
        display={"flex"}
        spacing={4}
        justifyContent={"center"}
        sx={verticalPaddingBoxStyles}
      >
        <Grid>
          <Button
            startIcon={'App-Store-Icon'}
            text={"App Store"}
            backgroundColor={colors.BLACK}
            color={colors.WHITE}
            sx={smallPaddingStyles}
          />
        </Grid>

        <Grid>
          <Button
            startIcon={'Google-Play-Icon'}
            text={"Google Play"}
            backgroundColor={colors.BLACK}
            color={colors.WHITE}
            sx={smallPaddingStyles}
          />
        </Grid>

        <Grid>
          <Button
            text={"BUTTON.ObtineCodQR"}
            backgroundColor={colors.BLUE_0067F6}
            color={colors.WHITE}
            sx={smallPaddingStyles}
          />
        </Grid>
      </Grid>
    </MainLayout>
  );
};

export default Section6;
