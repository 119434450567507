import React from "react";
import TextImageGrid from "../../../components/grids/textImageGrid.component.tsx";
import colors from "../../../constants/colors.ts";
import Box from "@mui/material/Box";

const Section2: React.FC = () => {
  return (
    <Box sx={{ maxWidth: "40%" }}>
      <TextImageGrid title={"TEXT.Events"} titleColor={colors.BLUE_0067F6} />
    </Box>
  );
};

export default Section2;
