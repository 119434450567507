import React from "react";
import Box from "@mui/material/Box";
import MainLayout from "../../MainLayout.tsx";
import useStyles from "../../../hooks/styles.hook.tsx";
import colors from "../../../constants/colors.ts";
import CardsGrid from "../../../components/grids/cardsGrid.component.tsx";
import Button from "../../../components/button/button.component.tsx";
import TextBox from "../../../components/text/textBox.component.tsx";
import { imagesFoldersTypes, textTypes } from "../../../constants/app.constants.ts";
import { maxRelaxServices } from "../../../constants/cards.constants.ts";
import Image from "../../../components/media/Image.component.tsx";

const Section5: React.FC = () => {
  const { verticalPaddingBoxStyles, textMarginBottomStyles } = useStyles();

  return (
    <MainLayout>
      <Box>
        <Image name={'Max-Relax'} variant={imagesFoldersTypes.LOGOS} />
      </Box>

      <TextBox
        variant={textTypes.SUBTITLE}
        color={colors.BLUE_0067F6}
        text={"TITLE.ServiciiRecomandateMaxRelax"}
        sx={verticalPaddingBoxStyles}
      />

      <CardsGrid
        cards={maxRelaxServices}
        backgroundColor={colors.WHITE}
        sx={textMarginBottomStyles}
      />

      <Button
        border={`1px solid ${colors.BLUE_0067F6}`}
        text={"BUTTON.DescoperaServiciileMaxRelax"}
        endIcon={'Right-Arrow-Blue'}
      />
    </MainLayout>
  );
};

export default Section5;
