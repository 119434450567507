import React from "react";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import { Box, Input } from "@mui/material";

import NEWSLETTER_EMAIL_INPUT from "../../themes/newsletter_email_input.themes.ts";
import useStyles from "../../hooks/styles.hook.tsx";
import Button from "../button/button.component.tsx";
import colors from "../../constants/colors.ts";
import { useIntl } from "react-intl";

const NewsletterInput: React.FC = () => {
  const { inputTextStyles } = useStyles();
  const intl = useIntl()

  return (
    <Box display={"flex"} alignItems={"center"} justifyContent={{sm: "center", md: 'flex-start'}}>
      <ThemeProvider theme={NEWSLETTER_EMAIL_INPUT}>
        <Input
          disableUnderline={true}
          placeholder={`${intl.formatMessage({ id: "PLACEHOLDER.EmailulTauAici"})}`}
          multiline={false}
          sx={inputTextStyles}
        />
      </ThemeProvider>

      <Button 
        backgroundColor={colors.WHITE} 
        color={colors.BLUE_242737}
        text={"BUTTON.SignUp"}
      />
    </Box>
  );
};

export default NewsletterInput;
