import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";

import useStyles from "../../hooks/styles.hook.tsx";
import TextBox from "../text/textBox.component.tsx";
import Button from "../button/button.component.tsx";
import { TextImageGridProps } from "../../interfaces/grid.interface.ts";
import MediaDisplay from "../homepages/mediaDisplay.component.tsx";
import colors from "../../constants/colors.ts";
import {
  imagesFoldersTypes,
  textTypes,
} from "../../constants/app.constants.ts";
import TextPanel from "../text/TextPanel.component.tsx";

function TextImageGrid({
  header,
  headerColour = colors.BLUE_242737,
  description,
  descriptionColour = colors.BLUE_242737,
  caption,
  title,
  text,
  subtitle,
  subtitleColour = colors.BLUE_242737,
  buttonLabel,
  buttonEndIcon,
  buttonBorder,
  image,
  imageSx,
  imageVariant = imagesFoldersTypes.PAGE_IMAGES,
  isTextLeftAligned = true,
  titleColor = colors.BLUE_242737,
  textColor = colors.BLUE_242737,
  textBackgroundColor,
  buttonTextColor,
  buttonBgColor,
  heightLg = 82,
  backgroundImage, // especially for gradient
  backgroundColor,
  hasVerticalPadding = false,
  alignImage = "flex-start",
  lg = 6,
  md = 6,
  icon,
  iconVariant = imagesFoldersTypes.ICONS,
  sx,
  spacing = 3,
  childrenReplacingImage = false,
  children,
}: TextImageGridProps) {
  const {
    textBoxPaddingStyles,
    verticalPaddingBoxStyles,
    textMarginBottomStyles,
    textBoxVerticalPaddingStyles,
  } = useStyles();

  return (
    <>
      <Box>
        <TextPanel
          title={header}
          titleColor={headerColour}
          text={description}
          textColor={descriptionColour}
        />

        <Box
          sx={{
            position: "relative",
            ...sx,
          }}
        >
          {/* Background box */}
          <Box
            sx={{
              position: "absolute",
              top: { xs: 0, md: "50%" },
              left: { xs: 0, md: "50%" },
              transform: { xs: "none", md: "translate(-50%, -50%)" },
              width: "100%",
              height: {
                lg: hasVerticalPadding ? "100%" : `${heightLg}%`,
                xs: "100%",
              },
              borderRadius: "20px",
              backgroundColor: backgroundColor,
              backgroundImage: backgroundImage,
              //zIndex: 0,
              ...(hasVerticalPadding ? verticalPaddingBoxStyles : {}),
            }}
          />

          {/* Grid Container */}
          <Grid
            container
            display={"flex"}
            spacing={spacing}
            sx={{ position: "relative" }}
            direction={isTextLeftAligned ? "row" : "row-reverse"}
          >
            {/* Text container */}
            <Grid
              size={{ sm: 12, md: md, lg: lg }}
              display={"flex"}
              alignItems={"center"}
              padding={3}
              sx={{
                ...(textBackgroundColor && {
                  backgroundColor: colors.PINK_F4F4FB,
                  borderRadius: '20px',
                }),
              }}
            >
              <Box padding={'0 48px'}>
                <TextPanel
                  icon={icon}
                  variant={iconVariant}
                  caption={caption}
                  captionColor={textColor}
                  title={title}
                  titleColor={titleColor}
                  subtitle={subtitle}
                  subtitleColor={subtitleColour}
                  text={text}
                  textColor={textColor}
                />

                {childrenReplacingImage === false && (
                  <Box sx={textMarginBottomStyles}>{children}</Box>
                )}

                {/* Button */}
                {buttonLabel && (
                  <Button
                    color={buttonTextColor}
                    backgroundColor={buttonBgColor}
                    border={buttonBorder}
                    endIcon={buttonEndIcon ? buttonEndIcon : null}
                    text={buttonLabel}
                  />
                )}
              </Box>
            </Grid>

            {/* Picture grid */}
            <Grid
              container
              size={{ sm: 12, md: 12 - md, lg: 12 - lg }}
              alignItems={"center"}
              justifyContent={"center"}
            >
              {image && childrenReplacingImage === false ? (
                <MediaDisplay variant={imageVariant} media={image} sx={imageSx} justifyContent={"center"}/>
              ) : (
                <Box padding={3}>
                  {childrenReplacingImage === true && children}
                </Box>
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
}

export default TextImageGrid;
