import React from "react";
import { usePageContext } from "../../context/Page.context.tsx";
import LandingSection from "./sections/LandingSection.homepage.tsx";
import OurImpactWidgets from "./sections/OurImpactWidgets.homepage.tsx";
import CorporareMassage from "./sections/CorporateMassage.homepage.tsx";
import MassageBenefits from "./sections/MassageBenefits.tsx";
import MassageTypes from "./sections/MassageTypes.homepage.tsx";
import TherapiesBenefits from "./sections/TherapiesBenefits.homepage.tsx";
import TherapyTypes from "./sections/TherapyTypes.homepage.tsx";
import ClientsReviews from "./sections/ClientsReviews.homepage.tsx";
import MaxRelaxMobileApp from "./sections/MaxRelaxMobileApp.homepage.tsx";
import AboutTherapies from "./sections/AboutTherapies.homepage.tsx";
import MaxActivMobileApp from "./sections/MaxActivMobileApp.homepage.tsx";
import TopBrands from "./sections/TopBrands.homepage.tsx";
import NationalPresence from "./sections/NationalPresence.homepage.tsx";

const Homepage: React.FC = () => {
  const { topPageRef } = usePageContext();

  return (
    <>
      <LandingSection />
      <OurImpactWidgets />
      <CorporareMassage />
      <MassageBenefits />
      <MassageTypes />
      <MaxRelaxMobileApp />
      <AboutTherapies />
      <TherapiesBenefits />
      <TherapyTypes />
      {/* <MaxActivMobileApp /> */}
      <ClientsReviews />
      <TopBrands />
      <NationalPresence />
    </>
  );
};

export default Homepage;
