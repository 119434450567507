import {
  MAX_RELAX_CHAIR_MASSAGE_LOGO,
  NEW_ICON_PATTERN_BLUE,
  WELLINGTON_COLOURED_LOGO,
  WORKPLACE_METAMORPHOSIS_LOGO,
} from "../assets/index.ts";
import { TimelineEventsListType } from "../types/timeline.types.ts";

export const wellingtonTimeline: TimelineEventsListType[] = [
  {
    period: "2005 - 2006",
    events: [
      {
        icon: NEW_ICON_PATTERN_BLUE,
        description: [ {label: "TEXT.Description_Timestamp_1"} ],
      },
    ],
  },
  {
    period: "2006 - 2008",
    events: [
      {
        icon: MAX_RELAX_CHAIR_MASSAGE_LOGO,
        description: [{ label: "TEXT.Description_Timestamp_2" }],
      },
    ],
  },
  {
    period: "2009 - 2011",
    events: [
      {
        icon: NEW_ICON_PATTERN_BLUE,
        description: [{ label: "TEXT.Description_Timestamp_3" }],
      },
    ],
  },
  {
    period: "2012 - 2014",
    events: [
      {
        icon: NEW_ICON_PATTERN_BLUE,
        description: [{ label: "TEXT.Description_Timestamp_4" }],
      },
    ],
  },
  {
    period: "2015 - 2017",
    events: [
      {
        icon: WELLINGTON_COLOURED_LOGO,
        description: [{ label: "TEXT.Description_1_Timestamp_5" }],
      },
      { description: [{ label: "TEXT.Description_2_Timestamp_5" }] },
      { description: [{ label: "TEXT.Description_3_Timestamp_5" }] },
    ],
  },
  {
    period: "2018 - 2019",
    events: [
      {
        icon: NEW_ICON_PATTERN_BLUE,
        description: [{ label: "TEXT.Description_1_Timestamp_6" }],
      },
      {
        description: [{
          label: "TEXT.Description_2_Timestamp_6",
          items: [
            "TEXT.Description_2_Element_1_Timestamp_6",
            "TEXT.Description_2_Element_2_Timestamp_6",
          ],
        }],
      },
    ],
  },
  {
    period: "2020 - 2021",
    events: [
      {
        icon: NEW_ICON_PATTERN_BLUE,
        description: [{ label: "TEXT.Description_Timestamp_7" }],
      },
    ],
  },
  {
    period: "2022-2023",
    events: [
      {
        icon: NEW_ICON_PATTERN_BLUE,
        description: [{ label: "TEXT.Description_1_Timestamp_8" }],
      },
      { description: [{ label: "TEXT.Description_2_Timestamp_8" }] },
    ],
  },
  {
    period: 2024,
    events: [
      {
        icon: WORKPLACE_METAMORPHOSIS_LOGO,
        description: [{ label: "TEXT.Description_1_Timestamp_9" }],
      },
      {
        icon: WELLINGTON_COLOURED_LOGO,
        description: [{ label: "TEXT.Description_2_Timestamp_9" }],
      },
    ],
  },
  {
    period: "2025-2065",
    events: [
      {
        icon: NEW_ICON_PATTERN_BLUE,
        description: [{ label: "TEXT.Description_Timestamp_10" }],
      },
    ],
  },
];
