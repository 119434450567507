import React, { createContext, useContext, useState } from "react";
import {
  headerCategoriesTypes,
  HeaderCategoriesTypes,
} from "../constants/header.constants.ts";

type CategoryContextType = {
  categoryIndex: number;
  setCategoryIndex: (index: number) => void;
};

const CategoryContext = createContext<CategoryContextType | undefined>(undefined);

export const CategoryProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [categoryIndex, setCategoryIndex] = useState<number>(0);

  return (
    <CategoryContext.Provider value={{ categoryIndex, setCategoryIndex }}>
      {children}
    </CategoryContext.Provider>
  );
};

export const useCategory = (): CategoryContextType => {
  const context = useContext(CategoryContext);
  if (!context) {
    throw new Error("useCategory must be withing a provider");
  }

  return context;
};
