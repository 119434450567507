import { ListItemsProps } from "../interfaces/grid.interface.ts";
import { CoverListGridTextType, CoverListItemType, ListItemType } from "../types/text.types.ts";
import { imagesFoldersTypes } from "./app.constants.ts";

export const indoorEventsList: ListItemsProps[] = [
  {
    title: "TITLE.EvenimenteIndoor",
    subitems: [
      { text: "TITLE.Conferinte&Gale" },
      { text: "TITLE.Sarbatori&EvenimenteTematice" },
      { text: "TITLE.InaugurariDeSediu" },
      { text: "TITLE.Workshopuri&Seminarii" },
      { text: "TITLE.LansariDeProdus" },
    ],
  },
];

export const outdoorEventsList: ListItemsProps[] = [
  {
    title: "TITLE.EvenimenteOutdoor",
    subitems: [
      { text: "TITLE.EvenimenteSportive" },
      { text: "TITLE.Teambuilding" },
      { text: "TITLE.PicnicuriCorporate" },
      { text: "TITLE.Festivaluri&Targuri" },
      { text: "TITLE.EvenimenteCaritabile" },
    ],
  },
];

export const mondayRisksList: ListItemsProps[] = [
  {
    title: "TITLE.RiscuriAsociateSanatatii",
    subitems: [
      { text: "TITLE.InfarctMiocardic", icon: 'Filled-Circle-Black' },
      { text: "TITLE.HipertensiuneArteriala", icon: 'Filled-Circle-Black' },
      { text: "TITLE.Migrene", icon: 'Filled-Circle-Black' },
    ],
  },
  {
    title: "TITLE.NivelDeProductivitate",
    subitems: [{ text: "TITLE.Medie", icon: 'Down-Red-Arrow' }],
  },
  {
    title: "TITLE.NivelDeStres",
    subitems: [{ text: "TEXT.NivelDeStres", icon: 'Down-Red-Arrow' }],
  },
];

export const coverListGridItems: CoverListItemType[] = [
  // CONTAINER 1
  {
    title: "TITLE.SuccesulCompaniei",
    items: [
      {
        title: "TITLE.ProductivitateCrescuta",
        text: "TEXT.ProductivitateCrescuta",
      },
      {
        title: "TITLE.PrevenireaBurnoutului",
        text: "TEXT.PrevenireaBurnoutului",
      },
      {
        title: "TITLE.GestionareMonitorizareSiRaportareFacila",
        text: "TEXT.GestionareMonitorizareSiRaportareFacila",
      },
      {
        title: "TITLE.ScadereaAbsenteismului",
        text: "TEXT.ScadereaAbsenteismului",
      },
      { title: "TITLE.EmployerBranding", text: "TEXT.EmployerBranding" },
      {
        title: "TITLE.ComponentaAStrategieiDeAtragere",
        text: "TEXT.ComponentaAStrategieiDeAtragere",
      },
    ],
  },

  // CONTAINER 2
  {
    title: "TITLE.PromovareaSanatatii",
    cover: 'Cover',
    items: [
      { title: "TITLE.ROI", text: "TEXT.ROI" },
      {
        title: "TITLE.PromovareaSanatatiiPreventive",
        text: "TEXT.PromovareaSanatatiiPreventive",
      },
      {
        title: "TITLE.CulturaSanatatiiSiWellness",
        text: "TEXT.CulturaSanatatiiSiWellness",
      },
    ],
  },

  // CONTAINER 3
  {
    title: "TITLE.RetentieSiRecrutare",
    cover: 'Cover',
    items: [
      {
        title: "TITLE.FactorDeDiferentiereInRecrutare",
        text: "TEXT.FactorDeDiferentiereInRecrutare",
      },
      {
        title: "TITLE.InstrumentCheieInProcesulDeRetentie",
        text: "TEXT.InstrumentCheieInProcesulDeRetentie",
      },
    ],
  },
];

export const maxRelaxAppGridItems: CoverListGridTextType[] = [
  {
    title: "TITLE.SimplificareaProcesuluiDeProgramare",
    text: "TEXT.SimplificareaProcesuluiDeProgramare",
  },
  {
    title: "TITLE.AccesibilitatePentruAngajati",
    text: "TEXT.AccesibilitatePentruAngajati",
  },
  {
    title: "TITLE.FeedbackInTimpReal",
    text: "TEXT.FeedbackInTimpReal",
  },
  {
    title: "TITLE.PromovareaUnuiMediuDeLucruSanatos",
    text: "TEXT.PromovareaUnuiMediuDeLucruSanatos",
  },
  {
    title: "TITLE.MaximizareaProductivitatii",
    text: "TEXT.MaximizareaProductivitatii",
  },
  {
    title: "TITLE.ImbunatatireaBunastariiAngajatilor",
    text: "TEXT.ImbunatatireaBunastariiAngajatilor",
  },
];

export const maxRelaxAppFeatures: CoverListGridTextType[] = [
  {
    title: "TITLE.AccesRapidLaServicii",
    text: "TEXT.AccesRapidLaServicii",
  },
  {
    title: "TITLE.CompatibilitateCuCompanii",
    text: "TEXT.CompatibilitateCuCompanii",
  },
  {
    title: "TITLE.InterfataSimpla&Intuitiva",
    text: "TEXT.InterfataSimpla&Intuitiva",
  },
  {
    title: "TITLE.GestionareaEficientaAProgramarilor",
    text: "TEXT.GestionareaEficientaAProgramarilor",
  },
  {
    title: "TITLE.FunctionalitatiPersonalizate",
    text: "TEXT.FunctionalitatiPersonalizate",
  },
  {
    title: "TITLE.FormularDeContact",
    text: "TEXT.FormularDeContact",
  },
];

export const eventsIncludedServicesItemsGrid: ListItemsProps[] = [
  {
    title: "TITLE.Echipamente",
    subitems: [
      {
        icon: 'Massage-Chair-Icon-Black',
        iconVariant: imagesFoldersTypes.ICONS,
        text: "TITLE.ScauneDeMasajProfesionale",
      },
      {
        icon: 'Circle-Black',
        text: "TITLE.DezinfectantPentruMaini&Scaune",
      },
      {
        icon: 'Circle-Black',
        text: "TITLE.ServeteleSauProsoape",
      },
      {
        icon: 'Tools-Icon-Black',
        iconVariant: imagesFoldersTypes.ICONS,
        text: "TITLE.InstalareaEchipamentelor",
      },
      {
        icon: 'Transport-Icon-Black',
        iconVariant: imagesFoldersTypes.ICONS,
        text: "TITLE.TransportulEchipamentelor",
      },
    ],
  },
  {
    title: "TITLE.AmenajareaSpatiului",
    subitems: [
      {
        icon: 'Aromatherapy-Icon-Black',
        iconVariant: imagesFoldersTypes.ICONS,
        text: "TITLE.Aromaterapie",
      },
      {
        icon: 'Circle-Black',
        text: "TITLE.AranjamenteFlorale",
      },
      {
        icon: 'Circle-Black',
        text: "TITLE.StatueteBuddha",
      },
      {
        icon: 'Music-System-Icon-Black',
        iconVariant: imagesFoldersTypes.ICONS,
        text: "TITLE.SistemAudioCuMuzica",
      },
    ],
  },
  {
    title: "TITLE.SuportInformativPentruAngajati",
    subitems: [
      {
        icon: 'Circle-Black',
        text: "TITLE.AfisePliante",
      },
      {
        icon: 'Circle-Black',
        text: "TITLE.Informare",
      },
    ],
  },
  {
    title: "TITLE.PlatformaOnlineDeInscriere",
    subitems: [
      {
        icon: 'Schedule-Icon-Black',
        iconVariant: imagesFoldersTypes.ICONS,
        text: "TITLE.GestionareOnlineProgramari",
      },
      {
        icon: 'Mobile-Icon-Black',
        iconVariant: imagesFoldersTypes.ICONS,
        text: "TITLE.MementoSMS",
      },
      {
        icon: 'Circle-Black',
        text: "TITLE.FeedbackDePerformanta",
      },
      {
        icon: 'Status-Icon-Black',
        iconVariant: imagesFoldersTypes.ICONS,
        text: "TITLE.RapoarteProgramari",
      },
    ],
  },
];

export const misionVisionValuesList: ListItemType[] = [
  {
    icon: 'Rocket-Icon-Blue',
    iconVariant: imagesFoldersTypes.ICONS,
    title: "TITLE.Misiune",
    text: "TEXT.Misiune",
  },
  {
    icon: 'Lightbulb-Icon-Blue',
    iconVariant: imagesFoldersTypes.ICONS,
    title: "TITLE.Viziune",
    text: "TEXT.Viziune",
  },
  {
    icon: 'Diamond-Icon-Blue',
    iconVariant: imagesFoldersTypes.ICONS,
    title: "TITLE.Valori",
    text: [
      "TITLE.Crativitate",
      "TITLE.Adaptibilitate",
      "TITLE.Pasiune",
      "TITLE.PutereaEchipei",
      "TITLE.Responsabilitate",
    ],
  },
];

