enum colors {
  WHITE = "#FFFFFF",
  BLACK = "#000000",
  GREY_CECFDA = "#CECFDA",
  MAIN_BLUE = "#006BFF",
  CYAN_CCFFFF = "#CCFFFF",
  BLUE_DDEFFF = "#ddefff",
  BLUE_66A6FF = "#66a6ff",
  MAIN_BLUE_400 = "#4570fc",
  MAIN_BLUE_800 = "#01347C",
  BLUE_0059D4 = "#0059D4",
  BLUE_0147A7 = "#0147A7",
  BLUE_F1FAFF = "#f1faff",
  BLUE_242737 = "#242737",
  BLUE_0067F6 = "#0067F6",
  BLUE_B4DBFF = "#B4DBFF",
  BLUE_F0FAFF = "#F0FAFF",
  CYAN_12CFE9 = "#12CFE9",
  CYAN_00FFFF = "#00FFFF",
  CYAN_E6FFFF = "#E6FFFF",
  CYAN_00E0E0 = '#00E0E0',
  GREY_151A41 = "#151A41",
  GREY_A2A2B2 = "#A2A2B2",
  GREY_2F3347 = "#2F3347",
  BLUE_2F3347 = "#2F3347",
  GREY_575967 = "#575967",
  GREY_707280 = "#707280",
  GREY_CECEDB = '#CECEDB',
  GREY_808092 = "#808092",
  GREY_ECECF5 = "#ECECF5",
  GREY_F9F9FB = "#F9F9FB",
  RED_E20037 = "#E20037",
  GREEN_00FFCF = '#00FFCF',
  GREEN_00DE00 = '#00DE00',
  TURQUOISE_04EEC4 = "#04EEC4",
  TURQUOISE_ADFFFF = "#ADFFFF",
  TURQUOISE_81FFFF = "#81FFFF",
  TURQUOISE_01FFFF = "#01FFFF",
  GREY_E9E9EF = "#E9E9EF",
  WHITE_9294A4 = "#9294A4",
  WHITE_F4F4F6 = "#F4F4F6",
  PINK_F4F4FB = "#f4f4fb",
}

export default colors;
