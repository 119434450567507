import React from "react";
import MainLayout from "../../MainLayout.tsx";
import useStyles from "../../../hooks/styles.hook.tsx";
import colors from "../../../constants/colors.ts";
import Box from "@mui/material/Box";
import { testimonials } from "../../../constants/homepage.constants.ts";
import Button from "../../../components/button/button.component.tsx";
import TextPanel from "../../../components/text/TextPanel.component.tsx";

const Section7: React.FC = () => {
  const { textBoxPaddingStyles } = useStyles();

  return (
    <MainLayout
      backgroundColor={colors.MAIN_BLUE_800}
      sx={textBoxPaddingStyles}
    >
      {/* Title & Description */}
      <Box textAlign={"center"}>
        <TextPanel
          title={"TITLE.Testimonials"}
          titleColor={colors.WHITE}
          text={"TEXT.Testimonials"}
          textColor={colors.WHITE}
        />
      </Box>

      {/* Reviews Container */}
      {/* <Box sx={{ margin: "40px 0" }}>
        <Testimonials testimonials={testimonials} />
      </Box> */}

      {/* Button */}
      <Box display={"flex"} justifyContent={"center"}>
        <Button text={"BUTTON.Testimonials"} />
      </Box>
    </MainLayout>
  );
};

export default Section7;
