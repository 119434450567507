import React from "react";
import colors from "../../constants/colors.ts";
import Box from "@mui/material/Box";
import { coverListGridProps } from "../../interfaces/grid.interface.ts";
import Grid from "@mui/material/Grid2";
import { CoverListGridTextType } from "../../types/text.types.ts";
import TextBox from "../text/textBox.component.tsx";
import useStyles from "../../hooks/styles.hook.tsx";
import {
  imagesFoldersTypes,
  textTypes,
} from "../../constants/app.constants.ts";
import Image from "../media/Image.component.tsx";
import Testimonials from "../slideshow/testimonials.component.tsx";
import { testimonials } from "../../constants/slideshow.constants.ts";

function CoverListsGrid({
  title,
  titleColour = colors.BLUE_242737,
  list,
  listTitleColour = colors.BLUE_242737,
  listTextColour = colors.BLUE_242737,
  cover,
  componentCover,
  children,
}: coverListGridProps) {
  const { smallMarginBottomStyles, verticalPaddingBoxStyles } = useStyles();

  return (
    <Box
      justifyContent={"center"}
      sx={{ ...smallMarginBottomStyles, ...verticalPaddingBoxStyles }}
    >
      <TextBox
        variant={textTypes.TITLE}
        color={titleColour}
        sx={smallMarginBottomStyles}
        text={title}
      />

      <Box sx={smallMarginBottomStyles}>
        {cover ? (
          <Image name={cover} variant={imagesFoldersTypes.PATTERNS} />
        ) : (
          <Testimonials testimonials={testimonials} items={1} />
        )}
      </Box>

      <Grid container spacing={8}>
        {list?.map((item: CoverListGridTextType, index) => (
          <Grid
            key={index}
            size={{ xs: 12, md: 12, lg: 6 }}
            //sx={smallVerticalPaddingStyles}
            alignItems={"center"}
          >
            <Box display={"flex"} gap={2} alignItems={"flex-start"}>
              <Box>
                <Image
                  name={"Rounded-Check"}
                  variant={imagesFoldersTypes.ICONS}
                />
              </Box>

              <Box>
                <TextBox
                  variant={textTypes.SUBTITLE}
                  text={item?.title}
                  fontWeight={600}
                  color={listTitleColour}
                  marginBottom={0}
                />
                <TextBox text={item?.text} color={listTextColour} />
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default CoverListsGrid;
