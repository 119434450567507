import React from "react";
import { useIntl } from "react-intl";
import TextImageGrid from "../../../components/grids/textImageGrid.component.tsx";
import TextBox from "../../../components/text/textBox.component.tsx";
import { useFormatText } from "../../../i18n/Metronici18n.tsx";
import useStyles from "../../../hooks/styles.hook.tsx";
import SlotCounter from "react-slot-counter";
import MainLayout from "../../MainLayout.tsx";
import { imagesFoldersTypes, textTypes } from "../../../constants/app.constants.ts";

const Section6: React.FC = () => {
  const intl = useIntl();
  const formatText = useFormatText();

  const { verticalPaddingBoxStyles } = useStyles();

  return (
    <MainLayout>
      <TextImageGrid
        title={"TITLE.MasajulTerapeuticNecesitate"}
        subtitle={"SUBTITLE.CifreleVorbescDeLaSine"}
        image={'Backache'}
        imageVariant={imagesFoldersTypes.PAGE_IMAGES}
        alignImage={"center"}
      >
        <TextBox>
          <TextBox variant={textTypes.TITLE} fontWeight={400}>
            <SlotCounter
              value={"274,000"}
              duration={1}
              direction={"top-down"}
              animateOnVisible={{
                triggerOnce: false,
              }}
            />
          </TextBox>

          {formatText(
            intl.formatMessage({
              id: "TEXT.MasajulTerapeuticNecesitate_Paragraph1",
            })
          )}
        </TextBox>

        <TextBox sx={verticalPaddingBoxStyles}>
          <TextBox variant={textTypes.TITLE} fontWeight={400}>
            <SlotCounter
              value={"80"}
              duration={1}
              direction={"top-down"}
              animateOnVisible={{
                triggerOnce: false,
              }}
            />
            {"% "}
            {`${intl.formatMessage({
              id: "TEXT.DintreRomani",
            })}`}
          </TextBox>
          {formatText(
            intl.formatMessage({
              id: "TEXT.MasajulTerapeuticNecesitate_Paragraph2",
            })
          )}

          <TextBox
            text={"TEXT.MasajulTerapeuticNecesitate_Paragraph3"}
            sx={verticalPaddingBoxStyles}
          />
        </TextBox>
      </TextImageGrid>
    </MainLayout>
  );
};

export default Section6;
