import { screenTypes } from "../constants/app.constants.ts";

// TEXT INSIDE THE HOMEPAGE TRIANGLE
export const homepageTriangleTextBox = {
  [screenTypes.PHONE]: {
    padding: 2,
    maxWidth: "45%",
  },

  [screenTypes.TABLET]: {
    padding: "50px",
  },

  [screenTypes.LAPTOP]: {
    padding: "80px 52px",
  },

  [screenTypes.DESKTOP]: {
    padding: "80px 52px",
  },

  [screenTypes.ULTRAWIDE]: {
    padding: "100px 150px 100px 70px",
  },
};

// LIST ITEMS
export const listItemsBoxStyle = {
  [screenTypes.PHONE]: {
    padding: "0.45rem", 
  },

  [screenTypes.TABLET]: {
    padding: "0.475rem", 
  },

  [screenTypes.LAPTOP]: {
    padding: "0.5rem", 
  },

  [screenTypes.DESKTOP]: {
    padding: "0.5rem", 
  },

  [screenTypes.ULTRAWIDE]: {
    padding: "0.6rem", 
  },
};

export const footerHeightStyle = {
  [screenTypes.PHONE]: {
    height: "120px",
  },

  [screenTypes.TABLET]: {
    height: "75px",
  },

  [screenTypes.LAPTOP]: {
    height: "100px",
  },

  [screenTypes.DESKTOP]: {
    height: "100px",
  },

  [screenTypes.ULTRAWIDE]: {
    height: "120px",
  },
};

// SLIDESHOW CIRCLES
export const slideshowCirclesStyle = {
  [screenTypes.PHONE]: {
    height: "10px",
    width: "10px",
    margin: '0 10px 10px',
  },

  [screenTypes.TABLET]: {
    height: "15px",
    width: "15px",
    margin: '0 10px 15px',
  },

  [screenTypes.LAPTOP]: {
    height: "20px",
    width: "20px",
    margin: '0 15px 15px',
  },

  [screenTypes.DESKTOP]: {
    height: "20px",
    width: "20px",
    margin: '0 15px 15px',
  },

  [screenTypes.ULTRAWIDE]: {
    height: "30px",
    width: "30px",
    margin: '0 20px 15px',
  },
}

// PADDINGS
export const verticalPaddingBoxStyle = {
  [screenTypes.PHONE]: {
    padding: '1rem 0'
  },

  [screenTypes.TABLET]: {
    padding: '1.5rem 0'
  },

  [screenTypes.LAPTOP]: {
    padding: '2rem 0'
  },

  [screenTypes.DESKTOP]: {
    padding: '2.5rem 0'
  },

  [screenTypes.ULTRAWIDE]: {
    padding: '3rem 0'
  },
}

export const footerWideGapStyle = {
  [screenTypes.PHONE]: {
    marginRight: 0
  },

  [screenTypes.TABLET]: {
    marginRight: 0
  },

  [screenTypes.LAPTOP]: {
    marginRight: 10
  },

  [screenTypes.DESKTOP]: {
    marginRight: 14
  },

  [screenTypes.ULTRAWIDE]: {
    marginRight: 20
  },
}

export const verticalMarginsStyle = {
  [screenTypes.PHONE]: {
    margin: '30px 0'
  },
  [screenTypes.TABLET]: {
    margin: '50px 0'
  },
  [screenTypes.LAPTOP]: {
    margin: '70px 0'
  },
  [screenTypes.DESKTOP]: {
    margin: '80px 0'
  },
  [screenTypes.ULTRAWIDE]: {
    margin: '100px 0'
  }
}

export const smallPaddingStyle = {
  [screenTypes.PHONE]: {
    padding: '0.5rem 0.75rem'
  },
  [screenTypes.TABLET]: {
    padding: '0.5rem 1rem'
  },
  [screenTypes.LAPTOP]: {
    padding: '0.5rem 1.25rem'
  },
  [screenTypes.DESKTOP]: {
    padding: '0.5rem 1.5rem'
  },
  [screenTypes.ULTRAWIDE]: {
    padding: '0.5rem 1.75rem'
  }
}

export const smallVerticalPaddingStyle = {
  [screenTypes.PHONE]: {
    padding: '0.5rem 0'
  },
  [screenTypes.TABLET]: {
    padding: '0.6rem 0'
  },
  [screenTypes.LAPTOP]: {
    padding: '0.7rem 0'
  },
  [screenTypes.DESKTOP]: {
    padding: '0.7rem 0'
  },
  [screenTypes.ULTRAWIDE]: {
    padding: '0.8rem 0'
  }
}