import React from "react";
import colors from "../../../constants/colors.ts";
import MainLayout from "../../MainLayout.tsx";
import TextImageGrid from "../../../components/grids/textImageGrid.component.tsx";

const NationalPresence: React.FC = () => {

  return (
    <MainLayout isFooterDisplayed={true}>
      <TextImageGrid
        title={"TITLE.PrezentaNationala"}
        text={"SUBTITLE.PrezentaNationala"}
        buttonLabel={"BUTTON.MaxRelaxInOrasulTau"}
        buttonBgColor="none"
        buttonBorder={`1px solid ${colors.BLUE_0067F6}`}
        buttonTextColor={colors.BLUE_0067F6}
        image={'Wellington-Map-Locations'}
        imageSx={{width: '80%'}}
      />
    </MainLayout>
  );
};

export default NationalPresence;
