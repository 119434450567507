import React, { useEffect, useRef, useState } from "react";
import { Box } from "@mui/material";
import Grid from "@mui/material/Grid2";

import useStyles from "../../hooks/styles.hook.tsx";
import { TextImageSliderItemType } from "../../types/items.types.ts";
import TextPanel from "../text/TextPanel.component.tsx";
import { TextImageSliderProps } from "../../interfaces/text.interface.ts";
import Image from "../media/Image.component.tsx";
import { imagesFoldersTypes } from "../../constants/app.constants.ts";

const TextImageSlider: React.FC<TextImageSliderProps> = ({
  buttonBackgroundColour,
  title,
  subtitle,
  description,
  caption,
  items,
}) => {
  const {
    verticalPaddingBoxStyles,
    imagesScalingStyles,
  } = useStyles();

  const fadeIn = {
    opacity: 1,
    transform: "translateX(0)",
    transition: "opacity 0.85s ease-out, transform 0.85s ease-out",
  };

  const fadeOutLeft = {
    opacity: 0,
    transform: "translateX(-50%)",
    transition: "opacity 0.85s ease-out, transform 0.85s ease-out",
  };

  const fadeOutRight = {
    opacity: 0,
    transform: "translateX(50%)",
    transition: "opacity 0.85s ease-out, transform 0.85s ease-out",
  };

  const [visibleItems, setVisibleItems] = useState<number[]>([]);
  const itemRefs = useRef<(HTMLElement | null)[]>([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const index = Number(entry.target.getAttribute("data-index"));

          if (entry.isIntersecting) {
            // Element is entering the viewport
            setVisibleItems((prev) => {
              // If index is already present, return the same array (no need to add it again)
              if (!prev.includes(index)) {
                return [...prev, index];
              }
              return prev;
            });
          } else {
            // Element is leaving the viewport
            setVisibleItems((prev) => {
              // If index is already present, remove it for fade-out effect
              if (prev.includes(index)) {
                return prev.filter((i) => i !== index); // Remove index
              }
              return prev;
            });
          }
        });
      },
      { threshold: 0.1 }
    );

    itemRefs.current?.forEach((ref) => {
      if (ref) {
        observer.observe(ref);
      }
    });

    return () => observer.disconnect();
  }, []);

  return (
    <Box sx={verticalPaddingBoxStyles}>
      <TextPanel title={title} subtitle={subtitle} text={description} />

      <Box>
        {items?.map((item: TextImageSliderItemType, index) => (
          // INDEX IS ODD => IMAGE + TEXT
          <Grid
            key={index}
            container
            display={"flex"}
            alignItems={"center"}
            flexDirection={index % 2 ? "row-reverse" : "row"}
            alignContent={"center"}
            justifyContent={"center"}
            spacing={"103px"}
            ref={(element) => (itemRefs.current[index] = element)}
            data-index={index}
            sx={{
              ...(index % 2 ? fadeOutRight : fadeOutLeft ),
              ...(visibleItems.includes(index) && fadeIn), // Override with fadeIn if the item is visible
            }}
          >
            {/* Image Container */}
            <Grid
              //item
              size={{ xs: 12, md: 7, lg: 7 }}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Image
                name={item?.image?.image}
                extension={item?.image?.extension}
                sx={imagesScalingStyles}
                variant={imagesFoldersTypes.PAGE_IMAGES}
              />
            </Grid>

            {/* Text Container */}
            <Grid size={{ xs: 12, md: 5, lg: 5 }}>
              <TextPanel
                caption={caption}
                title={item?.title}
                text={item?.description}
                button={"BUTTON.FindOutMore"}
                buttonBackgroundColor={buttonBackgroundColour}
                buttonEndIcon={'Right-Arrow-Blue'}
              />
            </Grid>
          </Grid>
        ))}
      </Box>
    </Box>
  );
};

export default TextImageSlider;
