import React from "react";
import Grid from "@mui/material/Grid2";

import colors from "../../constants/colors.ts";
import TextBox from "../text/textBox.component.tsx";
import useStyles from "../../hooks/styles.hook.tsx";
import MainLayout from "../../modules/MainLayout.tsx";

const Footer: React.FC = () => {
  const { captionFontSizeStyles } = useStyles();

  return (
    <MainLayout backgroundColor={colors.GREY_2F3347} marginBottom={0}>
      <Grid
        container
        spacing={8}
        display={"flex"}
        alignItems={"center"}
        justifyContent={{ sm: "center", md: "flex-start" }}
        textAlign={{ md: "center", lg: "left" }}
        padding={"32px 0"}
      >
        <Grid>
          <TextBox
            color={colors.WHITE}
            sx={captionFontSizeStyles}
            text={"TITLE.Termeni&Conditii"}
            marginBottom={0}
          />
        </Grid>

        <Grid>
          <TextBox
            color={colors.WHITE}
            sx={captionFontSizeStyles}
            text={"TITLE.PoliticaDeConfidentialitate"}
            marginBottom={0}
          />
        </Grid>

        <Grid size={{ xs: 12, sm: 5 }}>
          <TextBox
            color={colors.WHITE}
            sx={captionFontSizeStyles}
            text={"TITLE.DrepturiRezervate"}
            marginBottom={0}
          />
        </Grid>
      </Grid>
    </MainLayout>
  );
};

export default Footer;
