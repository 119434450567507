import React from "react";
import colors from "../../../constants/colors.ts";
import HomepageSecondaryLayout from "../../../components/homepages/homepageSecondaryLayout.tsx";
import Section2 from "./Section2.massage.tsx";
import { imagesFoldersTypes } from "../../../constants/app.constants.ts";

const Section1: React.FC = () => {
  return (
    <HomepageSecondaryLayout
      backgroundColor={colors.BLUE_B4DBFF}
      media={'Massage-Homepage-Video'}
      mediaVariant={imagesFoldersTypes.VIDEOS}
      title={"TITLE.DespreMasaj"}
      titleColour={colors.BLUE_242737}
      button={"BUTTON.CereOferta"}
      buttonBackgroundColour={colors.BLUE_242737}
      buttonColour={colors.WHITE}
    >
      <Section2 />
    </HomepageSecondaryLayout>
  );
};

export default Section1;
