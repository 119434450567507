import React from "react";
import { headerItems } from "../../constants/header.constants.ts";
import SettingsBar from "./settingsBar.component.tsx";
import { maxTypes, MaxTypes } from "../../constants/app.constants.ts";
import Navbar from "./navbar/navbar.component.tsx";

// https://codesandbox.io/p/sandbox/framer-motion-variants-forked-f9mrkv?file=%2Fsrc%2FApp.tsx%3A33%2C26

const Header: React.FC<{
  isDark?: boolean;
  backgroundColor?: string;
  maxType?: MaxTypes;
}> = ({ isDark = false, backgroundColor, maxType = maxTypes.MAX_RELAX }) => {
  return (
    <div
      style={{
        zIndex: 1000,
        position: "absolute",
        width: "100%",
        maxWidth: "1280px",
      }}
    >
      {/* Settings Bar  */}
      <SettingsBar isDark={isDark} backgroundColor={backgroundColor} />

      {/* Navbar */}
      <Navbar items={headerItems} maxType={maxType} />
    </div>
  );
};

export default Header;
