import React from "react";
import MainLayout from "../../MainLayout.tsx";
import ListGrid from "../../../components/grids/listGrid.component.tsx";
import { eventsIncludedServicesItemsGrid } from "../../../constants/lists.constants.ts";

const Section10: React.FC = () => {
  return (
    <MainLayout>
      <ListGrid items={eventsIncludedServicesItemsGrid} />
    </MainLayout>
  );
};

export default Section10;
