import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import useStyles from "../../hooks/styles.hook.tsx";
import Button from "../button/button.component.tsx";
import TextBox from "./textBox.component.tsx";
import { TextPanelProps } from "../../interfaces/text.interface.ts";
import { TextTypes, textTypes } from "../../constants/app.constants.ts";
import Image from "../media/Image.component.tsx";

const TextPanel: React.FC<TextPanelProps> = ({
  alignItems = "flex-start",
  backgroundColor,
  icon,
  iconExtension,
  variant = "",
  caption,
  captionColor,
  captionTextTransform = "uppercase",
  title,
  titleAlign,
  titleBoxHeight,
  titleColor,
  titleFontWeight = 600,
  titleFontSize = 36,
  subtitle,
  subtitleColor,
  subtitleFontWeight = 600,
  text,
  textBoxHeight,
  textColor,
  button,
  buttonColor,
  buttonBorderColor,
  buttonBackgroundColor,
  buttonEndIcon,
  buttonStartIcon,
  justifyContent,
  textAlign,
  style,
}) => {
  const { smallMarginBottomStyles } = useStyles();

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      justifyContent={justifyContent}
      sx={{
        backgroundColor: backgroundColor,
        alignItems: alignItems,
        ...style,
      }}
    >
      {/* Icon */}
      {icon && (
        <Image
          variant={variant}
          name={icon}
          sx={smallMarginBottomStyles}
          extension={iconExtension}
        />
      )}

      {caption && (
        <TextBox
          variant={textTypes.CAPTION}
          fontFamily="Inter Regular"
          textTransform={captionTextTransform}
          fontWeight={500}
          text={caption}
          color={captionColor}
          fontSize={12}
        />
      )}

      {title && (
        <TextBox
          variant={textTypes.TITLE}
          text={title}
          textAlign={titleAlign}
          color={titleColor}
          fontWeight={titleFontWeight}
          fontSize={titleFontSize}
          sx={{
            height: titleBoxHeight ? titleBoxHeight : "auto",
            display: "flex",
            alignItems: "center",
          }}
        />
      )}

      {subtitle && (
        <TextBox
          variant={textTypes.SUBTITLE}
          text={subtitle}
          color={subtitleColor}
          fontWeight={subtitleFontWeight}
          fontSize={18}
        />
      )}

      {text && (
        <TextBox
          variant={textTypes.TEXT}
          textAlign={textAlign}
          text={text}
          color={textColor}
          fontWeight={400}
          fontSize={16}
          marginBottom={button ? 2 : 0}
          sx={{
            height: textBoxHeight ? textBoxHeight : "auto",
            display: "flex",
            alignItems: "flex-start",
          }}
        />
      )}

      {button && (
        <Button
          text={button}
          fontSize={16}
          border={buttonBorderColor}
          color={buttonColor}
          backgroundColor={buttonBackgroundColor}
          endIcon={buttonEndIcon}
          startIcon={buttonStartIcon}
        />
      )}
    </Box>
  );
};

export default TextPanel;
