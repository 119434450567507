import React, { useEffect, useRef, useState } from "react";
import { HomepageLayoutProps } from "../../interfaces/homepageLayout.interface";
import colors from "../../constants/colors.ts";
import Box from "@mui/material/Box";
import MainLayout from "../../modules/MainLayout.tsx";
import { useScreenSize } from "../../context/ScreenSize.context.tsx";
import useStyles from "../../hooks/styles.hook.tsx";
import { maxTypes, screenTypes } from "../../constants/app.constants.ts";
import ScrollableMedia from "../media/scrollableMedia.component.tsx";
import TextPanel from "../text/TextPanel.component.tsx";
import Grid from "@mui/material/Grid2";

const HomepageSecondaryLayout: React.FC<
  HomepageLayoutProps & React.PropsWithChildren
> = ({
  backgroundColor,
  media,
  mediaVariant,
  title,
  titleColour = colors.BLUE_242737,
  description,
  descriptionColour = colors.BLUE_242737,
  button,
  buttonColour = colors.WHITE,
  buttonBackgroundColour = colors.BLUE_242737,
  areBubblesDisplayed = false,
  maxType = maxTypes.MAX_RELAX,
  isDark = true,
  children,
}) => {
  const outerBoxRef = useRef<HTMLElement>(null);
  const [outerBoxHeight, setOuterBoxHeight] = useState(0);

  const screenSize = useScreenSize();

  const {
    textBoxHorizontalPaddingStyles,
    textBoxPaddingStyles,
    secondaryBackgroundColourHeightStyles,
    verticalMarginsStyles,
    smallMarginsTopStyles,
  } = useStyles();

  const paddingStyles =
    screenSize === screenTypes.PHONE || screenSize === screenTypes.TABLET
      ? textBoxPaddingStyles
      : textBoxHorizontalPaddingStyles;

  useEffect(() => {
    if (outerBoxRef.current) {
      setOuterBoxHeight(outerBoxRef.current.offsetHeight);
    }
  }, [outerBoxRef.current]);

  return (
    <>
      <Box position="relative" ref={outerBoxRef}>
        <MainLayout
          backgroundColor={backgroundColor}
          isHeaderDisplayed={true}
          isDark={isDark}
          maxType={maxType}
          sx={{
            display: "flex",
            alignItems: "center",
            height: "550px",
          }}
        >
          <Grid
            size={{ md: 5, sm: 12 }}
            container
            justifyContent={"center"}
            textAlign={{ md: "left", xs: "center" }}
            paddingLeft={10}
          >
            <TextPanel
              title={title}
              titleColor={titleColour}
              text={description}
              textColor={descriptionColour}
              button={button}
              buttonColor={buttonColour}
              buttonBackgroundColor={buttonBackgroundColour}
            />
          </Grid>
        </MainLayout>
        <MainLayout backgroundColor={"none"}>{children}</MainLayout>
        <MainLayout
          isOverflowHidden={false}
          sx={{
            position: "absolute",
            top: 13,
          }}
        >
          <Grid size={{ md: 7, sm: 12 }} padding={"0 80px"} >
            <ScrollableMedia
              media={media}
              variant={mediaVariant}
              areBubblesDisplayed={areBubblesDisplayed}
              outerBoxHeight={outerBoxHeight}
              sx={{
                width: '65%',
                marginTop: 5
              }}
            />
          </Grid>
        </MainLayout>
      </Box>
    </>
  );
};

export default HomepageSecondaryLayout;
