import { screenTypes } from "../constants/app.constants.ts";

export const titleFontSizeStyle = {
  [screenTypes.PHONE]: {
    fontSize: "1.3rem",
    //marginBottom: "0.625rem",
  },

  [screenTypes.TABLET]: {
    fontSize: "1.7rem",
    //marginBottom: "0.875rem",
  },

  [screenTypes.LAPTOP]: {
    fontSize: "2.1rem",
    //marginBottom: "1.25rem",
  },

  [screenTypes.DESKTOP]: {
    fontSize: "2.65rem",
    //marginBottom: "1.75rem",
  },

  [screenTypes.ULTRAWIDE]: {
    fontSize: "3.4rem",
    //marginBottom: "2.5rem",
  },
};

export const textBoxFontSizeStyle = {
    [screenTypes.PHONE]: {
      fontSize: "0.9rem",
    },
  
    [screenTypes.TABLET]: {
      fontSize: "1.3rem",
    },
  
    [screenTypes.LAPTOP]: {
      fontSize: "1.5rem",
    },
  
    [screenTypes.DESKTOP]: {
      fontSize: "1.375rem",
    },
  
    [screenTypes.ULTRAWIDE]: {
      fontSize: "1.75rem",
    }
};

export const headerMenuFontSizeStyle = {
  [screenTypes.PHONE]: {
    fontSize: '0.75rem'
  },
  [screenTypes.TABLET]: {
    fontSize: '0.8rem'
  },
  [screenTypes.LAPTOP]: {
    fontSize: '1rem'
  },
  [screenTypes.DESKTOP]: {
    fontSize: '1.125rem'
  },
  [screenTypes.ULTRAWIDE]: {
    fontSize: '1.25rem'
  }
}

export const headerMenuItemFontSizeStyle = {
  [screenTypes.PHONE]: {
    fontSize: '0.8rem'
  },
  [screenTypes.TABLET]: {
    fontSize: '0.875rem'
  },
  [screenTypes.LAPTOP]: {
    fontSize: '0.95rem'
  },
  [screenTypes.DESKTOP]: {
    fontSize: '1rem'
  },
  [screenTypes.ULTRAWIDE]: {
    fontSize: '1.125rem'
  }
}

export const textBoxVerticalPaddingStyle = {
  [screenTypes.PHONE]: {
    padding: "0.625rem 0",
  },

  [screenTypes.TABLET]: {
    padding: "0.875rem 0",
  },

  [screenTypes.LAPTOP]: {
    padding: "1.25rem 0",
  },

  [screenTypes.DESKTOP]: {
    padding: "1.375rem 0",
  },

  [screenTypes.ULTRAWIDE]: {
    padding: "1.75rem 0",
  }
}

export const textBoxPaddingStyle = {
  [screenTypes.PHONE]: {
    padding: "25px",
  },

  [screenTypes.TABLET]: {
    padding: "50px",
  },

  [screenTypes.LAPTOP]: {
    padding: "80px",
  },

  [screenTypes.DESKTOP]: {
    padding: "80px",
  },

  [screenTypes.ULTRAWIDE]: {
    padding: "100px",
  },
};

export const textMarginBottomStyle = {
  [screenTypes.PHONE]: {
    marginBottom: "0.625rem",
  },

  [screenTypes.TABLET]: {
    marginBottom: "0.875rem",
  },

  [screenTypes.LAPTOP]: {
    marginBottom: "1.25rem ",
  },

  [screenTypes.DESKTOP]: {
    marginBottom: "1.375rem",
  },

  [screenTypes.ULTRAWIDE]: {
    marginBottom: "1.75rem",
  },
};

export const textBoxHorizontalPaddingStyle = {
  [screenTypes.PHONE]: {
    padding: "0 25px",
  },

  [screenTypes.TABLET]: {
    padding: "0 50px",
  },

  [screenTypes.LAPTOP]: {
    padding: "0 80px",
  },

  [screenTypes.DESKTOP]: {
    padding: "0 80px",
  },

  [screenTypes.ULTRAWIDE]: {
    padding: "0 100px",
  },
};

// BUTTON COMPONENT TEXT
export const buttonTextStyle = {
  [screenTypes.PHONE]: {
    fontSize: "0.7rem",
    letterSpacing: "0.11px",
    lineHeight: "1rem",
    padding: "0.2rem",
  },

  [screenTypes.TABLET]: {
    fontSize: "0.85rem",
    letterSpacing: "0.13px",
    lineHeight: "1.3125rem",
    padding: "0.5rem",
  },

  [screenTypes.LAPTOP]: {
    fontSize: "1rem",
    letterSpacing: "0.15px",
    lineHeight: "1.4375rem",
    padding: "0.75rem",
  },

  [screenTypes.DESKTOP]: {
    fontSize: "1rem",
    letterSpacing: "0.15px",
    lineHeight: "1.5rem",
    padding: "0.75rem",
  },

  [screenTypes.ULTRAWIDE]: {
    fontSize: "1.1875rem",
    letterSpacing: "0.175px",
    lineHeight: "1.5625rem",
    padding: "0.9375rem",
  },
};

// CAPTION TEXT
export const captionFontSizeStyle = {
    [screenTypes.PHONE]: {
      fontSize: "0.85rem",
      // letterSpacing: "0.11px",
      // lineHeight: "1.1875rem",
    },
  
    [screenTypes.TABLET]: {
      fontSize: "0.8375rem",
      // letterSpacing: "0.13px",
      // lineHeight: "1.3125rem",
    },
  
    [screenTypes.LAPTOP]: {
      fontSize: "1rem",
      // letterSpacing: "0.15px",
      // lineHeight: "1.4375rem",
    },
  
    [screenTypes.DESKTOP]: {
      fontSize: "0.9rem",
      // letterSpacing: "0.15px",
      // lineHeight: "1.5rem",
    },
  
    [screenTypes.ULTRAWIDE]: {
      fontSize: "0.9rem",
      // letterSpacing: "0.175px",
      // lineHeight: "1.5625rem",
    },
  };

// SUBTITLE TEXT
export const subtitleFontSizeStyle = {
  [screenTypes.PHONE]: {
    fontSize: '1rem',
  },
  [screenTypes.TABLET]: {
    fontSize: '1.25rem',
  },
  [screenTypes.LAPTOP]: {
    fontSize: '1.5rem',
  },
  [screenTypes.DESKTOP]: {
    fontSize: '2rem',
  },
  [screenTypes.ULTRAWIDE]: {
    fontSize: '2.75rem',
  },
}

export const inputTextStyle = {
  [screenTypes.PHONE]: {
    fontSize: '0.875rem',
    padding: '0 0.75rem'
  },

  [screenTypes.TABLET]: {
    fontSize: '1rem',
    padding: '0 1rem'
  },

  [screenTypes.LAPTOP]: {
    fontSize: '1.125rem',
    padding: '0 1.25rem'
  },

  [screenTypes.DESKTOP]: {
    fontSize: '1.25rem',
    padding: '0 1.5rem'
  },

  [screenTypes.ULTRAWIDE]: {
    fontSize: '1.5rem',
    padding: '0 2rem'
  }
}

export const footerLinksTitleStyle = {
  [screenTypes.PHONE]: {
    fontSize: '0.95rem',
  },
  [screenTypes.TABLET]: {
    fontSize: '1rem',
  },
  [screenTypes.LAPTOP]: {
    fontSize: '1.125rem',
  },
  [screenTypes.DESKTOP]: {
    fontSize: '1.25rem',
  },
  [screenTypes.ULTRAWIDE]: {
    fontSize: '1.75rem',
  },
}


// named with styleS because they don't depend on screen size
export const captionStyles = {
  fontFamily: "'SF Pro Text Medium', 'sans-serif'"
}

export const titleStyles = {
  fontFamily: "'Inter Regular', 'sans-serif'",
  fontWeight: 800
}

export const subtitleStyles = {
  fontFamily: "'SF Pro', 'sans-serif'",
  fontWeight: 600
}

export const textStyles = {
  fontFamily: "'SF Pro Text Regular', 'sans-serif'",
  letterSpacing: 0.05,
}