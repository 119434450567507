import React from "react";
import { IntlProvider } from "react-intl";
import enMessages from "./messages/en.json";
import roMessages from "./messages/ro.json";
import { useLang } from "./Metronici18n.tsx";

const allMessages = {
  en: enMessages,
  ro: roMessages,
};

const I18nProvider: React.FC<any> = ({ children }) => {
  const locale = useLang();
  const messages = allMessages[locale];

  return (
    <IntlProvider locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  );
};

export { I18nProvider };
