import React from "react";
import MainLayout from "../../MainLayout.tsx";
import TextImageGrid from "../../../components/grids/textImageGrid.component.tsx";
import colors from "../../../constants/colors.ts";

const Section4: React.FC = () => {
  return (
    <MainLayout>
      <TextImageGrid
        lg={12}
        md={12}
        spacing={0}
        title={"TITLE.MotiveazaAngajatii"}
        backgroundColor={colors.BLUE_F0FAFF}
      />
    </MainLayout>
  );
};

export default Section4;
