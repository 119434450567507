import { useScreenSize } from "../context/ScreenSize.context.tsx";
import {
  footerHeightStyle,
  footerWideGapStyle,
  homepageTriangleTextBox,
  listItemsBoxStyle,
  slideshowCirclesStyle,
  smallPaddingStyle,
  smallVerticalPaddingStyle,
  verticalMarginsStyle,
  verticalPaddingBoxStyle,
} from "../styles/boxes.styles.ts";
import { gridHeightStyle } from "../styles/grids.styles.ts";
import {
  colouredComponentHeightStyle,
  smallMarginBottomStyle,
  mainLayoutBoxStyle,
  mainBackgroundColourHeightStyle,
  secondaryBackgroundColourHeightStyle,
  smallMarginTopStyle,
  mainLayoutPaddingStyle,
  marginBottomStyle,
  marginTopStyle,
} from "../styles/mainLayout.styles.ts";
import {
  avatarImageScalingStyle,
  headerImageScalingStyle,
  homepageTriangleStyle,
  imagesScalingStyle,
  mediaHeightScalingStyle,
  slideshowWidthStyle,
} from "../styles/media.styles.ts";
import {
  buttonTextStyle,
  inputTextStyle,
  textBoxPaddingStyle,
  textBoxFontSizeStyle,
  textBoxVerticalPaddingStyle,
  titleFontSizeStyle,
  headerMenuFontSizeStyle,
  headerMenuItemFontSizeStyle,
  subtitleFontSizeStyle,
  footerLinksTitleStyle,
  textBoxHorizontalPaddingStyle,
  textMarginBottomStyle,
  captionFontSizeStyle,
} from "../styles/text.styles.ts";

function useStyles() {
  const screenSize = useScreenSize();
  //console.log(screenSize);

  // MAIN LAYOUT
  const boxStyles = mainLayoutBoxStyle[screenSize];
  const smallMarginBottomStyles = smallMarginBottomStyle[screenSize];
  const colouredComponentHeightStyles =
    colouredComponentHeightStyle[screenSize];
  const mainBackgroundColourHeightStyles =
    mainBackgroundColourHeightStyle[screenSize];
  const secondaryBackgroundColourHeightStyles =
    secondaryBackgroundColourHeightStyle[screenSize];
  const marginBottomStyles = marginBottomStyle[screenSize]
  const marginTopStyles = marginTopStyle[screenSize]

  // TEXT
  const titleFontSizeStyles = titleFontSizeStyle[screenSize];
  const textBoxFontSizeStyles = textBoxFontSizeStyle[screenSize];
  const textBoxPaddingStyles = textBoxPaddingStyle[screenSize];
  const buttonTextStyles = buttonTextStyle[screenSize];
  const captionFontSizeStyles = captionFontSizeStyle[screenSize];
  const subtitleFontSizeStyles = subtitleFontSizeStyle[screenSize];
  const inputTextStyles = inputTextStyle[screenSize];
  const headerMenuFontSizeStyles = headerMenuFontSizeStyle[screenSize];
  const headerMenuItemFontSizeStyles = headerMenuItemFontSizeStyle[screenSize];
  const footerLinksTitleStyles = footerLinksTitleStyle[screenSize];

  // BOX
  const homepageTextBoxStyles = homepageTriangleTextBox[screenSize];
  const listItemsBoxStyles = listItemsBoxStyle[screenSize];
  const footerHeightStyles = footerHeightStyle[screenSize];
  const slideshowCircleStyles = slideshowCirclesStyle[screenSize];
  const textBoxHorizontalPaddingStyles =
    textBoxHorizontalPaddingStyle[screenSize];

  // PADDINGS
  const verticalPaddingBoxStyles = verticalPaddingBoxStyle[screenSize];
  const textBoxVerticalPaddingStyles = textBoxVerticalPaddingStyle[screenSize];
  const smallPaddingStyles = smallPaddingStyle[screenSize];
  const smallVerticalPaddingStyles = smallVerticalPaddingStyle[screenSize];
  const mainLayoutPaddingStyles = mainLayoutPaddingStyle[screenSize];

  // MARGINS
  const footerWideGapStyles = footerWideGapStyle[screenSize];
  const verticalMarginsStyles = verticalMarginsStyle[screenSize];
  const smallMarginsTopStyles = smallMarginTopStyle[screenSize];
  const textMarginBottomStyles = textMarginBottomStyle[screenSize];

  // MEDIA
  const homepageTriangleStyles = homepageTriangleStyle[screenSize];
  const imagesScalingStyles = imagesScalingStyle[screenSize];
  const avatarImageScalingStyles = avatarImageScalingStyle[screenSize];
  const headerImageScalingStyles = headerImageScalingStyle[screenSize];
  const mediaHeightScalingStyles = mediaHeightScalingStyle[screenSize];
  const slideshowWidthStyles = slideshowWidthStyle[screenSize];

  // GRID
  const gridHeightStyles = gridHeightStyle[screenSize];

  return {
    // LAYOUT
    boxStyles,
    smallMarginBottomStyles,
    colouredComponentHeightStyles,
    mainBackgroundColourHeightStyles,
    secondaryBackgroundColourHeightStyles,
    marginTopStyles,
    marginBottomStyles,

    // TEXT
    titleFontSizeStyles,
    textBoxFontSizeStyles,
    textBoxPaddingStyles,
    buttonTextStyles,
    captionFontSizeStyles,
    subtitleFontSizeStyles,
    inputTextStyles,
    headerMenuFontSizeStyles,
    headerMenuItemFontSizeStyles,
    footerLinksTitleStyles,

    // BOX
    homepageTextBoxStyles,
    listItemsBoxStyles,
    footerHeightStyles,
    slideshowCircleStyles,

    // PADDING
    verticalPaddingBoxStyles,
    textBoxVerticalPaddingStyles,
    textBoxHorizontalPaddingStyles,
    smallPaddingStyles,
    smallVerticalPaddingStyles,
    mainLayoutPaddingStyles,

    footerWideGapStyles,
    verticalMarginsStyles,
    smallMarginsTopStyles,
    textMarginBottomStyles,

    // MEDIA
    homepageTriangleStyles,
    imagesScalingStyles,
    avatarImageScalingStyles,
    headerImageScalingStyles,
    mediaHeightScalingStyles,
    slideshowWidthStyles,

    // GRID
    gridHeightStyles,
  };
}

export default useStyles;
