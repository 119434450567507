import React from "react";
import Box from "@mui/material/Box";
import useStyles from "../../hooks/styles.hook.tsx";
import { MenuItem, Select, ThemeProvider } from "@mui/material";
import { useLang, setLanguage } from "../../i18n/Metronici18n.tsx";
import SELECTOR_THEME from "../../themes/selector.themes.ts";

const LanguageSelector: React.FC = () => {
  const lang = useLang();

  const { headerMenuFontSizeStyles } = useStyles();

  const handleLanguageSelect = (e) => {
    setLanguage(e.target.value);
  };

  return (
    <ThemeProvider theme={SELECTOR_THEME}>
      <Box display={"flex"}>
        <Select
        disabled={true}
          value={lang}
          onChange={(e) => handleLanguageSelect(e)}
          disableUnderline={true}
        >
          <MenuItem value="ro">Ro</MenuItem>
          <MenuItem value="en">En</MenuItem>
        </Select>
      </Box>
    </ThemeProvider>
  );
};

export default LanguageSelector;
