import React from "react";
import HomepageSecondaryLayout from "../../../components/homepages/homepageSecondaryLayout.tsx";
import colors from "../../../constants/colors.ts";
import Section2 from "./Section2.max-active.tsx";
import { maxTypes } from "../../../constants/app.constants.ts";

const Section1: React.FC = () => {
  return (
    <HomepageSecondaryLayout
      title="TITLE.DespreTerapii"
      media={'Picture-Pattern'}
      backgroundColor={colors.TURQUOISE_ADFFFF}
      button="BUTTON.SolicitaOferta"
      maxType={maxTypes.MAX_ACTIVE}
    >
      <Section2/>
    </HomepageSecondaryLayout>
  );
};

export default Section1;
