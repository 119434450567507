import React from "react";
import Grid from "@mui/material/Grid2";

import { LinkGridsProps } from "../../interfaces/grid.interface.ts";
import { FooterLink } from "../../types/footer.types.ts";
import TextBox from "../text/textBox.component.tsx";
import {
  imagesFoldersTypes,
  textTypes,
} from "../../constants/app.constants.ts";
import Image from "../media/Image.component.tsx";
import { motion } from "framer-motion";

const LinkGrid: React.FC<LinkGridsProps> = ({
  columnsNumber,
  md = 12 / columnsNumber,
  items,
  itemBackgroundColour,
  iconColour = null,
  sx,
}) => {
  return (
    <Grid container display={"flex"} justifyContent={"center"} spacing={5}>
      {items?.map((item: FooterLink, index) => (
        <Grid
          container
          justifyContent={"center"}
          key={index}
          size={{ lg: 12 / columnsNumber, md: md, sm: 6 }}
          sx={{
            borderRadius: "20px",
            backgroundColor: itemBackgroundColour,
            padding: 3
          }}
          >
            <motion.button
              style={{
                backgroundColor: "transparent",
                border: "none",
              }}
              whileHover={{
                scale: 1.2,
                transition: { duration: 0.3 },
              }}
            >
              {item?.icon && (
                <Image
                  name={iconColour ? `${item?.icon}-${iconColour}` : item?.icon}
                  variant={item?.variant || imagesFoldersTypes.ICONS}
                />
              )}

              <TextBox
                variant={textTypes.CAPTION}
                text={item?.label}
                textAlign="center"
                marginBottom={0}
              />
            </motion.button>
        </Grid>
      ))}
    </Grid>
  );
};

export default LinkGrid;
