import React from "react";
import BenefitsGrid from "../../../components/grids/benefitsGrid.component.tsx";
import { therapiesRow } from "../../../constants/homepage.constants.ts";
import colors from "../../../constants/colors.ts";
import MainLayout from "../../MainLayout.tsx";

const TherapiesBenefits: React.FC = () => {
  return (
    <MainLayout>
      <BenefitsGrid
        title={"TITLE.BeneficiileTerapiilor"}
        containers={therapiesRow}
        backgroundColor={colors.CYAN_E6FFFF}
        color={colors.BLUE_242737}
      />
    </MainLayout>
  );
};

export default TherapiesBenefits;
