import React from "react";
import MainLayout from "../../MainLayout.tsx";
import Box from "@mui/material/Box";
import { ListItemType } from "../../../types/text.types.ts";
import colors from "../../../constants/colors.ts";
import TextPanel from "../../../components/text/TextPanel.component.tsx";
import useStyles from "../../../hooks/styles.hook.tsx";
import TextBox from "../../../components/text/textBox.component.tsx";
import { textTypes } from "../../../constants/app.constants.ts";
import { misionVisionValuesList } from "../../../constants/lists.constants.ts";

const Section3: React.FC = () => {
  const { smallPaddingStyles, verticalPaddingBoxStyles } = useStyles();

  return (
    <MainLayout>
      <Box sx={verticalPaddingBoxStyles}>
        <TextBox
          variant={textTypes.TITLE}
          text={"TITLE.MisiuneaViziuneaValorileNoastre"}
        />

        <Box display={"flex"} justifyContent={"space-between"} gap={8}>
          {misionVisionValuesList?.map((item: ListItemType, index) => (
              <TextPanel
                key={index}
                backgroundColor={colors.BLUE_F0FAFF}
                icon={item?.icon}
                variant={item?.iconVariant}
                title={item?.title}
                text={item?.text}
                style={{
                  borderRadius: "20px",
                  ...smallPaddingStyles
                }}
              />
          ))}
        </Box>
      </Box>
    </MainLayout>
  );
};

export default Section3;
