import React from "react";
import TextImageGrid from "../../../components/grids/textImageGrid.component.tsx";
import colors from "../../../constants/colors.ts";
import MainLayout from "../../MainLayout.tsx";
import { imagesFoldersTypes } from "../../../constants/app.constants.ts";

const Section8: React.FC = () => {
  return (
    <MainLayout>
      <TextImageGrid
        title={"TITLE.BeneficiileMasajului"}
        text={"TEXT.BeneficiileMasajului"}
        image={'Massage-Benefits'}
        imageVariant={imagesFoldersTypes.PAGE_IMAGES}
        backgroundColor={colors.BLUE_B4DBFF}
        heightLg={85}
      />
    </MainLayout>
  );
};

export default Section8;
