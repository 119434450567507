import React from "react";
import TextImageSlider from "../../../components/slideshow/textImageSlider.component.tsx";
import colors from "../../../constants/colors.ts";
import MainLayout from "../../MainLayout.tsx";
import { massageTypes } from "../../../constants/cards.constants.ts";

const Section5: React.FC = () => {
  return (
    <MainLayout isFooterDisplayed={true} backgroundColor={colors.PINK_F4F4FB}>
      <TextImageSlider
        buttonBackgroundColour={colors.PINK_F4F4FB}
        title={"TITLE.TipuriDeMasaj"}
        description={"DESCRIPTION.TipuriDeMasaj"}
        caption="TITLE.TipuriDeMasaj"
        items={massageTypes}
      />
    </MainLayout>
  );
};

export default Section5;
