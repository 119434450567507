import { screenTypes } from "../constants/app.constants.ts";

export const homepageTriangleStyle = {
  [screenTypes.PHONE]: {
    height: "400px",
  },

  [screenTypes.TABLET]: {
    height: "550px",
  },

  [screenTypes.LAPTOP]: {
    height: "650px",
  },

  [screenTypes.DESKTOP]: {
    height: "700px",
  },

  [screenTypes.ULTRAWIDE]: {
    height: "750px",
  },
};

export const imagesScalingStyle = {
  [screenTypes.PHONE]: {
    width: "60%",
    height: "auto",
    //objectFit: "cover",
  },

  [screenTypes.TABLET]: {
    width: "65%",
    height: "auto",
    //objectFit: "cover",
  },

  [screenTypes.LAPTOP]: {
    width: "65%",
    height: "auto",
    //objectFit: "cover",
  },

  [screenTypes.DESKTOP]: {
    width: "65%",
    height: "auto",
    //objectFit: "contain",
  },

  [screenTypes.ULTRAWIDE]: {
    width: "65%",
    height: "auto",
    //objectFit: "cover",
  },
};

export const mediaHeightScalingStyle = {
  [screenTypes.PHONE]: {
    height: "200px",
    width: "100%",
    objectFit: "cover",
  },
  [screenTypes.TABLET]: {
    height: "300px",
    width: "100%",
    objectFit: "cover",
  },
  [screenTypes.LAPTOP]: {
    height: "400px",
    width: "100%",
    objectFit: "cover",
  },
  [screenTypes.DESKTOP]: {
    height: "350px",
    width: "100%",
    objectFit: "cover",
  },
  [screenTypes.ULTRAWIDE]: {
    height: "600px",
    width: "100%",
    objectFit: "cover",
  },
};

export const avatarImageScalingStyle = {
  [screenTypes.PHONE]: {
    width: "40px",
    height: "45px",
  },

  [screenTypes.TABLET]: {
    width: "50px",
    height: "55px",
  },

  [screenTypes.LAPTOP]: {
    width: "60px",
    height: "65px",
  },

  [screenTypes.DESKTOP]: {
    width: "70px",
    height: "75px",
  },

  [screenTypes.ULTRAWIDE]: {
    width: "80px",
    height: "85px",
  },
};

export const headerImageScalingStyle = {
  [screenTypes.PHONE]: {
    width: "85px",
    height: "auto", // '18px'
  },
  [screenTypes.TABLET]: {
    width: "90px",
    height: "auto", // '23px'
  },
  [screenTypes.LAPTOP]: {
    width: "100px",
    height: "auto", // '27px'
  },
  [screenTypes.DESKTOP]: {
    width: "120px",
    height: "auto", // '30px'
  },
  [screenTypes.ULTRAWIDE]: {
    width: "130px",
    height: "auto", //'33px'
  },
};

export const slideshowWidthStyle = {
  [screenTypes.PHONE]: {
    width: '350px'
  },

  [screenTypes.TABLET]: {
    width: '500px'
  },

  [screenTypes.LAPTOP]: {
    width: '500px'
  },

  [screenTypes.DESKTOP]: {
    width: '700px'
  },
  [screenTypes.ULTRAWIDE]: {
    width: '750px'
  }
}