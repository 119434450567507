import React from "react";
import MainLayout from "../../MainLayout.tsx";
import { Box } from "@mui/material";
import colors from "../../../constants/colors.ts";
import useStyles from "../../../hooks/styles.hook.tsx";
import { maxActiveServices } from "../../../constants/cards.constants.ts";
import CardsGrid from "../../../components/grids/cardsGrid.component.tsx";
import Button from "../../../components/button/button.component.tsx";
import TextBox from "../../../components/text/textBox.component.tsx";
import { imagesFoldersTypes, textTypes } from "../../../constants/app.constants.ts";
import Image from "../../../components/media/Image.component.tsx";

const Section6: React.FC = () => {
  const {
    marginBottomStyles,
    textMarginBottomStyles,
  } = useStyles();
  return (
    <MainLayout>
      <Box border={`1px solid ${colors.GREY_CECEDB}`} sx={marginBottomStyles} />

      <Box sx={textMarginBottomStyles}>
        <Image name={'Max-Active'} variant={imagesFoldersTypes.LOGOS} />
      </Box>

      <TextBox
        variant={textTypes.SUBTITLE}
        color={colors.GREEN_00DE00}
        text={"TITLE.ServiciiRecomandateMaxActive"}
      />

      <CardsGrid
        cards={maxActiveServices}
        backgroundColor={colors.WHITE}
        sx={textMarginBottomStyles}
      />

      <Button
        border={`1px solid ${colors.BLUE_0067F6}`}
        text={"BUTTON.DescoperaServiciileMaxActive"}
        endIcon={'Right-Arrow-Blue'}
      />
    </MainLayout>
  );
};

export default Section6;
