import { ExtendedTestimonialType } from "../interfaces/testimonials.interface.ts";
import { EmployeeInfoType } from "../types/items.types.ts";
import colors from "./colors.ts";

export const employeesInfoSliderItems: EmployeeInfoType[] = [
  {
    image: "Employee",
    name: "Name here",
    role: "Role I 2020",
    testimonial: {
      title:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus dictum, tortor ac interdum vulputate, quam felis varius justo, non faucibus justo mi in nisi.",
      text: [
        "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        "Este o ședință de masaj profesionist care se realizează peste haine și durează 15 minute. Masajul este executat de către un kinetoterapeut,Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum pe un scaun special de masaj.",
      ],
      media: ['IMAGE_1', 'IMAGE_2', 'IMAGE_3'],
    },
  },
  {
    image: "Employee",
    name: "Name here",
    role: "Role II 2020",
    testimonial: {
      title:
        "Curabitur euismod nulla et arcu luctus, non aliquet magna posuere. Suspendisse potenti. Ut gravida ligula eu sem fermentum, sed sagittis enim venenatis. Nam tincidunt sapien a tellus consequat, nec pellentesque magna scelerisque. ",
      text: [
        "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        "Este o ședință de masaj profesionist care se realizează peste haine și durează 15 minute. Masajul este executat de către un kinetoterapeut,Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum pe un scaun special de masaj.",
      ],
      media: ['IMAGE_1', 'IMAGE_2', 'IMAGE_3'],
    },
  },
  {
    image: "Employee",
    name: "Name here",
    role: "Role III 2020",
    testimonial: {
      title:
        "Maecenas sed turpis non mauris accumsan accumsan. Nullam vulputate nulla vitae quam ultricies, a hendrerit arcu dictum.",
      text: [
        "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        "Este o ședință de masaj profesionist care se realizează peste haine și durează 15 minute. Masajul este executat de către un kinetoterapeut,Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum pe un scaun special de masaj.",
      ],
      media: ['IMAGE_1', 'IMAGE_2', 'IMAGE_3'],
    },
  },
  {
    image: "Employee",
    name: "Name here",
    role: "Role IV 2020",
    testimonial: {
      title:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      text: [
        "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        "Este o ședință de masaj profesionist care se realizează peste haine și durează 15 minute. Masajul este executat de către un kinetoterapeut,Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum pe un scaun special de masaj.",
      ],
      media: ['IMAGE_1', 'IMAGE_2', 'IMAGE_3'],
    },
  },
  {
    image: "Employee",
    name: "Name here",
    role: "Role IV 2020",
    testimonial: {
      title:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      text: [
        "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        "Este o ședință de masaj profesionist care se realizează peste haine și durează 15 minute. Masajul este executat de către un kinetoterapeut,Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum pe un scaun special de masaj.",
      ],
      media: ['IMAGE_1', 'IMAGE_2', 'IMAGE_3'],
    },
  },

  {
    image: "Employee",
    name: "Name here",
    role: "Role IV 2020",
    testimonial: {
      title:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      text: [
        "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        "Este o ședință de masaj profesionist care se realizează peste haine și durează 15 minute. Masajul este executat de către un kinetoterapeut,Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum pe un scaun special de masaj.",
      ],
      media: ['IMAGE_1', 'IMAGE_2', 'IMAGE_3'],
    },
  },
];

export const testimonials: ExtendedTestimonialType[] = [
  // CONTAINER 1
  {
    title:
      "Colegii noștri sunt foarte încântați de aceste sesiuni, considerându-le un beneficiu extraordinar și o pauză revigorantă în timpul programului de lucru",
    content:
      "Apreciem în mod deosebit flexibilitatea și disponibilitatea echipei de a ne acomoda programul, precum și comunicarea deschisă și promptă. Profesionalismul și atitudinea prietenoasă a echipei voastre au creat un mediu confortabil și sigur pentru toți colegii noștri. Recomandăm cu căldură serviciile voastre oricărei companii care își dorește să îmbunătățească starea de bine a angajaților săi. Calitatea și profesionalismul oferite fac din voi un partener de încredere.",
    icon: 'Testimonial-Avatar',
    name: "Delia Stancu",
    role: "People & Culture Generalist IKEA România",
    backgroundColor: colors.BLUE_F0FAFF,
    commaIcon: 'Upper-Commas-Dark-Blue',
    clientTextColor: colors.BLUE_2F3347
  },

  // CONTAINER 2
  {
    title:
      "După masaj am observat un tonus mai bun și o creștere a atitudinii pozitive.",
    content:
      "Aștept în fiecare săptămână cu mult interes pauza pentru masajul de relaxare, care mă ajută extraordinar, având în vedere că am o muncă statică la calculator. Terapeuții sunt foarte buni, flexibili și răspund eficient la nevoile fiecăruia dintre noi.",
    icon: 'Testimonial-Avatar',
    name: "Delia 2",
    role: "HR",
    backgroundColor: colors.BLUE_DDEFFF,
    commaIcon: 'Upper-Commas-Dark-Blue',
    clientTextColor: colors.BLUE_242737
  },

  // CONTAINER 3
  {
    title: "Lorem ipsum dolor sit amet.",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    icon: 'Testimonial-Avatar',
    name: "James Appleseed",
    role: "CEO, Pineapple INC.",
    backgroundColor: colors.BLUE_DDEFFF,
    commaIcon: 'Upper-Commas-Turquoise',
    clientTextColor: colors.BLUE_2F3347
  },

  // CONTAINER 4
  {
    title: "Lorem ipsum dolor sit amet.",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ",
    icon: 'Testimonial-Avatar',
    name: "James Appleseed",
    role: "CEO, Pineapple INC.",
    backgroundColor: colors.TURQUOISE_01FFFF,
    commaIcon: 'Upper-Commas-White',
    clientTextColor: colors.BLUE_2F3347
  },

  // CONTAINER 9
  {
    title:
      "Lorem ipsum dolor sit amet. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.\n\n\n  Quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ",
    icon: 'Testimonial-Avatar',
    name: "James Appleseed",
    role: "CEO, Pineapple INC.",
    backgroundColor: colors.BLUE_0067F6,
    commaIcon: 'Upper-Commas-Blue',
    titleColor: colors.WHITE,
    textColor: colors.WHITE,
    clientTextColor: colors.WHITE
  },
  // CONTAINER 6
  {
    title: "Lorem ipsum dolor sit amet.",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ",
    icon: 'Testimonial-Avatar',
    name: "James Appleseed",
    role: "CEO, Pineapple INC.",
    backgroundColor: colors.BLUE_F0FAFF,
    commaIcon: 'Upper-Commas-Dark-Blue',
    clientTextColor: colors.BLUE_2F3347
  },

  // CONTAINER 7
  {
    title: "Lorem ipsum dolor sit amet.",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.",
    icon: 'Testimonial-Avatar',
    name: "James Appleseed",
    role: "CEO, Pineapple INC.",
    backgroundColor: colors.BLUE_0147A7,
    commaIcon: 'Upper-Commas-Dark-Blue',
    titleColor: colors.WHITE,
    textColor: colors.WHITE,
    clientTextColor: colors.WHITE
  },

  // CONTAINER 8
  {
    title: "Lorem ipsum dolor sit amet.",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ",
    icon: 'Testimonial-Avatar',
    name: "James Appleseed",
    role: "CEO, Pineapple INC.",
    backgroundColor: colors.BLUE_DDEFFF,
    commaIcon: 'Upper-Commas-Turquoise',
    clientTextColor: colors.BLUE_2F3347
  },

];