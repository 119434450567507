import React from "react";
import MainLayout from "../../MainLayout.tsx";
import colors from "../../../constants/colors.ts";
import CoverListsGrid from "../../../components/grids/coverListsGrid.component.tsx";
import { coverListGridItems } from "../../../constants/lists.constants.ts";

const Section8: React.FC = () => {
  return (
    <MainLayout backgroundColor={colors.BLUE_F0FAFF}>
      {coverListGridItems?.map((item, index) => (
        <CoverListsGrid
          key={index}
          title={item?.title}
          cover={item?.cover}
          list={item?.items}
        />
      ))}
    </MainLayout>
  );
};

export default Section8;
