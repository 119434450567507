import React from "react";
import Section1 from "./sections/Section1.clients.tsx";
import Section3 from "./sections/section3.clients.tsx";
import Section4 from "./sections/Section4.clients.tsx";
import Section5 from "./sections/Section5.clients.tsx";
import Section6 from "./sections/Section6.clients.tsx";
import Section7 from "./sections/Section7.clients.tsx";

const ClientsHomepage: React.FC = () => {
  return (
    <>
      <Section1 />
      {/* Section2 is included in section1 */}
      <Section3 />
      <Section4 />
      <Section5 />
      <Section6 />
      <Section7 />
    </>
  );
};

export default ClientsHomepage;
