import React from "react";
import TextImageGrid from "../../../components/grids/textImageGrid.component.tsx";
import colors from "../../../constants/colors.ts";
import MainLayout from "../../MainLayout.tsx";
import { imagesFoldersTypes } from "../../../constants/app.constants.ts";

const Section10: React.FC = () => {
  return (
    <MainLayout>
      <TextImageGrid
        title={"TITLE.TipuriDeMasaj"}
        titleColor={colors.WHITE}
        text={"TEXT.TipuriDeMasaj"}
        textColor={colors.WHITE}
        backgroundColor={colors.BLUE_0067F6}
        image={'Chair-Massage'}
        imageVariant={imagesFoldersTypes.IMAGES}
        alignImage="center"
        heightLg={88.5}
      />
    </MainLayout>
  );
};

export default Section10;
