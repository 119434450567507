import React from "react";
import TextImageGrid from "../../../components/grids/textImageGrid.component.tsx";
import AccordionText from "../../../components/text/accordionText.component.tsx";
import { HealthyBackAccordion } from "../../../constants/homepage.constants.ts";
import MainLayout from "../../MainLayout.tsx";
import TextBox from "../../../components/text/textBox.component.tsx";
import { mediaExtensionTypes, textTypes } from "../../../constants/app.constants.ts";
import { MediaType } from "../../../interfaces/card.interface.ts";

const Section3: React.FC = () => {
  const imageArray: MediaType[] = [{image: 'Image-1', extension: mediaExtensionTypes.PNG}, {image: 'Image-2'}, {image: 'Image-3'}, {image: 'Image-4'}];

  return (
    <MainLayout>
      <TextImageGrid
        caption={"TITLE.HealthyBack"}
        title={"TITLE.CePresupuneProgramul"}
        text={"TEXT.ProgramulHealthyBack"}
        isTextLeftAligned={false}
        image={imageArray}
        alignImage={"center"}
      >
        <TextBox
          variant={textTypes.SUBTITLE}
          text={"TITLE.CeIncludeProgramul"}
          fontWeight={800}
        />
        <AccordionText items={HealthyBackAccordion} />
      </TextImageGrid>
    </MainLayout>
  );
};

export default Section3;
