import React, { useState } from "react";
import Box from "@mui/material/Box";
import { TiThMenu } from "react-icons/ti";
import { IoMdSettings } from "react-icons/io";

import Button from "../../components/button/button.component.tsx";
import colors from "../../constants/colors.ts";
import useStyles from "../../hooks/styles.hook.tsx";
import HeaderMenuDrawer from "./headerMenuDrawer.component.tsx";
import { imagesFoldersTypes, maxTypes, MaxTypes } from "../../constants/app.constants.ts";
import Image from "../media/Image.component.tsx";

const CollapsedHeader: React.FC<{ maxType?: MaxTypes }> = ({ maxType = maxTypes.MAX_RELAX }) => {
  const [isHeaderMenuOpen, setIsHeaderMenuOpen] = useState<boolean>(false);

  const { smallPaddingStyles, headerImageScalingStyles } = useStyles();

  const clickHeaderMenu = () => {
    setIsHeaderMenuOpen((prevState) => !prevState);
  };

  return (
    <Box
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
      position={"fixed"}
      top={0}
      zIndex={1000}
      width={"100vw"}
      sx={{
        overflowX: 'hidden',
        backgroundColor: colors.WHITE,
        borderBottomLeftRadius: "20px",
        borderBottomRightRadius: "20px",
      }}
    >
      <Box justifyContent={"flex-end"} sx={smallPaddingStyles}>
        {/* Main Header Menu Button */}
        <Button color={colors.BLUE_242737} onClick={() => clickHeaderMenu()}>
          <TiThMenu />
        </Button>

        {isHeaderMenuOpen && (
          <HeaderMenuDrawer
            isHeaderMenuOpen={isHeaderMenuOpen}
            setIsHeaderMenuOpen={setIsHeaderMenuOpen}
          />
        )}
      </Box>

      <Box justifyContent={"center"}>
        <Image
          name={maxType === maxTypes.MAX_RELAX ? 'Max-Relax-Logo' : 'Max-Active-Logo'}
          variant={imagesFoldersTypes.LOGOS}
          sx={headerImageScalingStyles}
        />
      </Box>

      <Box justifyContent={"flex-end"} sx={smallPaddingStyles}>
        {/* Settings br menu button */}
        <Button color={colors.BLUE_242737}>
          <IoMdSettings />
        </Button>
      </Box>
    </Box>
  );
};

export default CollapsedHeader;
