import React from "react";
import './styles/section1.css'
import HomepageLayout from "../../../components/homepages/homepageLayout.component.tsx";
import { imagesFoldersTypes } from "../../../constants/app.constants.ts";

const LandingSection: React.FC = () => {

  return (
    <HomepageLayout 
      media={'Max-Relax-Header'}
      mediaVariant={imagesFoldersTypes.VIDEOS}
      backgroundImage={'Triangle'}
      title={'TITLE.PauzeActiveProductivitateMaxima'}
      description={"TEXT.PauzeActiveProductivitateMaxima"}
      button={"BUTTON.AflaSolutiileNoastre"}
      areBubblesDisplayed={true}
      isFooterBackgroundTransparent={true}
    />
  );
};

export default LandingSection;
