import React from "react";
import { Accordion, AccordionDetails, AccordionSummary, Icon } from "@mui/material";
import { CoverListGridTextType } from "../../types/text.types.ts";
import TextBox from "./textBox.component.tsx";
import useStyles from "../../hooks/styles.hook.tsx";
import colors from "../../constants/colors.ts";
import { AccordionTextProps } from "../../interfaces/text.interface.ts";
import { imagesFoldersTypes } from "../../constants/app.constants.ts";
import Image from "../media/Image.component.tsx";

const AccordionText: React.FC<AccordionTextProps> = ({ items }) => {
  const { smallPaddingStyles } = useStyles();

  return items?.map((item: CoverListGridTextType, index) => (
    <Accordion
      key={index}
      sx={{
        boxShadow: "none",
        borderTop: `1px solid ${colors.GREY_CECEDB}`,
      }}
    >
      <AccordionSummary
        expandIcon={<Image name={'Down-Keyboard-Arrow-Dark'} variant={imagesFoldersTypes.ELEMENTS} />}
        sx={smallPaddingStyles}
      >
        <TextBox
          text={item?.title}
          fontWeight={600}
          color={colors.GREY_2F3347}
          marginBottom={0}
        />
      </AccordionSummary>

      <AccordionDetails sx={smallPaddingStyles}>
        <TextBox color={colors.GREY_575967} text={item?.text} />
      </AccordionDetails>
    </Accordion>
  ));
};

export default AccordionText;
